<template>
    <a-spin :spinning="spinningLoadingPage">
        <a-spin :spinning="showLoading">
            <div>
                <b-card>
                    <b-row>
                        <!-- ==start-content-left== -->
                        <b-col sm="4">
                            <b-card no-body class="border mt-1">
                                <b-card-header class="p-1 bg-light-primary">
                                    <b-card-title class="font-medium-1">
                                        <feather-icon icon="FileIcon" size="16" />
                                        <span class="align-middle ml-50">Menu Report</span>
                                    </b-card-title>
                                </b-card-header>
                                <b-row class="p-1">
                                    <v-treeview v-model="treeData" :treeTypes="treeTypes" @selected="onSelectedMenu" :openAll="openAll" :contextItems="contextItems" @contextSelected="contextSelectedTree"> </v-treeview>
                                </b-row>
                            </b-card>
                        </b-col>
                        <!-- ==end-content-left== -->

                        <!-- ==start-content-right== -->
                        <b-col sm="8">
                            <b-card no-body class="border mt-1">
                                <b-card-header class="p-1 bg-light-primary">
                                    <b-card-title class="font-medium-1">
                                        <feather-icon icon="FileIcon" size="16" />
                                        <span class="align-middle ml-50">Page Report {{ toTitleCase(REPORT_SELECTED) }}</span>
                                    </b-card-title>
                                </b-card-header>
                                <a-spin :spinning="spinningLoading">
                                    <div style="padding: 20px;">
                                        <!-- ==end-wmo-select== -->
                                        <div v-if="ALLOW_WMO_PICKER.indexOf(REPORT_SELECTED) != -1">
                                            <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="WMO ID :">
                                                <a-select show-search :value="WMO_SELECTED" style="width: 100%;" :filter-option="false" :not-found-content="null" @search="fetchWmo" @change="onChangeWMO">
                                                    <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                                                    <a-select-option v-for="(wmo, indexWmo) in STORE_WMO_SELECT" :key="indexWmo" :value="wmo.station_wmo_id"> {{ wmo.station_wmo_id }} - {{ wmo.station_name }} </a-select-option>
                                                </a-select>
                                            </b-form-group>
                                        </div>
                                        <!-- ==end-wmo-select== -->

                                        <!-- ==end-wmo-select== -->

                                        <div v-if="ALLOW_STATION_PICKER.indexOf(REPORT_SELECTED) != -1">
                                            <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="STATION ID :">
                                                <a-select
                                                    show-search
                                                    :value="STATION_SELECTED"
                                                    :show-arrow="true"
                                                    placeholder="Type for search time..."
                                                    style="width: 100%;"
                                                    :filter-option="false"
                                                    :not-found-content="fetching ? undefined : null"
                                                    @search="fetchStation"
                                                    @change="onChangeStation"
                                                >
                                                    <a-spin v-if="fetching" slot="notFoundContent" size="small" />

                                                    <a-select-option v-for="(station, indexStation) in STORE_STATION_SELECT" :key="indexStation" :value="station.station_id">
                                                        {{ station.alias_station_id }} - {{ station.station_name }}
                                                    </a-select-option>
                                                </a-select>
                                            </b-form-group>
                                        </div>

                                        <!-- ==end-wmo-select== -->

                                        <!-- ==start-type-periode-picker== -->
                                        <div v-if="ALLOW_TYPE_PERIODE_PICKER.indexOf(REPORT_SELECTED) != -1">
                                            <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="TYPE PERIODE :">
                                                <a-select show-search :value="PERIODE_TYPE_SELECTED" style="width: 100%;" :filter-option="false" :not-found-content="null" @search="fetchWmo" @change="onChangePERIODETYPE">
                                                    <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                                                    <a-select-option v-for="(periodeVal, indexPeriode) in STORE_PERIODE_TYPE" :key="indexPeriode" :value="periodeVal.KEY">
                                                        {{ periodeVal.LABEL }}
                                                    </a-select-option>
                                                </a-select>
                                            </b-form-group>
                                        </div>

                                        <!-- ==start-periode-picker== -->
                                        <div v-if="ALLOW_PERIODE_PICKER.indexOf(REPORT_SELECTED) != -1">
                                            <div v-if="REPORT_SELECTED == 'WXREV'">
                                                <div v-if="PERIODE_TYPE_SELECTED == 'harian'">
                                                    <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="DATE :">
                                                        <a-date-picker @change="onChangeDate" :value="DATE_SELECTED != null && DATE_SELECTED != '' ? moment(DATE_SELECTED, 'YYYY-MM-DD') : DATE_SELECTED" />
                                                    </b-form-group>
                                                </div>
                                                <div v-if="PERIODE_TYPE_SELECTED == 'bulanan'">
                                                    <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="PERIODE :">
                                                        <a-month-picker
                                                            placeholder="Select Periode"
                                                            @change="onChangeDatePeriode"
                                                            :value="DATE_PERIODE_SELECTED != null && DATE_PERIODE_SELECTED != '' ? moment(DATE_PERIODE_SELECTED, 'YYYY-MM') : DATE_PERIODE_SELECTED"
                                                        />
                                                    </b-form-group>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="PERIODE :">
                                                    <a-month-picker
                                                        placeholder="Select Periode"
                                                        @change="onChangeDatePeriode"
                                                        :value="DATE_PERIODE_SELECTED != null && DATE_PERIODE_SELECTED != '' ? moment(DATE_PERIODE_SELECTED, 'YYYY-MM') : DATE_PERIODE_SELECTED"
                                                    />
                                                </b-form-group>
                                            </div>
                                        </div>
                                        <!-- ==end-periode-picker== -->

                                        <!-- ==start-date-picker== -->
                                        <div v-if="ALLOW_DATE_PICKER.indexOf(REPORT_SELECTED) != -1">
                                            <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="DATE :">
                                                <a-date-picker @change="onChangeDate" :value="DATE_SELECTED != null && DATE_SELECTED != '' ? moment(DATE_SELECTED, 'YYYY-MM-DD') : DATE_SELECTED" />
                                            </b-form-group>
                                        </div>
                                        <!-- ==end-date-picker== -->

                                        <!-- ==start-range-picker== -->
                                        <div v-if="ALLOW_DATE_RANGE_PICKER.indexOf(REPORT_SELECTED) != -1">
                                            <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="DATE RANGE :">
                                                <a-range-picker
                                                    @change="onChangeRangeDate"
                                                    :value="DATE_RANGE_SELECTED != null && DATE_RANGE_SELECTED != '' ? [moment(DATE_RANGE_SELECTED[0], 'YYYY-MM-DD'), moment(DATE_RANGE_SELECTED[1], 'YYYY-MM-DD')] : DATE_RANGE_SELECTED"
                                                />
                                            </b-form-group>
                                        </div>

                                        <!-- <RangePicker picker="year" /> -->
                                        <!-- ==end-range-picker== -->

                                        <!-- ==start-picker-MONTH== -->
                                        <div v-if="ALLOW_DATE_PICKER_MONTH.indexOf(REPORT_SELECTED) != -1">
                                            <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="MONTH :">
                                                <a-select show-search :value="MONTH_SELECTED" style="width: 200px;" :filter-option="true" :not-found-content="null" @change="onChangeMONTH">
                                                    <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                                                    <a-select-option v-for="(rawMonth, indexMonth) in STORAGE_MONTH" :key="indexMonth" :value="rawMonth.KEY + '-' + rawMonth.LABEL">
                                                        {{ rawMonth.LABEL }}
                                                    </a-select-option>
                                                </a-select>
                                            </b-form-group>
                                        </div>
                                        <!-- ==end-range-picker-MONTH== -->

                                        <!-- ==start-picker-years== -->
                                        <div v-if="ALLOW_DATE_RANGE_PICKER_YEARS.indexOf(REPORT_SELECTED) != -1">
                                            <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="START YEARS :">
                                                <a-select show-search :value="START_YEARS_SELECTED" style="width: 200px;" :filter-option="true" :not-found-content="null" @change="onChangeYearStart">
                                                    <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                                                    <a-select-option v-for="(rawYears, indexYears) in START_YEARS_STORE" :key="indexYears" :value="rawYears">
                                                        {{ rawYears }}
                                                    </a-select-option>
                                                </a-select>
                                            </b-form-group>
                                            <b-form-group v-if="ALLOW_PICKER_YEARS.indexOf(REPORT_SELECTED) == -1" label-cols="4" label-cols-lg="4" label-size="sm" label="END YEARS :">
                                                <a-select show-search :value="END_YEARS_SELECTED" style="width: 200px;" :filter-option="true" :not-found-content="null" @change="onChangeYearEnd">
                                                    <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                                                    <a-select-option v-for="(rawYears, indexYears) in END_YEARS_STORE" :key="indexYears" :value="rawYears">
                                                        {{ rawYears }}
                                                    </a-select-option>
                                                </a-select>
                                            </b-form-group>
                                        </div>

                                        <!-- ==end-range-picker-MONTH== -->

                                        <div v-if="ALLOW_TIME.indexOf(REPORT_SELECTED) != -1">
                                            <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="TIME :">
                                                <a-select show-search :value="TIME_SELECTED" style="width: 100%;" :filter-option="false" :not-found-content="null" @search="fetchWmo" @change="onChangeTIME">
                                                    <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                                                    <a-select-option v-for="(timeVal, indexTime) in STORE_TIME" :key="indexTime" :value="timeVal">
                                                        {{ timeVal }}
                                                    </a-select-option>
                                                </a-select>
                                            </b-form-group>
                                        </div>

                                        <div v-if="ALLOW_TIME_30.indexOf(REPORT_SELECTED) != -1">
                                            <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="TIME :">
                                                <a-select show-search :value="TIME_SELECTED" style="width: 100%;" :filter-option="false" :not-found-content="null" @search="fetchWmo" @change="onChangeTIME">
                                                    <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                                                    <a-select-option v-for="(timeVal, indexTime) in STORE_TIME_30" :key="indexTime" :value="timeVal">
                                                        {{ timeVal }}
                                                    </a-select-option>
                                                </a-select>
                                            </b-form-group>
                                        </div>

                                        <div v-if="ALLOW_JENIS_TANAH.indexOf(REPORT_SELECTED) != -1">
                                            <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="JENIS TANAH :">
                                                <a-select show-search :value="JENIS_TANAH_SELECT" style="width: 100%;" :filter-option="false" :not-found-content="null" @search="fetchWmo" @change="onChangeJENISTANAH">
                                                    <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                                                    <a-select-option v-for="(timeVal, indexTime) in STORE_JENIS_TANAH" :key="indexTime" :value="timeVal.KEY">
                                                        {{ timeVal.LABEL }}
                                                    </a-select-option>
                                                </a-select>
                                            </b-form-group>
                                        </div>

                                        <!-- ==date-inteval== -->
                                        <div v-if="ALLOW_INTERVAL_YEAR.indexOf(REPORT_SELECTED) != -1">
                                            <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="INTERVAL TAHUN NORMAL :">
                                                <b-form-input id="inteval_tahun" v-on:keypress="isNumber($event)" v-model.number="INTERVAL_YEAR" class="col-4 mbtm" size="sm" style="height: 33px;"> </b-form-input>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <!-- @click="onExportPDF"                     v-b-modal.modalReport
  -->

                                    <div style="text-align: right; padding: 10px;" v-if="!form_ku && reportType && reportType != ''">
                                        <div v-if="REPORT_SELECTED == 'WXREV'">
                                            <div v-if="PERIODE_TYPE_SELECTED == 'bulanan'">
                                                <a-button type="primary" icon="download" @click="kirim_report"> Process Report </a-button>
                                            </div>
                                            <div v-else-if="PERIODE_TYPE_SELECTED == 'harian'">
                                                <a-button type="primary" icon="check" @click="sendToGTSSwitching"> Send to GTS Switching </a-button>
                                            </div>
                                        </div>
                                        <div v-else class="d-flex justify-content-end">
                                            <div v-if="REPORT_SELECTED == 'CLIMAT'" style="text-align: right;" class="mr-1">
                                                <a-button type="primary" icon="check" @click="sendToGTSSwitching" :disabled="!WMO_SELECTED || !DATE_PERIODE_SELECTED || !INTERVAL_YEAR"> Send to GTS Switching </a-button>
                                            </div>
                                            <a-button type="primary" icon="download" @click="kirim_report"> Process Report </a-button>
                                        </div>
                                    </div>
                                    <div style="text-align: right; padding: 10px;" v-if="form_ku">
                                        <a-button type="primary" icon="download" @click="createReportExcel"> Process Report </a-button>
                                    </div>
                                </a-spin>

                                <vue-html2pdf
                                    :show-layout="false"
                                    :float-layout="true"
                                    :enable-download="false"
                                    :preview-modal="true"
                                    :paginate-elements-by-height="1100"
                                    filename="Report"
                                    :pdf-quality="2"
                                    :manual-pagination="false"
                                    pdf-format="legal"
                                    v-bind:pdf-orientation="REPORT_ORIENTATION"
                                    @progress="onProgress($event)"
                                    @startPagination="startPagination()"
                                    @hasPaginated="hasPaginated()"
                                    @beforeDownload="beforeDownload($event)"
                                    @hasDownloaded="hasDownloaded($event)"
                                    ref="html2Pdf"
                                    v-if="REPORT_SELECTED != 'CLIMAT'"
                                >
                                    <ReportME45 slot="pdf-content" :dataPDF="STORE_REPORT" v-if="REPORT_SELECTED == 'ME_45'"></ReportME45>
                                    <ReportME48 slot="pdf-content" :dataPDF="STORE_REPORT" v-if="REPORT_SELECTED == 'ME_48'"></ReportME48>
                                    <ReportMETAR slot="pdf-content" :dataPDF="STORE_REPORT" v-if="REPORT_SELECTED == 'METAR_REPORT'"> </ReportMETAR>
                                    <ReportPILOTBALON slot="pdf-content" :dataPDF="STORE_REPORT" v-if="REPORT_SELECTED == 'PILOT_BALON'"> </ReportPILOTBALON>
                                    <ReportFKIKLIM71 slot="pdf-content" :dataPDF="STORE_REPORT" v-if="REPORT_SELECTED == 'FKIKLIM71'"> </ReportFKIKLIM71>
                                    <ReportIKLIMMIKRO slot="pdf-content" :dataPDF="STORE_REPORT" v-if="REPORT_SELECTED == 'IKLIM_MIKRO'"> </ReportIKLIMMIKRO>
                                    <ReportAGMIA slot="pdf-content" :dataPDF="STORE_REPORT" v-if="REPORT_SELECTED == 'AGM_IA'"> </ReportAGMIA>
                                    <ReportAGMIB slot="pdf-content" :dataPDF="STORE_REPORT" v-if="REPORT_SELECTED == 'AGM_IB'"> </ReportAGMIB>
                                    <ReportPSYCHROMETERASSMAN slot="pdf-content" :dataPDF="STORE_REPORT" v-if="REPORT_SELECTED == 'PSYCHROMETER_ASSMAN'"></ReportPSYCHROMETERASSMAN>
                                    <ReportSUHUTANAH slot="pdf-content" :dataPDF="STORE_REPORT" v-if="REPORT_SELECTED == 'SUHU_TANAH'"> </ReportSUHUTANAH>
                                    <ReportOPPENGUAPAN slot="pdf-content" :dataPDF="STORE_REPORT" v-if="REPORT_SELECTED == 'OP_PENGUAPAN'"> </ReportOPPENGUAPAN>
                                    <ReportPICHEPENGUAPAN slot="pdf-content" :dataPDF="STORE_REPORT" v-if="REPORT_SELECTED == 'PICHE_PENGUAPAN'"></ReportPICHEPENGUAPAN>
                                    <ReportLYSIMETER slot="pdf-content" :dataPDF="STORE_REPORT" v-if="REPORT_SELECTED == 'LYSIMETER'"> </ReportLYSIMETER>
                                    <ReportGUNBELLANI slot="pdf-content" :dataPDF="STORE_REPORT" v-if="REPORT_SELECTED == 'GUNBELLANI'"> </ReportGUNBELLANI>
                                    <ReportLAMAPENYINARAN slot="pdf-content" :dataPDF="STORE_REPORT" v-if="REPORT_SELECTED == 'LAMA_PENYINARAN'"></ReportLAMAPENYINARAN>
                                    <ReportAKTINOGRAPH slot="pdf-content" :dataPDF="STORE_REPORT" v-if="REPORT_SELECTED == 'AKTINOGRAPH'"> </ReportAKTINOGRAPH>
                                    <ReportTHERMOHIGOGRAPH slot="pdf-content" :dataPDF="STORE_REPORT" v-if="REPORT_SELECTED == 'THERMOHIGROGRAPH'"></ReportTHERMOHIGOGRAPH>
                                    <ReportBAROGRAPH slot="pdf-content" :dataPDF="STORE_REPORT" v-if="REPORT_SELECTED == 'BAROGRAPH'"> </ReportBAROGRAPH>
                                    <ReportPERAWANAN slot="pdf-content" :dataPDF="STORE_REPORT" v-if="REPORT_SELECTED == 'PERAWANAN'"> </ReportPERAWANAN>
                                    <ReportFORMHUJAN slot="pdf-content" :dataPDF="STORE_REPORT" v-if="REPORT_SELECTED == 'FORM_HUJAN'"> </ReportFORMHUJAN>
                                    <ReportHUJANHELMAN slot="pdf-content" :dataPDF="STORE_REPORT" v-if="REPORT_SELECTED == 'HUJAN_HELMAN'"> </ReportHUJANHELMAN>
                                    <ReportKELEMBAPANTANAH slot="pdf-content" :dataPDF="STORE_REPORT" v-if="REPORT_SELECTED == 'KELEMBAPAN_TANAH'"></ReportKELEMBAPANTANAH>
                                    <ReportFENOLOGI slot="pdf-content" :dataPDF="STORE_REPORT" v-if="REPORT_SELECTED == 'FENOLOGI'"> </ReportFENOLOGI>
                                    <ReportPETIR slot="pdf-content" :dataPDF="STORE_REPORT" v-if="REPORT_SELECTED == 'PETIR'"></ReportPETIR>
                                    <ReportMAGNET slot="pdf-content" :dataPDF="STORE_REPORT" v-if="REPORT_SELECTED == 'MAGNET'"> </ReportMAGNET>
                                    <ReportWXREV slot="pdf-content" :dataPDF="STORE_REPORT" v-if="REPORT_SELECTED == 'WXREV'"></ReportWXREV>
                                    <ReportACS slot="pdf-content" :dataPDF="STORE_REPORT" v-if="REPORT_SELECTED == 'ACS'" />
                                </vue-html2pdf>

                                <vue-html2pdf
                                    :show-layout="false"
                                    :float-layout="false"
                                    :enable-download="false"
                                    :preview-modal="false"
                                    :paginate-elements-by-height="1100"
                                    filename="Report"
                                    :pdf-quality="2"
                                    :manual-pagination="false"
                                    pdf-format="legal"
                                    v-bind:pdf-orientation="REPORT_ORIENTATION"
                                    @progress="onProgress($event)"
                                    @startPagination="startPagination()"
                                    @hasPaginated="hasPaginated()"
                                    @beforeDownload="beforeDownload($event)"
                                    @hasDownloaded="hasDownloaded($event)"
                                    ref="html2Pdf"
                                    v-if="REPORT_SELECTED == 'CLIMAT'"
                                >
                                    <ReportClimat slot="pdf-content" :dataPDF="STORE_REPORT" v-if="REPORT_SELECTED == 'CLIMAT'" />
                                </vue-html2pdf>
                            </b-card>
                            <!-- </b-col> -->

                            <!-- NEW -->
                            <!-- <div v-if="resultreport.result == true"> -->
                            <div>
                                <!-- <div v-if="ALLOW_WMO_PICKER.indexOf(REPORT_SELECTED) != -1"> -->
                                <b-card no-body class="border mt-1" v-if="!form_ku">
                                    <b-card-header class="p-1 bg-light-primary">
                                        <b-card-title class="font-medium-1">
                                            <feather-icon icon="FileIcon" size="16" />
                                            <span class="align-middle ml-50">List Download Report {{ toTitleCase(REPORT_SELECTED_TITLE) }}</span>
                                        </b-card-title>
                                    </b-card-header>

                                    <!-- <div id="app" v-if="resultreport.result == true"> -->
                                    <div id="app" v-if="!form_ku" style="min-height: 200px; max-height: 525px; overflow: auto;">
                                        <vue-good-table
                                            class="monitor"
                                            :columns="columns"
                                            :rows="checkreport"
                                            :pagination-options="{
                                                enabled: true,
                                                perPage: pageLength,
                                                setCurrentPage: 1,
                                                mode: 'pages',
                                            }"
                                            :isLoading="isLoading"
                                        >
                                            <template slot="table-row" slot-scope="props">
                                                <span v-if="props.column.field === 'wmo'">
                                                    {{ wmo }}
                                                </span>

                                                <span v-if="props.column.field === 'download_status'">
                                                    <a-button class="button-download" @click="getReport(props.row)" v-if="props.row[props.column.field] == true"> Download </a-button>
                                                </span>
                                                <!--
                            disabled="checkreport.at_flag === 1"
                            -->

                                                <span v-else>
                                                    {{ props.formattedRow[props.column.field] }}
                                                </span>
                                            </template>
                                        </vue-good-table>
                                        <!-- <div>
                      {{ checkreport.data }}
                    </div> -->
                                    </div>
                                    <!-- <div id="table_ku" v-if="form_ku" style="min-height: 200px; max-height: 525px; overflow: auto">
                    <vue-good-table
                      :columns="columns"
                      :rows="checkreport_ku"
                      :pagination-options="{
                        enabled: true,
                        perPage: pageLength,
                        setCurrentPage: 1,
                        mode: 'pages',
                      }"
                      :isLoading="isLoading"
                    >
                      <template slot="table-row" slot-scope="props">
                        <span v-if="props.column.field === 'wmo'">
                          {{ wmo }}
                        </span>

                        <span v-if="props.column.field === 'download_status'">
                          <a-button class="button-download" @click="getReport(props.row)" > Download </a-button>
                        </span>
    

                        <span v-else>
                          {{ props.formattedRow[props.column.field] }}
                        </span>
                      </template>
                    </vue-good-table>
                  </div> -->
                                </b-card>
                            </div>
                            <b-modal ref="modalReport" id="modalReport" size="sm" @show="resultreport.result == true" hide-footer="true" hide-header="true">
                                <!-- <div>{{ resultreport.result }}</div> -->
                                <div>Report sedang disiapkan . . .</div>
                            </b-modal>
                            <!-- <div>{{ resultreport.result }}</div> -->
                            <!-- <div v-if="resultreport.result == true">HASIL</div> -->
                        </b-col>
                        <!-- ==end-content-right== -->
                    </b-row>
                </b-card>

                <b-modal id="send-gts-message" ref="send-gts-message" title="SEND GTS MESSAGES" button-size="md" size="lg" centered no-close-on-backdrop>
                    <b-row class="mb-1">
                        <b-col cols="12">
                            <b-form-textarea id="textarea-sandi-gts" placeholder="Sandi" v-model="gtsSwitching.sandi" class="font-weight-bolder" rows="9"> </b-form-textarea>
                        </b-col>
                    </b-row>
                    <template #modal-footer>
                        <b-button size="sm" @click="onCancel">
                            Cancel
                        </b-button>
                        <b-button size="sm" variant="gradient-primary" @click="onSubmitToBmkgSwitching" :disabled="showLoading">
                            <b-spinner v-if="showLoading" small />
                            <span v-if="!showLoading">Send to Gts Switching</span>
                            <span v-else>Sending ... </span>
                        </b-button>
                    </template>
                </b-modal>
            </div>
        </a-spin>
    </a-spin>
</template>
<script>
import Vue from "vue";
import VTreeview from "v-treeview";
import VueHtml2pdf from "vue-html2pdf";
import DataWMO from "@/api/dummy/store/DataWMO";
import DataStation from "@/api/dummy/store/DataStation";
import DataDummyME_48 from "@/api/dummy/store/DataDummyME_48";
import DataDummyME_45 from "@/api/dummy/store/DataDummyME_45";
import DataDummyMETAR from "@/api/dummy/store/DataDummyMETAR";
import DataDummyFKIKLIM71 from "@/api/dummy/store/DataDummyFKIKLIM71";
import DataDummyIKLIMMIKRO from "@/api/dummy/store/DataDummyIKLIMMIKRO";
import DataDummySUHUTANAH from "@/api/dummy/store/DataDummySUHUTANAH";
import DataDummyPSYCHROMETERASSMAN from "@/api/dummy/store/DataDummyPSYCHROMETERASSMAN";
import DataDummyPICHEPENGUAPAN from "@/api/dummy/store/DataDummyPICHEPENGUAPAN";
import DataDummyGUNBELLANI from "@/api/dummy/store/DataDummyGUNBELLANI";
import DataDummyLAMAPENYINARAN from "@/api/dummy/store/DataDummyLAMAPENYINARAN";
import DataDummyTHERMOHIGOGRAPH from "@/api/dummy/store/DataDummyTHERMOHIGOGRAPH";
import DataDummyAKTINOGRAPH from "@/api/dummy/store/DataDummyAKTINOGRAPH";
import DataDummyBAROGRAPH from "@/api/dummy/store/DataDummyBAROGRAPH";
import DataDummyPERAWANAN from "@/api/dummy/store/DataDummyPERAWANAN";
import DataDummyKELEMBAPANTANAH from "@/api/dummy/store/DataDummyKELEMBAPANTANAH";
import DataDummyLYSIMETER from "@/api/dummy/store/DataDummyLYSIMETER";
import DataDummyFORMHUJAN from "@/api/dummy/store/DataDummyFORMHUJAN";
import DataDummyAGM1A from "@/api/dummy/store/DataDummyAGM1A";
import DataDummyAGM1B from "@/api/dummy/store/DataDummyAGM1B";
import DataDummyOPPENGUAPAN from "@/api/dummy/store/DataDummyOPPENGUAPAN";
import DataDummyHUJANHELMAN from "@/api/dummy/store/DataDummyHUJANHELMAN";
import DataDummyWXREV from "@/api/dummy/store/DataDummyWXREV";

import DataDummyFenologi from "@/api/dummy/store/DataDummyFenologi";
import DataDummyACS from "@/api/dummy/store/DataDummyACS";
import DataDummyCLIMAT from "@/api/dummy/store/DataDummyCLIMAT";

import DataHelper from "@/api/dummy/store/DataHelper";
import moment from "moment";

import ReportME48 from "./ReportME48";
import ReportME45 from "./ReportME45";
import ReportMETAR from "./ReportMETAR";
import ReportPILOTBALON from "./ReportPILOTBALON";
import ReportFKIKLIM71 from "./ReportFKIKLIM71";
import ReportIKLIMMIKRO from "./ReportIKLIMMIKRO";
import ReportAGMIA from "./ReportAGMIA";
import ReportAGMIB from "./ReportAGMIB";
import ReportPSYCHROMETERASSMAN from "./ReportPSYCHROMETERASSMAN";
import ReportSUHUTANAH from "./ReportSUHUTANAH";
import ReportOPPENGUAPAN from "./ReportOPPENGUAPAN";
import ReportPICHEPENGUAPAN from "./ReportPICHEPENGUAPAN";
import ReportLYSIMETER from "./ReportLYSIMETER";
import ReportGUNBELLANI from "./ReportGUNBELLANI";
import ReportLAMAPENYINARAN from "./ReportLAMAPENYINARAN";
import ReportAKTINOGRAPH from "./ReportAKTINOGRAPH";
import ReportTHERMOHIGOGRAPH from "./ReportTHERMOHIGOGRAPH";
import ReportBAROGRAPH from "./ReportBAROGRAPH";
import ReportPERAWANAN from "./ReportPERAWANAN";
import ReportFORMHUJAN from "./ReportFORMHUJAN";
import ReportKELEMBAPANTANAH from "./ReportKELEMBAPANTANAH";
import ReportFENOLOGI from "./ReportFENOLOGI";
import ReportPETIR from "./ReportPETIR";
import ReportMAGNET from "./ReportMAGNET";
import ReportHUJANHELMAN from "./ReportHUJANHELMAN";
import ReportWXREV from "./ReportWXREV";

import ReportACS from "./ReportACS.vue";
import ReportClimat from "./ReportClimat.vue";
import { VueGoodTable } from "vue-good-table";
import jwt from "@/auth/jwt/useJwt";
import { BModal, BRow, BCol, BCard, BCardHeader, BCardTitle, BFormGroup, BFormTextarea, BOverlay, BSpinner, BFormInput, BInputGroup, BFormSelect, BButton, BButtonGroup, BPagination, BAvatar, BBadge, BFormDatepicker } from "bootstrap-vue";

import helper from "@/api/helper";
import report from "@/api/report";
import axios from "axios";

import { saveAs } from "file-saver";
import metadata from "@/api/metadata";
import { saveExcel } from "@progress/kendo-vue-excel-export";
import ExcelJS from "exceljs";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
    components: {
        BModal,
        BRow,
        BButton,
        BCol,
        BCard,
        BCardHeader,
        BCardTitle,
        BFormTextarea,
        BFormInput,
        BOverlay,
        BSpinner,
        VTreeview,
        VueHtml2pdf,
        BFormGroup,
        DataDummyME_48,
        DataDummyME_45,
        ReportME48,
        ReportME45,
        ReportFKIKLIM71,
        ReportMETAR,
        ReportPILOTBALON,
        ReportIKLIMMIKRO,
        ReportAGMIA,
        ReportAGMIB,
        ReportPSYCHROMETERASSMAN,
        ReportSUHUTANAH,
        ReportOPPENGUAPAN,
        ReportPICHEPENGUAPAN,
        ReportLYSIMETER,
        ReportGUNBELLANI,
        ReportLAMAPENYINARAN,
        ReportAKTINOGRAPH,
        ReportTHERMOHIGOGRAPH,
        ReportBAROGRAPH,
        ReportPERAWANAN,
        ReportFORMHUJAN,
        ReportHUJANHELMAN,
        ReportKELEMBAPANTANAH,
        ReportFENOLOGI,
        ReportPETIR,
        ReportMAGNET,
        ReportWXREV,
        ReportACS,
        ReportClimat,
        VNodes: {
            functional: true,
            render: (h, ctx) => ctx.props.vnodes,
        },
        VueGoodTable,
        ExcelJS,
        saveAs,
    },
    computed: {
        // console: () => console.log("serviceTable", serviceTable()),
        getBlockDownload(aa) {
            this.blockCount = aa === 2;
            return this.blockCount;
        },

        // isDisabled() {
        //   // evaluate whatever you need to determine disabled here...

        //   if (this.checkreport.at_flag === 2) {
        //     return true;
        //   } else {
        //     return false;
        //   }
        // },
    },
    mounted() {
        // this.getReport();
        // this.serviceTable();
        // setTimeout(() => {
        // this.$refs.hide() * 5000;
        // this.serviceTable();
        // console.log("AAAA", this.WMO_SELECTED);
        // this.listReport();
        // console.log("STORE_WMO >>>>>>>>>>>", fetchWmo());
    },
    beforeDestroy() {
        // console.log("beforeDestroy L");
        if (this.setIntervalCheckReport) {
            // console.log("on L if");
            clearInterval(this.setIntervalCheckReport);
        }
    },
    methods: {
        // delayCloseAlert() {

        // },
        // getParameter() {
        //   if (this.REPORT_SELECTED === "ME_45") {
        //     return "me45";
        //   } else if (this.REPORT_SELECTED === "ME_48") {
        //     return "me48";
        //   }checkStatus
        // },

        isNumber(evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                evt.preventDefault();
            }
            if (evt.key == "." || evt.key == ",") {
                return evt.preventDefault();
            } else {
                return true;
            }
        },

        checkTableFormat() {
            if (this.REPORT_SELECTED == "ME_45" || this.REPORT_SELECTED == "ME_48" || this.REPORT_SELECTED == "METAR_REPORT" || this.REPORT_SELECTED == "WXREV" || this.REPORT_SELECTED == "ACS") {
                this.columns = [
                    {
                        label: "No",
                        field: "no",
                    },
                    {
                        label: "Jenis Report",
                        field: "name",
                    },
                    {
                        label: "Stasiun ",
                        field: "wmo",
                    },
                    {
                        label: "Date Start",
                        field: "date_start",
                        dateInputFormat: "dd-mmmm-yyyy",
                        dateOutputFormat: "dd mmmm yyyy",
                    },
                    {
                        label: "Date End",
                        field: "date_end",
                        dateInputFormat: "dd-mmmm-yyyy",
                        dateOutputFormat: "dd mmmm yyyy",
                    },
                    {
                        label: "Created",
                        field: "created_at",
                    },
                    {
                        label: "Status",
                        field: "status_download",
                    },
                    {
                        label: "Download",
                        field: "download_status",
                        filterable: true,
                    },
                ];
            } else if (this.REPORT_SELECTED == "KIMIA_AIR_HUJAN") {
                this.columns = [
                    {
                        label: "No",
                        field: "no",
                    },
                    {
                        label: "Jenis Report",
                        field: "name",
                    },
                    {
                        label: "Stasiun ",
                        field: "wmo",
                    },
                    {
                        label: "Year",
                        field: "periode",
                    },
                    {
                        label: "Created",
                        field: "created_at",
                    },
                    {
                        label: "Status",
                        field: "status_download",
                    },
                    {
                        label: "Download",
                        field: "download_status",
                        filterable: true,
                    },
                ];
            } else {
                this.columns = [
                    {
                        label: "No",
                        field: "no",
                    },
                    {
                        label: "Jenis Report",
                        field: "name",
                    },
                    {
                        label: "Stasiun ",
                        field: "wmo",
                    },
                    {
                        label: "Periode",
                        field: "periode",
                    },
                    {
                        label: "Created",
                        field: "created_at",
                    },
                    {
                        label: "Status",
                        field: "status_download",
                    },
                    {
                        label: "Download",
                        field: "download_status",
                        filterable: true,
                    },
                ];
            }
        },
        toTitleCase(str) {
            const arr = str.split("_");
            const result = [];
            for (const word of arr) {
                result.push(word.charAt(0).toUpperCase() + word.slice(1));
            }
            return result.join(" ");
        },

        kirim_report() {
            var errMessage = "";
            if (this.reportType == "me45" || this.reportType == "me48" || this.reportType == "metar") {
                if (this.WMO_SELECTED === "") {
                    errMessage += "Field WMO ID masih kosong";
                }
                if (!this.DATE_RANGE_SELECTED || (this.DATE_RANGE_SELECTED && this.DATE_RANGE_SELECTED.length < 1)) {
                    if (errMessage.length > 0) {
                        errMessage += ", ";
                    }
                    errMessage += "Field DATE RANGE masih kosong";
                }

                console.log("errMessage", errMessage);
            } else if (
                this.reportType == "fklim" ||
                this.reportType == "iklim-mikro" ||
                this.reportType == "suhu-tanah" ||
                this.reportType == "piche-penguapan" ||
                this.reportType == "gunbellani" ||
                this.reportType == "lama-penyinaran" ||
                this.reportType == "aktinograph" ||
                this.reportType == "thermohigograph" ||
                this.reportType == "barograph" ||
                this.reportType == "form-perawanan" ||
                this.reportType == "form-hujan" ||
                this.reportType == "form-hujan-helman" ||
                this.reportType == "fenologi" ||
                this.reportType == "wxrev"
            ) {
                if (this.STATION_SELECTED === "") {
                    errMessage += "Field STATION ID masih kosong";
                }
                if (this.DATE_PERIODE_SELECTED.length < 1) {
                    if (errMessage.length > 0) {
                        errMessage += ", ";
                    }
                    errMessage += "Field PERIODE masih kosong";
                }
            } else if (this.reportType == "agm-1-a" || this.reportType == "agm-1-b" || this.reportType == "psychrometer-assman" || this.reportType == "op-penguapan" || this.reportType == "form-kelembapan-tanah") {
                if (this.STATION_SELECTED === "") {
                    errMessage += "Field STATION ID masih kosong";
                }
                if (this.DATE_PERIODE_SELECTED.length < 1) {
                    if (errMessage.length > 0) {
                        errMessage += ", ";
                    }
                    errMessage += "Field PERIODE masih kosong";
                }
                if (this.TIME_SELECTED.length < 1) {
                    if (errMessage.length > 0) {
                        errMessage += ", ";
                    }
                    errMessage += "Field TIME masih kosong";
                }
            } else if (this.reportType == "form-lysimeter") {
                if (this.STATION_SELECTED === "") {
                    errMessage += "Field STATION ID masih kosong";
                }
                if (this.DATE_PERIODE_SELECTED.length < 1) {
                    if (errMessage.length > 0) {
                        errMessage += ", ";
                    }
                    errMessage += "Field PERIODE masih kosong";
                }
                if (this.TIME_SELECTED.length < 1) {
                    if (errMessage.length > 0) {
                        errMessage += ", ";
                    }
                    errMessage += "Field TIME masih kosong";
                }
                if (this.JENIS_TANAH_SELECT.length < 1) {
                    if (errMessage.length > 0) {
                        errMessage += ", ";
                    }
                    errMessage += "Field JENIS TANAH masih kosong";
                }
            } else if (this.reportType == "climat") {
                if (this.WMO_SELECTED === "") {
                    errMessage += "Field WMO ID masih kosong";
                }
                if (this.DATE_PERIODE_SELECTED.length < 1) {
                    if (errMessage.length > 0) {
                        errMessage += ", ";
                    }
                    errMessage += "Field PERIODE masih kosong";
                }
                if (this.INTERVAL_YEAR.length < 1) {
                    if (errMessage.length > 0) {
                        errMessage += ", ";
                    }
                    errMessage += "Field INTERVAL TAHUN NORMAL masih kosong";
                }
            } else if (this.reportType == "acs") {
                if (this.STATION_SELECTED === "") {
                    errMessage += "Field STATION ID masih kosong";
                }

                if (this.START_YEARS_SELECTED.length < 1) {
                    if (errMessage.length > 0) {
                        errMessage += ", ";
                    }
                    errMessage += "Field START YEAR masih kosong";
                }

                if (this.END_YEARS_SELECTED.length < 1) {
                    if (errMessage.length > 0) {
                        errMessage += ", ";
                    }
                    errMessage += "Field END YEAR masih kosong";
                }

                let month = this.MONTH_SELECTED != "" && this.MONTH_SELECTED != null ? Number(this.MONTH_SELECTED.split("-")[0]) : this.MONTH_SELECTED;
                if (month.length < 1) {
                    if (errMessage.length > 0) {
                        errMessage += ", ";
                    }
                    errMessage += "Field MONTH masih kosong";
                }
            }

            if (errMessage.length <= 0) {
                this.POSTReport();
            } else {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: errMessage, //"Data tidak valid. Periksa kembali inputan anda",
                        icon: "BellIcon",
                        variant: "danger",
                    },
                });
            }
            // this.POSTReport();
        },
        async POSTReport() {
            let userLogin = JSON.parse(localStorage.getItem("userLogin"));
            let userId = userLogin.id;

            let url = this.urlReport(this.reportType, userId);
            console.log("url", url);
            let resTable = await axios.get(url, helper.getConfig()).then((response) => {
                this.resultreport = response.data;
            });
            this.listReport();
            clearInterval(this.setIntervalCheckReport);
            this.setIntervalCheckReport = setInterval(() => this.listReport(), 60000);
        },

        async sendToGTSSwitching() {
            if (this.REPORT_SELECTED == "WXREV") {
                if (this.PERIODE_TYPE_SELECTED == "harian") {
                    try {
                        // console.log('sendToGTSSwitching WXREV', this.STATION_SELECTED, this.DATE_SELECTED)
                        const { data } = await report.getReportWxrevHarian(this.STATION_SELECTED, this.DATE_SELECTED);
                        // console.log("request datas WXREV : ", data);

                        if (!data || data == "") {
                            this.$swal({
                                title: "Tidak Ada Data",
                                text: "Data Belum/Tidak Tersedia",
                                icon: "error",
                                customClass: {
                                    confirmButton: "btn btn-danger",
                                },
                                buttonsStyling: false,
                            });
                            this.showLoading = false;
                            return;
                        }
                        this.showLoading = true;
                        let stasiun = await metadata.getPathStasiun(this.STATION_SELECTED);
                        if (stasiun.data) {
                            this.showLoading = true;
                            let station = await metadata.getStationByPath(stasiun.data[0].path);
                            let headerGts = station.data["BmkgSatu.metadata.gts.IGTS"];
                            let headerWxrev = headerGts.wxrev_gts_header;
                            let isSendWxrev = headerGts.wxrev_send_to_bmkgswitching;

                            let titleWarn = "";
                            let contentWarn = "";
                            if (!isSendWxrev) {
                                titleWarn = "Anda tidak mempunyai permission untuk mengirim ke GTS Switching";
                                contentWarn = "Perhatikan metadata stasiun anda atau hubungi administor";
                            } else if (!headerWxrev) {
                                titleWarn = "Anda tidak dapat mengirim ke GTS Switching";
                                contentWarn = "Tidak ada Header atau hubungi administrator";
                            }

                            if (!isSendWxrev || !headerWxrev) {
                                this.$swal({
                                    title: titleWarn,
                                    text: contentWarn,
                                    icon: "error",
                                    customClass: {
                                        confirmButton: "btn btn-danger",
                                    },
                                    buttonsStyling: false,
                                });
                                this.showLoading = false;
                                return;
                            }

                            this.gtsSwitching.station_path = stasiun.data[0].path;
                            this.gtsSwitching.station_wmo = stasiun.data[0].station_wmo_id;
                            this.gtsSwitching.sandi = headerWxrev + " " + data;
                            this.gtsSwitching.gts_id = 8;
                            this.gtsSwitching.gts_header = headerWxrev;
                            this.gtsSwitching.gts_ts_data = this.DATE_SELECTED + "T00:00";
                        }
                        // console.log('stasiun', stasiun, this.gtsSwitching)

                        this.$refs["send-gts-message"].show();
                        this.showLoading = false;
                    } catch (e) {
                        console.log("catch", e);
                        this.showLoading = false;
                        console.log(e);
                    }
                }
            } else if (this.REPORT_SELECTED == "CLIMAT") {
                if (this.WMO_SELECTED && this.DATE_PERIODE_SELECTED && this.INTERVAL_YEAR) {
                    this.showLoading = true;
                    try {
                        const { data } = await report.getReportBeritaClimat(this.WMO_SELECTED, this.DATE_PERIODE_SELECTED, this.INTERVAL_YEAR);

                        if (!data || data == "") {
                            this.$swal({
                                title: "Tidak Ada Data",
                                text: "Data Belum/Tidak Tersedia",
                                icon: "error",
                                customClass: {
                                    confirmButton: "btn btn-danger",
                                },
                                buttonsStyling: false,
                            });
                            this.showLoading = false;
                            return;
                        }
                        this.showLoading = true;
                        let stasiun = await metadata.getAllStasiunByWmo(this.WMO_SELECTED);
                        if (stasiun.data) {
                            this.showLoading = true;
                            // let gtsHeader = await metadata.getStationGtsClimatHeader(stasiun.data[0].path);
                            let station = await metadata.getStationByPath(stasiun.data[0].path);
                            let headerGts = station.data["BmkgSatu.metadata.gts.IGTS"];
                            let headerClimat = headerGts.klimat_gts_berita_klimat_header;
                            let isSendClimat = headerGts.klimat_send_to_bmkgswitching;

                            let titleWarn = "";
                            let contentWarn = "";
                            if (!isSendClimat) {
                                titleWarn = "Anda tidak mempunyai permission untuk mengirim ke GTS Switching";
                                contentWarn = "Perhatikan metadata stasiun anda atau hubungi administor";
                            } else if (!headerClimat) {
                                titleWarn = "Anda tidak dapat mengirim ke GTS Switching";
                                contentWarn = "Tidak ada Header atau hubungi administrator";
                            }

                            if (!isSendClimat || !headerClimat) {
                                this.$swal({
                                    title: titleWarn,
                                    text: contentWarn,
                                    icon: "error",
                                    customClass: {
                                        confirmButton: "btn btn-danger",
                                    },
                                    buttonsStyling: false,
                                });
                                this.showLoading = false;
                                return;
                            }

                            this.gtsSwitching.station_path = stasiun.data[0].path;
                            this.gtsSwitching.station_wmo = stasiun.data[0].station_wmo_id;
                            this.gtsSwitching.sandi = headerClimat + " " + data;
                            this.gtsSwitching.gts_id = 7;
                            this.gtsSwitching.gts_header = headerClimat;
                            this.gtsSwitching.gts_ts_data = this.DATE_PERIODE_SELECTED + "-01T00:00";
                        }
                        console.log("stasiun", stasiun, this.gtsSwitching);

                        this.$refs["send-gts-message"].show();
                        this.showLoading = false;
                    } catch (e) {
                        console.log("catch", e);
                        this.showLoading = false;
                        console.log(e);
                    }
                }
            }
        },

        onCancel() {
            this.$refs["send-gts-message"].hide();
        },

        async onSubmitToBmkgSwitching() {
            let mdate = new Date();
            let id =
                "" +
                this.padZero(this.gtsSwitching.gts_id, 2) +
                this.WMO_SELECTED +
                mdate.getFullYear() +
                this.padZero(mdate.getMonth() + 1, 2) +
                this.padZero(mdate.getDate(), 2) +
                this.padZero(mdate.getHours(), 2) +
                this.padZero(mdate.getMinutes(), 2) +
                this.padZero(mdate.getSeconds(), 2);
            // console.log("onSubmitToBmkgSwitching - id", id, this.gtsSwitching)

            let header = this.gtsSwitching.gts_header;
            const post_data = {
                "@type": "GTSMessage",
                id: id,
                type_message: this.gtsSwitching.gts_id,
                sandi_gts: this.gtsSwitching.sandi,
                timestamp_sent_data:
                    mdate.getFullYear() +
                    "-" +
                    this.padZero(mdate.getMonth() + 1, 2) +
                    "-" +
                    this.padZero(mdate.getDate(), 2) +
                    "T" +
                    this.padZero(mdate.getHours(), 2) +
                    ":" +
                    this.padZero(mdate.getMinutes(), 2) +
                    ":" +
                    this.padZero(mdate.getSeconds(), 2),
                timestamp_data: this.gtsSwitching.gts_ts_data,
                wmoid: this.WMO_SELECTED,
                ttaaii: header.split(" ")[0],
                cccc: header.split(" ")[1],
            };

            let url_gts = helper.getBaseUrl() + this.gtsSwitching.station_path + "/gts";
            // console.log('post_data', post_data, url_gts)
            this.showLoading = true;
            await axios
                .post(url_gts, post_data, helper.getConfig())
                .then((response) => {
                    if (response.status == 201 || response.status == 200 || response.status == 204) {
                        this.$swal({
                            title: "Input Data GTS Messages Berhasil",
                            text: "Input Data GTS Message",
                            icon: "success",
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: false,
                        });
                        this.showLoading = false;
                        this.$refs["send-gts-message"].hide();
                    }
                    this.showLoading = false;
                })
                .catch((error) => {
                    this.showLoading = false;
                    // this.spinningLoadingPage = false
                    // error_handler(this, error, "Sinoptik", "Input Data Error");
                    // console.log('error', error, error.response.status, )
                });
        },

        padZero(val, n) {
            return `${"0".repeat(n)}${val}`.substr(-n, n);
        },

        serviceTable() {
            clearInterval(this.setIntervalCheckReport);
            let userLogin = JSON.parse(localStorage.getItem("userLogin"));
            let userId = userLogin.id;
            switch (this.REPORT_SELECTED) {
                case "ME_45":
                    this.reportType = "me45";
                    break;
                case "ME_48":
                    this.reportType = "me48";
                    break;
                case "METAR_REPORT":
                    this.reportType = "metar";

                    break;
                // case "PILOT_BALON":
                //   this.reportType = "me45";
                //   this.listReport()
                //   break;
                case "FKIKLIM71":
                    this.reportType = "fklim";
                    break;
                case "IKLIM_MIKRO":
                    this.reportType = "iklim-mikro";
                    break;
                case "AGM_IA":
                    this.reportType = "agm-1-a";
                    break;
                case "AGM_IB":
                    this.reportType = "agm-1-b";
                    break;
                case "PSYCHROMETER_ASSMAN":
                    this.reportType = "psychrometer-assman";
                    break;
                case "SUHU_TANAH":
                    this.reportType = "suhu-tanah";
                    break;
                case "OP_PENGUAPAN":
                    this.reportType = "op-penguapan";
                    break;
                case "PICHE_PENGUAPAN":
                    this.reportType = "piche-penguapan";
                    break;
                case "LYSIMETER":
                    this.reportType = "form-lysimeter";
                    break;
                case "GUNBELLANI":
                    this.reportType = "gunbellani";
                    break;
                case "LAMA_PENYINARAN":
                    this.reportType = "lama-penyinaran";
                    break;
                case "AKTINOGRAPH":
                    this.reportType = "aktinograph";
                    break;
                case "THERMOHIGROGRAPH":
                    this.reportType = "thermohigograph";
                    break;
                case "BAROGRAPH":
                    this.reportType = "barograph";
                    break;
                case "PERAWANAN":
                    this.reportType = "form-perawanan";
                    break;
                case "FORM_HUJAN":
                    this.reportType = "form-hujan";
                    break;
                case "HUJAN_HELMAN":
                    this.reportType = "form-hujan-helman";
                    break;
                case "KELEMBAPAN_TANAH":
                    this.reportType = "form-kelembapan-tanah";
                    break;
                case "FENOLOGI":
                    this.reportType = "fenologi";
                    break;
                case "CLIMAT":
                    this.reportType = "climat";
                    // this.listReport()
                    break;
                // case "PETIR":
                //   this.reportType = "me45";
                //   this.listReport()
                //   break;
                // case "MAGNET":
                //   this.reportType = "me45";
                //   this.listReport()
                //   break;
                case "WXREV":
                    this.reportType = "wxrev";
                    break;
                case "ACS":
                    this.reportType = "acs";
                    // this.listReport()
                    break;
                default:
                    break;
            }

            this.isLoading = false;
            // this.listReport();
            // clearInterval(this.setIntervalCheckReport);
            this.setIntervalCheckReport = setInterval(() => this.listReport(), 60000);
        },

        urlReport(reportType, userId) {
            // console.log("report", reportType)
            // if (reportType == "me45") {
            //   let u = helper.getBaseUrl() +
            //     `@report_pdf?report=${this.reportType}&user_id=${userId}&wmo_id=${this.WMO_SELECTED}&date_start=${this.DATE_RANGE_SELECTED[0]}&date_end=${this.DATE_RANGE_SELECTED[1]}`;
            //   console.log("u", u)
            //   return u
            // } else if (reportType == "suhu-tanah") {
            //   let u = helper.getBaseUrl() +
            //     `@report_pdf?report=${this.reportType}&user_id=${userId}&station_id=${this.STATION_SELECTED}&periode=${this.DATE_PERIODE_SELECTED}`;
            //   return u
            // }

            var r_url = "";
            switch (reportType) {
                case "me45":
                    r_url = helper.getBaseUrl() + `@report_pdf?report=${this.reportType}&user_id=${userId}&wmo_id=${this.WMO_SELECTED}&date_start=${this.DATE_RANGE_SELECTED[0]}&date_end=${this.DATE_RANGE_SELECTED[1]}`;
                    break;
                case "me48":
                    r_url = helper.getBaseUrl() + `@report_pdf?report=${this.reportType}&user_id=${userId}&wmo_id=${this.WMO_SELECTED}&date_start=${this.DATE_RANGE_SELECTED[0]}&date_end=${this.DATE_RANGE_SELECTED[1]}`;
                    break;
                case "metar":
                    r_url = helper.getBaseUrl() + `@report_pdf?report=${this.reportType}&user_id=${userId}&wmo_id=${this.WMO_SELECTED}&date_start=${this.DATE_RANGE_SELECTED[0]}&date_end=${this.DATE_RANGE_SELECTED[1]}`;
                    break;

                case "fklim":
                    r_url = helper.getBaseUrl() + `@report_pdf?report=${this.reportType}&user_id=${userId}&station_id=${this.STATION_SELECTED}&periode=${this.DATE_PERIODE_SELECTED}`;
                    break;

                case "iklim-mikro":
                    r_url = helper.getBaseUrl() + `@report_pdf?report=${this.reportType}&user_id=${userId}&station_id=${this.STATION_SELECTED}&periode=${this.DATE_PERIODE_SELECTED}`;
                    this.reportType = "iklim-mikro";
                    break;

                case "agm-1-a":
                    r_url = helper.getBaseUrl() + `@report_pdf?report=${this.reportType}&user_id=${userId}&station_id=${this.STATION_SELECTED}&periode=${this.DATE_PERIODE_SELECTED}&waktu=${this.TIME_SELECTED}`;
                    break;

                case "agm-1-b":
                    r_url = helper.getBaseUrl() + `@report_pdf?report=${this.reportType}&user_id=${userId}&station_id=${this.STATION_SELECTED}&periode=${this.DATE_PERIODE_SELECTED}&waktu=${this.TIME_SELECTED}`;
                    break;

                case "psychrometer-assman":
                    r_url = helper.getBaseUrl() + `@report_pdf?report=${this.reportType}&user_id=${userId}&station_id=${this.STATION_SELECTED}&periode=${this.DATE_PERIODE_SELECTED}&waktu=${this.TIME_SELECTED}`;
                    break;

                case "suhu-tanah":
                    r_url = helper.getBaseUrl() + `@report_pdf?report=${this.reportType}&user_id=${userId}&station_id=${this.STATION_SELECTED}&periode=${this.DATE_PERIODE_SELECTED}`;
                    break;

                case "op-penguapan":
                    r_url = helper.getBaseUrl() + `@report_pdf?report=${this.reportType}&user_id=${userId}&station_id=${this.STATION_SELECTED}&periode=${this.DATE_PERIODE_SELECTED}&waktu=${this.TIME_SELECTED}`;
                    break;

                case "piche-penguapan":
                    r_url = helper.getBaseUrl() + `@report_pdf?report=${this.reportType}&user_id=${userId}&station_id=${this.STATION_SELECTED}&periode=${this.DATE_PERIODE_SELECTED}`;
                    break;

                case "form-lysimeter":
                    r_url = helper.getBaseUrl() + `@report_pdf?report=${this.reportType}&user_id=${userId}&station_id=${this.STATION_SELECTED}&periode=${this.DATE_PERIODE_SELECTED}&waktu=${this.TIME_SELECTED}&penampang=${this.JENIS_TANAH_SELECT}`;
                    break;

                case "gunbellani":
                    r_url = helper.getBaseUrl() + `@report_pdf?report=${this.reportType}&user_id=${userId}&station_id=${this.STATION_SELECTED}&periode=${this.DATE_PERIODE_SELECTED}`;
                    break;

                case "lama-penyinaran":
                    r_url = helper.getBaseUrl() + `@report_pdf?report=${this.reportType}&user_id=${userId}&station_id=${this.STATION_SELECTED}&periode=${this.DATE_PERIODE_SELECTED}`;
                    break;

                case "aktinograph":
                    r_url = helper.getBaseUrl() + `@report_pdf?report=${this.reportType}&user_id=${userId}&station_id=${this.STATION_SELECTED}&periode=${this.DATE_PERIODE_SELECTED}`;
                    break;

                case "thermohigograph":
                    r_url = helper.getBaseUrl() + `@report_pdf?report=${this.reportType}&user_id=${userId}&station_id=${this.STATION_SELECTED}&periode=${this.DATE_PERIODE_SELECTED}`;
                    break;

                case "barograph":
                    r_url = helper.getBaseUrl() + `@report_pdf?report=${this.reportType}&user_id=${userId}&station_id=${this.STATION_SELECTED}&periode=${this.DATE_PERIODE_SELECTED}`;
                    break;

                case "form-perawanan":
                    r_url = helper.getBaseUrl() + `@report_pdf?report=${this.reportType}&user_id=${userId}&station_id=${this.STATION_SELECTED}&periode=${this.DATE_PERIODE_SELECTED}`;
                    break;

                case "form-hujan":
                    r_url = helper.getBaseUrl() + `@report_pdf?report=${this.reportType}&user_id=${userId}&station_id=${this.STATION_SELECTED}&periode=${this.DATE_PERIODE_SELECTED}`;
                    break;

                case "form-hujan-helman":
                    r_url = helper.getBaseUrl() + `@report_pdf?report=${this.reportType}&user_id=${userId}&station_id=${this.STATION_SELECTED}&periode=${this.DATE_PERIODE_SELECTED}`;
                    break;

                case "form-kelembapan-tanah":
                    r_url = helper.getBaseUrl() + `@report_pdf?report=${this.reportType}&user_id=${userId}&station_id=${this.STATION_SELECTED}&periode=${this.DATE_PERIODE_SELECTED}&waktu=${this.TIME_SELECTED}`;
                    break;

                case "fenologi":
                    r_url = helper.getBaseUrl() + `@report_pdf?report=${this.reportType}&user_id=${userId}&station_id=${this.STATION_SELECTED}&periode=${this.DATE_PERIODE_SELECTED}`;
                    break;

                case "climat":
                    r_url = helper.getBaseUrl() + `@report_pdf?report=${this.reportType}&user_id=${userId}&wmo_id=${this.WMO_SELECTED}&periode=${this.DATE_PERIODE_SELECTED}&waktu=${this.INTERVAL_YEAR}`;
                    // this.reportType = "me45";
                    // this.listReport()
                    break;
                // case "PETIR":
                //   this.reportType = "me45";
                //   this.listReport()
                //   break;
                // case "MAGNET":
                //   this.reportType = "me45";
                //   this.listReport()
                //   break;
                case "wxrev":
                    r_url = helper.getBaseUrl() + `@report_pdf?report=${this.reportType}&user_id=${userId}&station_id=${this.STATION_SELECTED}&periode=${this.DATE_PERIODE_SELECTED}`;
                    break;
                case "acs":
                    let month = this.MONTH_SELECTED != "" && this.MONTH_SELECTED != null ? Number(this.MONTH_SELECTED.split("-")[0]) : this.MONTH_SELECTED;
                    r_url = helper.getBaseUrl() + `@report_pdf?report=${this.reportType}&user_id=${userId}&station_id=${this.STATION_SELECTED}&periode=${month}&date_start=${this.START_YEARS_SELECTED}&date_end=${this.END_YEARS_SELECTED}`;
                    // this.reportType = "me45";
                    // this.listReport()
                    break;
                default:
                    break;
            }
            return r_url;
        },

        async listReport() {
            this.isLoading = true;
            let userLogin = JSON.parse(localStorage.getItem("userLogin"));
            let userId = userLogin.id;
            let data = null;
            let allFinish = true;

            const urlcheck = helper.getBaseUrl() + `@check_report?report=${this.reportType}&user_id=${userId}`;

            let checkReport = await axios
                .get(urlcheck, helper.getConfig())
                .then((response) => {
                    data = response.data.result;
                    // this.checkreport = response.data.result;
                    // console.log("aaaa", response.data.result)
                })
                .catch((err) => {
                    this.spinningLoadingPage = false;
                });
            this.isLoading = false;
            let arr_data = [];
            var no = 1;
            data.sort(function (a, b) {
                return new Date(Number(b.at_create)) - new Date(Number(a.at_create));
            });
            for (var i in data) {
                let download_status = false;
                let status_download = "";
                if (data[i].at_flag == 2) {
                    download_status = true;
                }

                if (data[i].at_flag == 0) {
                    status_download = "Akan Diproses";
                } else if (data[i].at_flag == 1) {
                    status_download = "Sedang Diproses";
                } else if (data[i].at_flag == 2) {
                    status_download = "Berhasil";
                } else if (data[i].at_flag == 3) {
                    status_download = "Gagal";
                } else if (data[i].at_flag == 4) {
                    status_download = "Tidak Ada Data";
                }

                var wmo_l = data[i].wmo;
                if (wmo_l == null) {
                    wmo_l = data[i].station;
                }
                var date_l = data[i].at_create;

                var date_m = moment(Number(date_l)).format("YYYY-MM-DD HH:mm");
                let row_data = {
                    no: no,
                    id: data[i].id,
                    name: data[i].name,
                    wmo: data[i].station_name,
                    date_start: data[i].date_start,
                    date_end: data[i].date_end,
                    details: data[i].details,
                    periode: data[i].periode,
                    status_download: status_download,

                    at_flag: data[i].at_flag,
                    download_status: download_status,
                    // "created_at" : (date_l) ? new Date(Number(date_l)).toLocaleString() : ""
                    created_at: date_m,
                };
                arr_data.push(row_data);
                no++;

                if (data[i].at_flag == 1 || data[i].at_flag == 0) {
                    allFinish = false;
                }
            }

            this.checkreport = arr_data;
            this.spinningLoadingPage = false;
            // console.log("arr_data : ", this.checkreport);
            // setTimeout(() => this.listReport(), 30000);

            // setTimeout(() => this.$bvModal.hide("modalReport"), 3000);
            if (allFinish) {
                clearInterval(this.setIntervalCheckReport);
            }
        },

        getReport(data) {
            // checkreport.id = id
            var token = jwt.getToken();
            const uri = helper.getBaseUrl() + `@download?id=${data.id}`;
            console.log("uri : ", uri);
            var config = {
                method: "get",
                url: uri,
                headers: {
                    Authorization: `Bearer  ${token}`,
                },
                responseType: "blob",
            };

            // const url = helper.getBaseUrl() + "@download?id=585";
            // let report_name = "REPORT "+data.name+"_"+data.wmo+"_"+data.date_start+"_"+data.date_end+".pdf"
            // console.log("download idl", data.id)
            // console.log("download ", url)

            axios(config).then((response) => {
                console.log(response.headers["content-disposition"]);
                var raw_name_file = response.headers["content-disposition"];
                // console.log("raw_name_file : ",raw_name_file)
                // raw_name_file.length
                var namafile = raw_name_file.substring(22, parseInt(raw_name_file.length - 1));
                // console.log("file nama : ",namafile);

                saveAs(response.data, namafile);
            });
            // this.checkStat();
        },

        onProgress(event) {},
        startPagination() {},
        hasPaginated() {},
        beforeDownload(event) {},
        hasDownloaded(event) {},
        onChangeRangeDate(date, dateString) {
            this.DATE_RANGE_SELECTED = dateString;
        },
        onChangeDate(date, dateString) {
            this.DATE_SELECTED = dateString;
        },
        onChangeDatePeriode(date, dateString) {
            this.DATE_PERIODE_SELECTED = dateString;
        },
        onChangeWMO(values) {
            this.WMO_SELECTED = values;
        },
        onChangeStation(values) {
            this.STATION_SELECTED = values;
        },
        onChangeTIME(values) {
            this.TIME_SELECTED = values;
        },
        onChangeJENISTANAH(values) {
            this.JENIS_TANAH_SELECT = values;
        },
        onChangeMONTH(values) {
            this.MONTH_SELECTED = values;
        },
        onChangePERIODETYPE(values) {
            this.PERIODE_TYPE_SELECTED = values;
        },
        onYearsPicker() {
            return [];
        },
        onChangeYearStart(values) {
            if (Number(values) > Number(this.END_YEARS_SELECTED)) {
                this.END_YEARS_SELECTED = Number(values);
            }
            this.START_YEARS_SELECTED = Number(values);
        },
        onChangeYearEnd(values) {
            if (Number(values) < Number(this.START_YEARS_SELECTED)) {
                this.START_YEARS_SELECTED = Number(values);
            }
            this.END_YEARS_SELECTED = Number(values);
        },
        async onSelectedMenu(node) {
            this.spinningLoadingPage = true;
            this.isLoading = true;
            this.WMO_SELECTED = "";
            this.DATE_PERIODE_SELECTED = "";
            this.DATE_RANGE_SELECTED = null;
            this.DATE_SELECTED = "";
            this.STATION_SELECTED = "";
            this.STORE_WMO = [];
            this.STORE_REPORT = {};
            this.TIME_SELECTED = "07:30";
            this.JENIS_TANAH_SELECT = "tanah_gundul";
            this.END_YEARS_SELECTED = "";
            this.START_YEARS_SELECTED = "";
            // this.serviceTable();
            // this.listReport();

            if (this.ALLOW_DATE_RANGE_PICKER_YEARS.indexOf(node.model.unix_code) != -1) {
                this.onYearsStart();
                this.onYearsEnd();
            }
            console.log("node.model.unix_code ", node.model.unix_code);
            if (this.ALLOW_WMO_PICKER.indexOf(node.model.unix_code) != -1) {
                this.fetching = true;

                // var datawmo = localStorage.getItem('list_station_wmo')
                // if (!datawmo) {
                // console.log('data list_station_wmo persistent kosong')
                new Promise(async (resolve) => {
                    this.STORE_WMO = await DataWMO.callDataWMO(node.model.unix_code);
                    this.STORE_WMO_SELECT = this.STORE_WMO;

                    localStorage.setItem("list_station_wmo", JSON.stringify(this.STORE_WMO));

                    this.spinningLoadingPage = false;
                    resolve(this.STORE_WMO);
                });
                // }
                // else {
                //   // console.log('data list_station_wmo ada')
                //   datawmo = JSON.parse(datawmo)
                //   this.STORE_WMO = datawmo;
                //   this.STORE_WMO_SELECT = this.STORE_WMO;
                //   this.spinningLoadingPage = false;
                // }
            }

            if (this.ALLOW_STATION_PICKER.indexOf(node.model.unix_code) != -1) {
                this.fetching = true;
                // var datastationall = localStorage.getItem('list_all_station')
                // if (!datastationall) {
                await new Promise(async (resolve) => {
                    this.STORE_STATION = await DataStation.callDataStationALL(node.model.unix_code);
                    var stationSet = [];
                    for (var i in this.STORE_STATION) {
                        stationSet.push(this.STORE_STATION[i]);
                        if (stationSet.length == 300) {
                            break;
                        }
                    }
                    this.STORE_STATION_SELECT = stationSet;

                    localStorage.setItem("list_all_station", JSON.stringify(this.STORE_STATION));

                    this.fetching = false;
                    this.spinningLoadingPage = false;
                    resolve(this.STORE_STATION);
                });
                // }
                // else {
                //   datastationall = JSON.parse(datastationall)
                //   this.STORE_STATION = datastationall;
                //   var stationSet = [];
                //   for (var i in this.STORE_STATION) {
                //     stationSet.push(this.STORE_STATION[i]);
                //     if (stationSet.length == 300) {
                //       break;
                //     }
                //   }
                //   this.STORE_STATION_SELECT = stationSet;
                //   this.fetching = false;
                //   this.spinningLoadingPage = false;
                // }
            }
            this.spinningLoadingPage = false;

            if (node.model.code == "MENU_ITEM") {
                this.REPORT_SELECTED = node.model.unix_code;
                this.REPORT_SELECTED_TITLE = node.model.unix_code;
                if (node.model.unix_code == "THERMOHIGOGRAPH") {
                    this.REPORT_SELECTED_TITLE = "THERMOHIGROGRAPH";
                }
                this.checkTableFormat();
                //list download
                if (
                    this.REPORT_SELECTED == "KIMIA_AIR_HUJAN" ||
                    this.REPORT_SELECTED == "KIMIA_AIR_HUJAN_NO_WMO" ||
                    this.REPORT_SELECTED == "SPM" ||
                    this.REPORT_SELECTED == "Aerosol" ||
                    this.REPORT_SELECTED == "SO2NO2" ||
                    this.REPORT_SELECTED == "EANET"
                ) {
                    this.form_ku = true;
                    this.serviceTableKah();
                } else {
                    this.form_ku = false;
                    this.serviceTable();
                }

                // this.listReport()
                this.isLoading = false;
            }
        },
        serviceTableKah() {},
        async createReportExcel() {
            if (this.STATION_SELECTED == "" || this.STATION_SELECTED == null || this.START_YEARS_SELECTED == "" || this.START_YEARS_SELECTED == null) {
                return;
            }
            this.isLoading = true;
            if (this.REPORT_SELECTED == "KIMIA_AIR_HUJAN") {
                const { data } = await metadata.reportKAHExcel(this.STATION_SELECTED, this.START_YEARS_SELECTED);
                // var listDataKu = data.items;
                var listDataKu = data;

                if (listDataKu.length == 0) {
                    // this.isLoading = false;
                    // console.log("tidak ada data")
                    this.$swal({
                        title: "ERROR",
                        text: "Data Belum/Tidak Tersedia",
                        icon: "error",
                        customClass: {
                            confirmButton: "btn btn-danger",
                        },
                        buttonsStyling: false,
                    });
                    return;
                }
                var station_name = "";
                var station_code = "BKT";
                var dataReportTemp = [];
                for (var i in listDataKu) {
                    var row = listDataKu[i];
                    station_code = listDataKu[i].sampel_id.substring(0, 3);
                    station_name = listDataKu[i].station_name;
                    var rows_data = {
                        sampel_id: row.sampel_id,
                        start_date: row.start_date,
                        start_time: row.start_time,
                        end_date: row.end_date,
                        end_time: row.end_time,
                        precipitation: row.precipitation || "",
                        sample_quality: row.sample_quality,
                        laboratory_comment: row.laboratory_comment,
                        unusual_occurrences: row.unusual_occurrences,
                        sg_mm: row.sg_mm,
                        sg_flag: row.sg_flag,
                        sm_mm: row.sm_mm,
                        sm_flag: row.sm_flag,
                        ph: row.ph,
                        ph_flag: row.ph_flag,
                        conductivity: row.conductivity,
                        conductivity_flag: row.kah_conductivity_flag,
                        acidity: row.acidity,
                        acidity_flag: row.acidity_flag,
                        sulfat: row.sulfat,
                        sulfat_flag: row.sulfat_flag,
                        amonium: row.amonium,
                        amonium_flag: row.amonium_flag,
                        nirat: row.nirat,
                        nirat_flag: row.nirat_flag,
                        cl: row.cl,
                        cl_flag: row.cl_flag,
                        ca: row.ca,
                        ca_flag: row.ca_flag,
                        k: row.k,
                        k_flag: row.k_flag,
                        mg: row.mg,
                        mg_flag: row.mg_flag,
                        na: row.na,
                        na_flag: row.na_flag,
                        f: row.f,
                        f_flag: row.f_flag,
                        period: row.period,
                        station_name: row.station_name,
                        station_id: row.station_id,
                    };
                    dataReportTemp.push(rows_data);
                }
                var sort_data = dataReportTemp.sort((a, b) => parseInt(a.period) - parseInt(b.period));

                this.cretaExcel(sort_data, station_name, station_code, this.START_YEARS_SELECTED);
                this.isLoading = false;
            } else if (this.REPORT_SELECTED == "KIMIA_AIR_HUJAN_NO_WMO") {
                const { data } = await metadata.reportKAHExcelNoWMO(this.STATION_SELECTED, this.START_YEARS_SELECTED);
                var listDataKu = data;

                if (listDataKu.length == 0) {
                    this.$swal({
                        title: "ERROR",
                        text: "Data Belum/Tidak Tersedia",
                        icon: "error",
                        customClass: {
                            confirmButton: "btn btn-danger",
                        },
                        buttonsStyling: false,
                    });
                    return;
                }

                this.cretaExcelKAHNoWMO(listDataKu, this.START_YEARS_SELECTED);
                this.isLoading = false;
            } else if (this.REPORT_SELECTED == "SPM") {
                const { data } = await metadata.reportSPMExcel(this.STATION_SELECTED, this.START_YEARS_SELECTED);
                var listDataKu = data;

                if (listDataKu.length == 0) {
                    this.$swal({
                        title: "ERROR",
                        text: "Data Belum/Tidak Tersedia",
                        icon: "error",
                        customClass: {
                            confirmButton: "btn btn-danger",
                        },
                        buttonsStyling: false,
                    });
                    return;
                }

                this.cretaExcelSPM(listDataKu, this.START_YEARS_SELECTED);
                this.isLoading = false;
            } else if (this.REPORT_SELECTED == "Aerosol") {
                const { data } = await metadata.reportAerosolExcel(this.STATION_SELECTED, this.START_YEARS_SELECTED);
                var listDataKu = data;

                if (listDataKu.length == 0) {
                    this.$swal({
                        title: "ERROR",
                        text: "Data Belum/Tidak Tersedia",
                        icon: "error",
                        customClass: {
                            confirmButton: "btn btn-danger",
                        },
                        buttonsStyling: false,
                    });
                    return;
                }

                this.cretaExcelAerosol(listDataKu, this.START_YEARS_SELECTED);
                this.isLoading = false;
            } else if (this.REPORT_SELECTED == "SO2NO2") {
                const { data } = await metadata.reportSO2NO2Excel(this.STATION_SELECTED, this.START_YEARS_SELECTED);
                var listDataKu = data;

                if (listDataKu.length == 0) {
                    this.$swal({
                        title: "ERROR",
                        text: "Data Belum/Tidak Tersedia",
                        icon: "error",
                        customClass: {
                            confirmButton: "btn btn-danger",
                        },
                        buttonsStyling: false,
                    });
                    return;
                }

                this.cretaExcelSO2NO2(listDataKu, this.START_YEARS_SELECTED);
                this.isLoading = false;
            } else if (this.REPORT_SELECTED == "EANET") {
                const { data } = await metadata.reportEanetExcel(this.STATION_SELECTED, this.START_YEARS_SELECTED);
                var listDataEanet = data;

                if (listDataEanet.length == 0) {
                    this.$swal({
                        title: "ERROR",
                        text: "Data Belum/Tidak Tersedia",
                        icon: "error",
                        customClass: {
                            confirmButton: "btn btn-danger",
                        },
                        buttonsStyling: false,
                    });
                    return;
                }
                console.log("listDataEanet", listDataEanet);
                this.createExcelEANET(listDataEanet, this.START_YEARS_SELECTED);
                this.isLoading = false;
            }
        },
        cretaExcel(data_xlsx, station_name, station_code, year) {
            // var data_xlsx = this.ROWS_DATA_REPORT_KAH[id];
            //   var template_report = "./TemplateReportKAH.xlsx";
            // console.log("this.columns", this.columns);

            const workbook = new ExcelJS.Workbook();
            const sheet1 = " Instructions";

            const sheet2 = "Precipitation Chemistry Data";

            const ChemistryDataSheet = workbook.getWorksheet("Data");

            // ChemistryDataSheet.columns = [
            //   { key: "id", width: 10 },
            //   // { key: "station_name", width: 30 },
            //   // { key: "tgl_angkat", width: 7 },
            //   // { key: "tgl_pasang", width: 7 },
            // ];

            // ChemistryDataSheet.addRows(this.rows);

            let SheetChemistryData = workbook.addWorksheet(sheet2, {
                properties: { tabColor: { argb: "ff80b1fd" } },
                // pageSetup: {
                //   horizontalCentered: true,
                //   columns: 8,
                //   verticalCentered: true,
                //   paperSize: 9,
                //   orientation: "portrait",
                //   margins: {
                //     left: 0.3149606,
                //     right: 0.3149606,
                //     top: 0.3543307,
                //     bottom: 0.3543307,
                //     header: 0.3149606,
                //     footer: 0.3149606,
                //   },
                // },
            });

            // SheetChemistryData.getCell(`A8 : AK100`).fill = {
            //   type: "pattern",
            //   pattern: "solid",
            //   fgColor: { argb: "ffffe2dd" },
            // };
            const titleData1 = SheetChemistryData.getRow(2);
            titleData1.height = 40;

            const titleDataColA = SheetChemistryData.getColumn(1);
            titleDataColA.width = 10;
            const titleDataColB = SheetChemistryData.getColumn(2);
            titleDataColB.width = 13;
            const titleDataColD = SheetChemistryData.getColumn(4);
            titleDataColD.width = 13;
            const titleDataColF = SheetChemistryData.getColumn(6);
            titleDataColF.width = 20;
            const titleDataColG = SheetChemistryData.getColumn(7);
            titleDataColG.width = 20;
            const titleDataColH = SheetChemistryData.getColumn(8);
            titleDataColH.width = 20;
            const titleDataColI = SheetChemistryData.getColumn(9);
            titleDataColI.width = 20;
            const titleDataColJ = SheetChemistryData.getColumn(10);
            titleDataColJ.width = 10;

            const titleChemistryData = SheetChemistryData.getCell("A2");
            SheetChemistryData.mergeCells(`A2:AK2`);

            titleChemistryData.value = "Access the red tab labelled 'Instructions' for guidance on entering data in this table. Each column in this table has an instruction number. The 'Instructions' contain guidance for each instruction number.";
            titleChemistryData.font = {
                size: 12,
                bold: true,
                color: "#000",
            };
            titleChemistryData.alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
            };

            const instructionChemistryData = SheetChemistryData.getCell("A3");
            SheetChemistryData.mergeCells(`A3:AK3`);

            instructionChemistryData.value =
                "Instruction Number………………..Instruction Number………………..Instruction Number………………..Instruction Number………………..Instruction Number………………..Instruction Number………………..Instruction Number ………………..Instruction Number………………..Instruction Number………………..Instruction Number....................Instruction Number....................Instruction Number....................Instruction Number....................Instruction Number....................";
            instructionChemistryData.font = {
                size: 10,
                bold: true,
                color: "#000",
            };
            instructionChemistryData.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetChemistryData.getCell("A3:AK3").border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
            };
            const titleChemistryData1 = SheetChemistryData.getCell("A4");
            SheetChemistryData.getCell("A4").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleChemistryData1.value = "[1]";

            SheetChemistryData.mergeCells("B4:C4");
            const titleChemistryData2A = SheetChemistryData.getCell("B4");
            SheetChemistryData.getCell("B4").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleChemistryData2A.value = "[2]";

            SheetChemistryData.mergeCells("D4:E4");
            const titleChemistryData2B = SheetChemistryData.getCell("D4");
            SheetChemistryData.getCell("D4").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleChemistryData2B.value = "[2]";

            const titleChemistryData3 = SheetChemistryData.getCell("F4");
            SheetChemistryData.getCell("F4").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleChemistryData3.value = "[3]";

            const titleChemistryData4 = SheetChemistryData.getCell("G4");
            SheetChemistryData.getCell("G4").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleChemistryData4.value = "[4]";

            const titleChemistryData5 = SheetChemistryData.getCell("H4");
            SheetChemistryData.getCell("H4").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleChemistryData5.value = "[5]";

            const titleChemistryData6 = SheetChemistryData.getCell("I4");
            SheetChemistryData.getCell("I4").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleChemistryData6.value = "[6]";

            SheetChemistryData.mergeCells("J4:K4");

            const titleChemistryData7 = SheetChemistryData.getCell("J4");
            SheetChemistryData.getCell("J4").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleChemistryData7.value = "[7]";

            SheetChemistryData.mergeCells("L4:M4");

            const titleChemistryData8 = SheetChemistryData.getCell("L4");
            SheetChemistryData.getCell("L4").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleChemistryData8.value = "[8]";

            SheetChemistryData.mergeCells("N4:O4");

            const titleChemistryData9 = SheetChemistryData.getCell("N4");
            SheetChemistryData.getCell("N4").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleChemistryData9.value = "[9]";

            SheetChemistryData.mergeCells("P4:Q4");

            const titleChemistryData10 = SheetChemistryData.getCell("P4");
            SheetChemistryData.getCell("P4").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleChemistryData10.value = "[10]";

            SheetChemistryData.mergeCells("R4:S4");

            const titleChemistryData11 = SheetChemistryData.getCell("R4");
            SheetChemistryData.getCell("R4").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleChemistryData11.value = "[11]";

            SheetChemistryData.mergeCells("T4:U4");

            const titleChemistryData12 = SheetChemistryData.getCell("T4");
            SheetChemistryData.getCell("T4").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleChemistryData12.value = "[12]";

            SheetChemistryData.mergeCells("V4:W4");

            const titleChemistryData13 = SheetChemistryData.getCell("V4");
            SheetChemistryData.getCell("V4").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleChemistryData13.value = "[13]";

            SheetChemistryData.mergeCells("X4:Y4");

            const titleChemistryData14 = SheetChemistryData.getCell("X4");
            SheetChemistryData.getCell("X4").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleChemistryData14.value = "[14]";

            SheetChemistryData.mergeCells("Z4:AA4");

            const titleChemistryData15A = SheetChemistryData.getCell("Z4");
            SheetChemistryData.getCell("Z4").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleChemistryData15A.value = "[15]";

            SheetChemistryData.mergeCells("AB4:AC4");

            const titleChemistryData15B = SheetChemistryData.getCell("AB4");
            SheetChemistryData.getCell("AB4").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleChemistryData15B.value = "[15]";

            SheetChemistryData.mergeCells("AD4:AE4");

            const titleChemistryData15C = SheetChemistryData.getCell("AD4");
            SheetChemistryData.getCell("AD4").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleChemistryData15C.value = "[15]";

            SheetChemistryData.mergeCells("AF4:AG4");

            const titleChemistryData15D = SheetChemistryData.getCell("AF4");
            SheetChemistryData.getCell("AF4").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleChemistryData15D.value = "[15]";

            SheetChemistryData.mergeCells("AH4:AI4");

            const titleChemistryData15E = SheetChemistryData.getCell("AH4");
            SheetChemistryData.getCell("AH4").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleChemistryData15E.value = "[15]";

            SheetChemistryData.mergeCells("AJ4:AK4");

            const titleChemistryData15F = SheetChemistryData.getCell("AJ4");
            SheetChemistryData.getCell("AJ4").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleChemistryData15F.value = "[15]";

            SheetChemistryData.mergeCells("A5:A6");
            const titleTable1A = SheetChemistryData.getCell("A5");
            SheetChemistryData.getCell("A5").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable1A.value = "SAMPLE ID";

            const titleTable1B = SheetChemistryData.getCell("A7");
            SheetChemistryData.getCell("A7").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable1B.value = "text";

            SheetChemistryData.mergeCells("B5:C5");
            const titleTable21A = SheetChemistryData.getCell("B5");
            SheetChemistryData.getCell("B5").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable21A.value = "START";

            const titleTable21B1 = SheetChemistryData.getCell("B6");
            SheetChemistryData.getCell("B6").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable21B1.value = "DATE";

            const titleTable21C1 = SheetChemistryData.getCell("B7");
            SheetChemistryData.getCell("B7").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable21C1.value = "dd-mm-yyyy";

            const titleTable21B2 = SheetChemistryData.getCell("C6");
            SheetChemistryData.getCell("C6").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable21B2.value = "TIME";

            const titleTable21C2 = SheetChemistryData.getCell("C7");
            SheetChemistryData.getCell("C7").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable21C2.value = "hh:mm";

            SheetChemistryData.mergeCells("D5:E5");
            const titleTable22A = SheetChemistryData.getCell("D5");
            SheetChemistryData.getCell("D5").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable22A.value = "END";

            const titleTable22B1 = SheetChemistryData.getCell("D6");
            SheetChemistryData.getCell("D6").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable22B1.value = "DATE";

            const titleTable22C1 = SheetChemistryData.getCell("D7");
            SheetChemistryData.getCell("D7").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable22C1.value = "dd-mm-yyyy";

            const titleTable22B2 = SheetChemistryData.getCell("E6");
            SheetChemistryData.getCell("E6").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable22B2.value = "TIME";
            const titleTable22C2 = SheetChemistryData.getCell("E7");
            SheetChemistryData.getCell("E7").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable22C2.value = "hh:mm";

            const titleTable3A = SheetChemistryData.getCell("F5");
            SheetChemistryData.getCell("F5").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable3A.value = "PRECIPITATION";

            const titleTable3B = SheetChemistryData.getCell("F6");
            SheetChemistryData.getCell("F6").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable3B.value = "TYPE";

            const titleTable3C = SheetChemistryData.getCell("F7");
            SheetChemistryData.getCell("F7").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable3C.value = "text";

            const titleTable4A = SheetChemistryData.getCell("G5");
            SheetChemistryData.getCell("G5").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable4A.value = "SAMPLE";

            const titleTable4B = SheetChemistryData.getCell("G6");
            SheetChemistryData.getCell("G6").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable4B.value = "QUALITY";

            const titleTable4C = SheetChemistryData.getCell("G7");
            SheetChemistryData.getCell("G7").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable4C.value = "text";

            const titleTable5A = SheetChemistryData.getCell("H5");
            SheetChemistryData.getCell("H5").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable5A.value = "LABORATORY";

            const titleTable5B = SheetChemistryData.getCell("H6");
            SheetChemistryData.getCell("H6").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable5B.value = "COMMENT";

            const titleTable5C = SheetChemistryData.getCell("H7");
            SheetChemistryData.getCell("H7").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable5C.value = "text";

            const titleTable6A = SheetChemistryData.getCell("I5");
            SheetChemistryData.getCell("I5").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable6A.value = "UNUSUAL";

            const titleTable6B = SheetChemistryData.getCell("I6");
            SheetChemistryData.getCell("I6").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable6B.value = "OCCURRENCES";

            const titleTable6C = SheetChemistryData.getCell("I7");
            SheetChemistryData.getCell("I7").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable6C.value = "text";

            SheetChemistryData.mergeCells("J5:K5");
            const titleTable7A = SheetChemistryData.getCell("J5");
            SheetChemistryData.getCell("J5").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable7A.value = "STANDARD GAUGE";

            SheetChemistryData.mergeCells("J6:K6");

            const titleTable7B = SheetChemistryData.getCell("J6");
            SheetChemistryData.getCell("J6").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable7B.value = "(liquid depth)";

            const titleTable7C1 = SheetChemistryData.getCell("J7");
            SheetChemistryData.getCell("J7").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable7C1.value = "mm";

            const titleTable7C2 = SheetChemistryData.getCell("K7");
            SheetChemistryData.getCell("K7").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable7C2.value = "flag";

            SheetChemistryData.mergeCells("L5:M5");
            const titleTable8A = SheetChemistryData.getCell("L5");
            SheetChemistryData.getCell("L5").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable8A.value = "SAMPLE MASS";

            const titleTable8C1 = SheetChemistryData.getCell("L7");
            SheetChemistryData.getCell("L7").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable8C1.value = "g";

            const titleTable8C2 = SheetChemistryData.getCell("M7");
            SheetChemistryData.getCell("M7").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable8C2.value = "flag";

            SheetChemistryData.mergeCells("N5:O5");
            const titleTable9A = SheetChemistryData.getCell("N5");
            SheetChemistryData.getCell("N5").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable9A.value = "pH";

            const titleTable9C1 = SheetChemistryData.getCell("N7");
            SheetChemistryData.getCell("N7").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable9C1.value = "pH units";

            const titleTable9C2 = SheetChemistryData.getCell("O7");
            SheetChemistryData.getCell("O7").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable9C2.value = "flag";

            SheetChemistryData.mergeCells("P5:Q5");
            const titleTable10A = SheetChemistryData.getCell("P5");
            SheetChemistryData.getCell("P5").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable10A.value = "Conductivity";

            const titleTable10C1 = SheetChemistryData.getCell("P7");
            SheetChemistryData.getCell("P7").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable10C1.value = "µS/cm";

            const titleTable10C2 = SheetChemistryData.getCell("Q7");
            SheetChemistryData.getCell("Q7").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable10C2.value = "flag";

            SheetChemistryData.mergeCells("R5:S5");
            const titleTable11A = SheetChemistryData.getCell("R5");
            SheetChemistryData.getCell("R5").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable11A.value = "Acidity";

            const titleTable11C1 = SheetChemistryData.getCell("R7");
            SheetChemistryData.getCell("R7").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable11C1.value = "µS/L";

            const titleTable11C2 = SheetChemistryData.getCell("S7");
            SheetChemistryData.getCell("S7").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable11C2.value = "flag";

            SheetChemistryData.mergeCells("T5:U5");
            const titleTable12A = SheetChemistryData.getCell("T5");
            SheetChemistryData.getCell("T5").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable12A.value = "Sulfate as SO42-";

            const titleTable12C1 = SheetChemistryData.getCell("T7");
            SheetChemistryData.getCell("T7").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable12C1.value = "mg/L";

            const titleTable12C2 = SheetChemistryData.getCell("U7");
            SheetChemistryData.getCell("U7").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable12C2.value = "flag";

            SheetChemistryData.mergeCells("V5:W5");
            const titleTable13A = SheetChemistryData.getCell("V5");
            SheetChemistryData.getCell("V5").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable13A.value = "Ammonium as NH4+";

            const titleTable13C1 = SheetChemistryData.getCell("V7");
            SheetChemistryData.getCell("V7").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable13C1.value = "mg/L";

            const titleTable13C2 = SheetChemistryData.getCell("W7");
            SheetChemistryData.getCell("W7").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable13C2.value = "flag";

            SheetChemistryData.mergeCells("X5:Y5");
            const titleTable14A = SheetChemistryData.getCell("X5");
            SheetChemistryData.getCell("X5").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable14A.value = "Nirate as NO3-";

            const titleTable14C1 = SheetChemistryData.getCell("X7");
            SheetChemistryData.getCell("X7").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable14C1.value = "mg/L";

            const titleTable14C2 = SheetChemistryData.getCell("Y7");
            SheetChemistryData.getCell("Y7").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable14C2.value = "flag";

            SheetChemistryData.mergeCells("Z5:AA5");
            const titleTable15A1 = SheetChemistryData.getCell("Z5");
            SheetChemistryData.getCell("Z5").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable15A1.value = "Cl";
            titleTable15A1.font = {
                color: { argb: "4bacc6" },
            };
            const titleTable15C11 = SheetChemistryData.getCell("Z7");
            SheetChemistryData.getCell("Z7").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable15C11.value = "mg/L";

            const titleTable15C21 = SheetChemistryData.getCell("AA7");
            SheetChemistryData.getCell("AA7").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable15C21.value = "flag";

            SheetChemistryData.mergeCells("AB5:AC5");
            const titleTable15A2 = SheetChemistryData.getCell("AB5");
            SheetChemistryData.getCell("AB5").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable15A2.value = "Ca";
            titleTable15A2.font = {
                color: { argb: "4bacc6" },
            };
            const titleTable15C12 = SheetChemistryData.getCell("AB7");
            SheetChemistryData.getCell("AB7").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable15C12.value = "mg/L";

            const titleTable15C22 = SheetChemistryData.getCell("AC7");
            SheetChemistryData.getCell("AC7").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable15C22.value = "flag";

            SheetChemistryData.mergeCells("AD5:AE5");
            const titleTable15A3 = SheetChemistryData.getCell("AD5");
            SheetChemistryData.getCell("AD5").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable15A3.value = "K";
            titleTable15A3.font = {
                color: { argb: "4bacc6" },
            };
            const titleTable15C13 = SheetChemistryData.getCell("AD7");
            SheetChemistryData.getCell("AD7").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable15C13.value = "mg/L";

            const titleTable15C23 = SheetChemistryData.getCell("AE7");
            SheetChemistryData.getCell("AE7").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable15C23.value = "flag";

            SheetChemistryData.mergeCells("AF5:AG5");
            const titleTable15A4 = SheetChemistryData.getCell("AF5");
            SheetChemistryData.getCell("AF5").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable15A4.value = "Mg";
            titleTable15A4.font = {
                color: { argb: "4bacc6" },
            };
            const titleTable15C14 = SheetChemistryData.getCell("AF7");
            SheetChemistryData.getCell("AF7").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable15C14.value = "mg/L";

            const titleTable15C24 = SheetChemistryData.getCell("AG7");
            SheetChemistryData.getCell("AG7").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable15C24.value = "flag";

            SheetChemistryData.mergeCells("AH5:AI5");
            const titleTable15A5 = SheetChemistryData.getCell("AH5");
            SheetChemistryData.getCell("AH5").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable15A5.value = "Na";
            titleTable15A5.font = {
                color: { argb: "4bacc6" },
            };
            const titleTable15C15 = SheetChemistryData.getCell("AH7");
            SheetChemistryData.getCell("AH7").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable15C15.value = "mg/L";

            const titleTable15C25 = SheetChemistryData.getCell("AI7");
            SheetChemistryData.getCell("AI7").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable15C25.value = "flag";

            SheetChemistryData.mergeCells("AJ5:AK5");
            const titleTable15A6 = SheetChemistryData.getCell("AJ5");
            SheetChemistryData.getCell("AJ5").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable15A6.value = "F";

            titleTable15A6.font = {
                color: { argb: "FF0000" },
            };
            const titleTable15C16 = SheetChemistryData.getCell("AJ7");
            SheetChemistryData.getCell("AJ7").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable15C16.value = "mg/L";

            const titleTable15C26 = SheetChemistryData.getCell("AK7");
            SheetChemistryData.getCell("AK7").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            titleTable15C26.value = "flag";

            // FOOTER
            SheetChemistryData.columns = [
                { key: "sampel_id", width: 10 },
                { key: "start_date", width: 13 },
                { key: "start_time" },
                { key: "end_date", width: 13 },
                { key: "end_time" },
                { key: "precipitation", width: 20 },
                { key: "sample_quality", width: 20 },
                { key: "laboratory_comment", width: 20 },
                { key: "unusual_occurrences", width: 20 },
                { key: "sg_mm" },
                { key: "sg_flag" },
                { key: "sm_mm" },
                { key: "sm_flag" },
                { key: "ph" },
                { key: "ph_flag" },
                { key: "conductivity" },
                { key: "conductivity_flag" },
                { key: "acidity" },
                { key: "acidity_flag" },
                { key: "sulfat" },
                { key: "sulfat_flag" },
                { key: "amonium" },
                { key: "amonium_flag" },
                { key: "nirat" },
                { key: "nirat_flag" },
                { key: "cl" },
                { key: "cl_flag" },
                { key: "ca" },
                { key: "ca_flag" },
                { key: "k" },
                { key: "k_flag" },
                { key: "mg" },
                { key: "mg_flag" },
                { key: "na" },
                { key: "na_flag" },
                { key: "f" },
                { key: "f_flag" },
            ];
            SheetChemistryData.addRows(data_xlsx);
            const rowCount = SheetChemistryData.rowCount;
            for (let i = 7; i <= rowCount; i++) {
                const inst = SheetChemistryData.getRow(1 + i);

                for (let index = 1; index <= 37; index++) {
                    inst.getCell(index).border = {
                        top: { style: "dotted" },
                        left: { style: "dotted" },
                        bottom: { style: "dotted" },
                        right: { style: "dotted" },
                    };
                }
            }
            for (let i = 8; i <= rowCount; i++) {
                const inst = SheetChemistryData.getRow(0 + i);

                for (let index = 1; index <= 37; index++) {
                    inst.getCell(index).fill = {
                        type: "pattern",
                        pattern: "solid",
                        fgColor: { argb: "FEF2CB" },
                    };
                }
            }

            let SheetInstructions = workbook.addWorksheet(sheet1, {
                properties: { tabColor: { argb: "fff41e1e" } },
            });
            const cellInstructionNumber = SheetInstructions.getCell("A1");
            const titleInstruction1 = SheetInstructions.getRow(1);
            titleInstruction1.height = 30;

            const titleInstruction2 = SheetInstructions.getRow(2);
            titleInstruction2.height = 30;
            SheetInstructions.getRow(2).alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };

            const titleInstruction7 = SheetInstructions.getRow(7);
            titleInstruction7.height = 30;
            SheetInstructions.getRow(7).alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            const titleInstruction8 = SheetInstructions.getRow(8);
            titleInstruction8.height = 30;
            SheetInstructions.getRow(8).alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };

            const titleInstruction13 = SheetInstructions.getRow(13);
            titleInstruction13.height = 30;
            SheetInstructions.getRow(13).alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            const titleInstruction14 = SheetInstructions.getRow(14);
            titleInstruction14.height = 30;
            SheetInstructions.getRow(14).alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };

            const titleInstruction15 = SheetInstructions.getRow(15);
            titleInstruction15.height = 40;
            SheetInstructions.getRow(15).alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            const titleInstruction16 = SheetInstructions.getRow(16);
            titleInstruction16.height = 30;
            SheetInstructions.getRow(16).alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };

            const titleInstruction17 = SheetInstructions.getRow(17);
            titleInstruction17.height = 30;
            SheetInstructions.getRow(17).alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            const titleInstruction20 = SheetInstructions.getRow(20);
            titleInstruction20.height = 30;
            SheetInstructions.getRow(20).alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            const titleInstruction21 = SheetInstructions.getRow(21);
            titleInstruction21.height = 30;
            SheetInstructions.getRow(21).alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            const titleInstruction25 = SheetInstructions.getRow(25);
            titleInstruction25.height = 40;
            SheetInstructions.getRow(25).alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            const titleInstruction33 = SheetInstructions.getRow(33);
            titleInstruction33.height = 30;
            SheetInstructions.getRow(33).alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            const titleInstruction36 = SheetInstructions.getRow(36);
            titleInstruction36.height = 60;
            SheetInstructions.getRow(36).alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };

            const titleInstruction38 = SheetInstructions.getRow(38);
            titleInstruction38.height = 40;
            SheetInstructions.getRow(38).alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            const titleInstruction40 = SheetInstructions.getRow(40);
            titleInstruction40.height = 40;
            SheetInstructions.getRow(40).alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            const titleInstruction41 = SheetInstructions.getRow(41);
            titleInstruction41.height = 30;
            SheetInstructions.getRow(41).alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            const titleInstruction42 = SheetInstructions.getRow(42);
            titleInstruction42.height = 40;
            SheetInstructions.getRow(42).alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            const titleInstruction43 = SheetInstructions.getRow(43);
            titleInstruction43.height = 20;
            SheetInstructions.getRow(43).alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            const titleInstruction45 = SheetInstructions.getRow(45);
            titleInstruction45.height = 20;
            SheetInstructions.getRow(45).alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            const titleInstruction47 = SheetInstructions.getRow(47);
            titleInstruction47.height = 20;
            SheetInstructions.getRow(47).alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            const titleInstruction49 = SheetInstructions.getRow(49);
            titleInstruction49.height = 20;
            SheetInstructions.getRow(49).alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            const titleInstruction51 = SheetInstructions.getRow(51);
            titleInstruction51.height = 20;
            SheetInstructions.getRow(51).alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            const titleInstruction53 = SheetInstructions.getRow(53);
            titleInstruction53.height = 20;
            SheetInstructions.getRow(53).alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            const titleInstruction55 = SheetInstructions.getRow(55);
            titleInstruction55.height = 20;
            SheetInstructions.getRow(55).alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            const titleInstruction57 = SheetInstructions.getRow(57);
            titleInstruction57.height = 20;
            SheetInstructions.getRow(57).alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            // const titleInstruction5 = SheetInstructions.getRow(5);

            const titleInstructionColA = SheetInstructions.getColumn(1);
            const titleInstructionColB = SheetInstructions.getColumn(2);
            const titleInstructionColF = SheetInstructions.getColumn(6);
            const titleInstructionColG = SheetInstructions.getColumn(7);

            titleInstructionColA.width = 10;
            titleInstructionColB.width = 30;
            titleInstructionColF.width = 23;
            titleInstructionColG.width = 52;

            cellInstructionNumber.value = "Instruction Number";
            cellInstructionNumber.font = {
                size: 10,
                bold: true,
                color: "#000",
            };
            cellInstructionNumber.alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
            };

            const cellInstruction1 = SheetInstructions.getCell("A2");
            cellInstruction1.value = "[1]";
            cellInstruction1.font = {
                size: 10,
                color: "#000",
                bold: false,
            };
            cellInstruction1.alignment = {
                horizontal: "center",
                vertical: "middle",
            };

            const cellInstruction2 = SheetInstructions.getCell("A3");
            cellInstruction2.value = "[2]";
            cellInstruction2.font = {
                size: 10,
                color: "#000",
            };
            cellInstruction2.alignment = {
                horizontal: "center",
                vertical: "middle",
            };

            const cellInstruction3 = SheetInstructions.getCell("A4");
            cellInstruction3.value = "[3]";
            cellInstruction3.font = {
                size: 10,
                color: "#000",
            };
            cellInstruction3.alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            SheetInstructions.mergeCells(`A5:E9`);
            SheetInstructions.getCell(`A5:E9`).fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "cccccc" },
            };

            const cellInstruction4 = SheetInstructions.getCell("A10");
            cellInstruction4.value = "[4]";
            cellInstruction4.font = {
                size: 10,
                color: "#000",
            };
            cellInstruction4.alignment = {
                horizontal: "center",
                vertical: "middle",
            };

            SheetInstructions.mergeCells(`A11:E17`);
            SheetInstructions.getCell(`A11:E17`).fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "cccccc" },
            };

            const cellInstruction5 = SheetInstructions.getCell("A18");
            cellInstruction5.value = "[5]";
            cellInstruction5.font = {
                size: 10,
                color: "#000",
            };
            cellInstruction5.alignment = {
                horizontal: "center",
                vertical: "middle",
            };

            SheetInstructions.mergeCells(`A19:E25`);
            SheetInstructions.getCell(`A19:E25`).fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "cccccc" },
            };
            const cellInstruction6 = SheetInstructions.getCell("A26");
            cellInstruction6.value = "[6]";
            cellInstruction6.font = {
                size: 10,
                color: "#000",
            };
            cellInstruction6.alignment = {
                horizontal: "center",
                vertical: "middle",
            };

            SheetInstructions.mergeCells(`A27:E35`);
            SheetInstructions.getCell(`A27:E35`).fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "cccccc" },
            };

            const cellInstruction7 = SheetInstructions.getCell("A36");
            cellInstruction7.value = "[7]";
            cellInstruction7.font = {
                size: 10,
                color: "#000",
            };
            cellInstruction7.alignment = {
                horizontal: "center",
                vertical: "middle",
            };

            SheetInstructions.mergeCells(`A37:E42`);
            SheetInstructions.getCell(`A37:E42`).fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "cccccc" },
            };

            const cellInstruction8 = SheetInstructions.getCell("A43");
            cellInstruction8.value = "[8]";
            cellInstruction8.font = {
                size: 10,
                color: "#000",
            };
            cellInstruction8.alignment = {
                horizontal: "center",
                vertical: "middle",
            };

            SheetInstructions.getCell(`A44`).fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "cccccc" },
            };

            const cellInstruction9 = SheetInstructions.getCell("A45");
            cellInstruction9.value = "[9]";
            cellInstruction9.font = {
                size: 10,
                color: "#000",
            };
            cellInstruction9.alignment = {
                horizontal: "center",
                vertical: "middle",
            };

            SheetInstructions.getCell(`A46`).fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "cccccc" },
            };

            const cellInstruction10 = SheetInstructions.getCell("A47");
            cellInstruction10.value = "[10]";
            cellInstruction10.font = {
                size: 10,
                color: "#000",
            };
            cellInstruction10.alignment = {
                horizontal: "center",
                vertical: "middle",
            };

            SheetInstructions.getCell(`A48`).fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "cccccc" },
            };

            const cellInstruction11 = SheetInstructions.getCell("A49");
            cellInstruction11.value = "[11]";
            cellInstruction11.font = {
                size: 10,
                color: "#000",
            };
            cellInstruction11.alignment = {
                horizontal: "center",
                vertical: "middle",
            };

            SheetInstructions.getCell(`A50`).fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "cccccc" },
            };

            const cellInstruction12 = SheetInstructions.getCell("A51");
            cellInstruction12.value = "[12]";
            cellInstruction12.font = {
                size: 10,
                color: "#000",
            };
            cellInstruction12.alignment = {
                horizontal: "center",
                vertical: "middle",
            };

            SheetInstructions.getCell(`A52`).fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "cccccc" },
            };
            const cellInstruction13 = SheetInstructions.getCell("A53");
            cellInstruction13.value = "[13]";
            cellInstruction13.font = {
                size: 10,
                color: "#000",
            };
            cellInstruction13.alignment = {
                horizontal: "center",
                vertical: "middle",
            };

            SheetInstructions.getCell(`A54`).fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "cccccc" },
            };
            const cellInstruction14 = SheetInstructions.getCell("A55");
            cellInstruction14.value = "[14]";
            cellInstruction14.font = {
                size: 10,
                color: "#000",
            };
            cellInstruction14.alignment = {
                horizontal: "center",
                vertical: "middle",
            };

            SheetInstructions.getCell(`A56`).fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "cccccc" },
            };

            const cellInstruction15 = SheetInstructions.getCell("A57");
            cellInstruction15.value = "[15]";
            cellInstruction15.font = {
                size: 10,
                color: "#000",
            };
            cellInstruction15.alignment = {
                horizontal: "center",
                vertical: "middle",
            };

            SheetInstructions.getCell(`A58`).fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "cccccc" },
            };

            // SheetInstructions.addRows(this.rows);

            // const rowCount = SheetInstructions.rowCount;

            // BBBBBBBBBBBBBBBBBBBBB
            const cellInstructionEnter = SheetInstructions.getCell("B1");
            SheetInstructions.mergeCells(`B1:G1`);

            cellInstructionEnter.value = "Instructions for entering Precipitation Chemistry Data";
            cellInstructionEnter.font = {
                size: 10,
                bold: true,
                color: "#000",
            };
            cellInstructionEnter.alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
            };
            const cellInstructionEnter1 = SheetInstructions.getCell("B2");
            SheetInstructions.mergeCells(`B2:G2`);

            cellInstructionEnter1.value = "This is a sample identifier (ID).  It can be any combination of letters and numbers, but each sample must have a different sample ID.";
            cellInstructionEnter1.alignment = {
                wrapText: true,
            };
            const cellInstructionEnter2 = SheetInstructions.getCell("B3");
            SheetInstructions.mergeCells(`B3:G3`);
            cellInstructionEnter2.value = "The Date and Time of the Start and End of each sample period. Enter local standard time only.";

            const cellInstructionEnter3A1 = SheetInstructions.getCell("B4");
            SheetInstructions.mergeCells(`B4:E4`);
            cellInstructionEnter3A1.value = "Select one Precipitation Type from this table.";

            const cellInstructionEnter3A2 = SheetInstructions.getCell("F4");
            cellInstructionEnter3A2.value = "Precipitation Type";
            SheetInstructions.getCell("F4").font = {
                size: 10,
                bold: true,
            };
            SheetInstructions.getCell("F4").border = {
                top: { style: "double" },
            };
            const cellInstructionEnter3A3 = SheetInstructions.getCell("G4");
            cellInstructionEnter3A3.value = "Description";
            SheetInstructions.getCell("G4").font = {
                size: 10,
                bold: true,
            };
            SheetInstructions.getCell("G4").border = {
                top: { style: "double" },
            };
            const cellInstructionEnter3B2 = SheetInstructions.getCell("F5");
            cellInstructionEnter3B2.value = "rain";

            const cellInstructionEnter3B3 = SheetInstructions.getCell("G5");
            cellInstructionEnter3B3.value = "Precipitation was rain only.";

            const cellInstructionEnter3C2 = SheetInstructions.getCell("F6");
            cellInstructionEnter3C2.value = "snow";

            const cellInstructionEnter3C3 = SheetInstructions.getCell("G6");
            cellInstructionEnter3C3.value = "Precipitation was snow only.";

            const cellInstructionEnter3D2 = SheetInstructions.getCell("F7");
            cellInstructionEnter3D2.value = "mixed";

            const cellInstructionEnter3D3 = SheetInstructions.getCell("G7");
            cellInstructionEnter3D3.value = "Precipitation was sleet or ice pellets or was a combination of liquid and frozen types, including rain with hail. ";

            const cellInstructionEnter3E2 = SheetInstructions.getCell("F8");
            cellInstructionEnter3E2.value = "unknown";

            const cellInstructionEnter3E3 = SheetInstructions.getCell("G8");
            cellInstructionEnter3E3.value = "Precipitation occurred but there is no record of the precipitation type.";

            const cellInstructionEnter3F2 = SheetInstructions.getCell("F9");
            cellInstructionEnter3F2.value = "none";

            const cellInstructionEnter3F3 = SheetInstructions.getCell("G9");
            cellInstructionEnter3F3.value = "No precipitation occurred during the sample period.";

            const cellInstructionEnter4A1 = SheetInstructions.getCell("B10");
            SheetInstructions.mergeCells(`B10:E10`);
            cellInstructionEnter4A1.value = "Enter one or more of the Sample Quality options from this table.";

            const cellInstructionEnter4A2 = SheetInstructions.getCell("F10");
            cellInstructionEnter4A2.value = "Sample Quality";
            SheetInstructions.getCell("F10").font = {
                size: 10,
                bold: true,
            };
            SheetInstructions.getCell("F10").border = {
                top: { style: "double" },
            };
            const cellInstructionEnter4A3 = SheetInstructions.getCell("G10");
            cellInstructionEnter4A3.value = "Description";
            SheetInstructions.getCell("G10").font = {
                size: 10,
                bold: true,
            };
            SheetInstructions.getCell("G10").border = {
                top: { style: "double" },
            };
            const cellInstructionEnter4B2 = SheetInstructions.getCell("F11");
            cellInstructionEnter4B2.value = "clean and clear";
            const cellInstructionEnter4B3 = SheetInstructions.getCell("G11");
            cellInstructionEnter4B3.value = "There were no problems with the sample or the sampler.";

            const cellInstructionEnter4C2 = SheetInstructions.getCell("F12");
            cellInstructionEnter4C2.value = "contaminated";
            const cellInstructionEnter4C3 = SheetInstructions.getCell("G12");
            cellInstructionEnter4C3.value = "Sample contamination was observed or reported.";

            const cellInstructionEnter4D2 = SheetInstructions.getCell("F13");
            cellInstructionEnter4D2.value = "spillage or leakage";
            const cellInstructionEnter4D3 = SheetInstructions.getCell("G13");
            cellInstructionEnter4D3.value = "Spillage or leakage occurred before the sample quantity was measured.";

            const cellInstructionEnter4E2 = SheetInstructions.getCell("F14");
            cellInstructionEnter4E2.value = "bulk (also record in metadata tab)";
            const cellInstructionEnter4E3 = SheetInstructions.getCell("G14");
            cellInstructionEnter4E3.value = "The sample was exposed continuously to the atmosphere. An example is a continuously open funnel-bottle sampler.";

            const cellInstructionEnter4F2 = SheetInstructions.getCell("F15");
            cellInstructionEnter4F2.value = "manual wet-only (also record in metadata tab)";
            const cellInstructionEnter4F3 = SheetInstructions.getCell("G15");
            cellInstructionEnter4F3.value = "A wet-only sample was collected by manually opening and closing the sampler.";

            const cellInstructionEnter4G2 = SheetInstructions.getCell("F16");
            cellInstructionEnter4G2.value = "sampler malfunction";
            const cellInstructionEnter4G3 = SheetInstructions.getCell("G16");
            cellInstructionEnter4G3.value = "The automated wet-only sampler malfunctioned and the sample was exposed during some dry weather.";

            const cellInstructionEnter4H2 = SheetInstructions.getCell("F17");
            cellInstructionEnter4H2.value = "no sample collected";
            const cellInstructionEnter4H3 = SheetInstructions.getCell("G17");
            cellInstructionEnter4H3.value = "Use this when  no precipitation occurred or the sampler malfunctioned and no sample was collected.";

            const cellInstructionEnter5A1 = SheetInstructions.getCell("B18");
            SheetInstructions.mergeCells(`B18:E18`);
            cellInstructionEnter5A1.value = "Enter one or more of the Laboratory Comments from this table.";

            const cellInstructionEnter5A2 = SheetInstructions.getCell("F18");
            cellInstructionEnter5A2.value = "Laboratory Comment";
            SheetInstructions.getCell("F18").font = {
                size: 10,
                bold: true,
            };
            SheetInstructions.getCell("F18").border = {
                top: { style: "double" },
            };
            const cellInstructionEnter5A3 = SheetInstructions.getCell("G18");
            cellInstructionEnter5A3.value = "Description";
            SheetInstructions.getCell("G18").font = {
                size: 10,
                bold: true,
            };
            SheetInstructions.getCell("G18").border = {
                top: { style: "double" },
            };
            const cellInstructionEnter5B2 = SheetInstructions.getCell("F19");
            cellInstructionEnter5B2.value = "valid analysis";
            const cellInstructionEnter5B3 = SheetInstructions.getCell("G19");
            cellInstructionEnter5B3.value = "There were no problems with the sample or the analysis.";

            const cellInstructionEnter5C2 = SheetInstructions.getCell("F20");
            cellInstructionEnter5C2.value = "qualified valid analysis";
            const cellInstructionEnter5C3 = SheetInstructions.getCell("G20");
            cellInstructionEnter5C3.value = "The sample was analyzed but some meaasurements may have been affected by contamination or mishandling.";

            const cellInstructionEnter5D2 = SheetInstructions.getCell("F21");
            cellInstructionEnter5D2.value = "partial valid analysis";
            const cellInstructionEnter5D3 = SheetInstructions.getCell("G21");
            cellInstructionEnter5D3.value = "Some chemical analyses were performed but the sample volume was too small for a complete analysis.";

            const cellInstructionEnter5E2 = SheetInstructions.getCell("F22");
            cellInstructionEnter5E2.value = "no analysis";
            const cellInstructionEnter5E3 = SheetInstructions.getCell("G22");
            cellInstructionEnter5E3.value = "No sample was collected";

            const cellInstructionEnter5F2 = SheetInstructions.getCell("F23");
            cellInstructionEnter5F2.value = "no analysis-low volume";
            const cellInstructionEnter5F3 = SheetInstructions.getCell("G23");
            cellInstructionEnter5F3.value = "Sample volume was too small for chemical analysis.";

            const cellInstructionEnter5G2 = SheetInstructions.getCell("F24");
            cellInstructionEnter5G2.value = "no analysis-contaminated";
            const cellInstructionEnter5G3 = SheetInstructions.getCell("G24");
            cellInstructionEnter5G3.value = "Sample was too contaminated for analysis.";

            const cellInstructionEnter5H2 = SheetInstructions.getCell("F25");
            cellInstructionEnter5H2.value = "no analysis-mishandled";
            const cellInstructionEnter5H3 = SheetInstructions.getCell("G25");
            cellInstructionEnter5H3.value = "Sample was mishandled. Possible reasons include improper preservation, excessive delays from collection to analysis, lost sample, or other procedural problems.";

            const cellInstructionEnter6A1 = SheetInstructions.getCell("B26");
            SheetInstructions.mergeCells(`B26:E26`);
            cellInstructionEnter6A1.value = "Enter one or more of the Laboratory Comments from this table.";

            const cellInstructionEnter6A2 = SheetInstructions.getCell("F26");
            cellInstructionEnter6A2.value = "Unusual Occurrence";
            SheetInstructions.getCell("F26").font = {
                size: 10,
                bold: true,
            };
            SheetInstructions.getCell("F26").border = {
                top: { style: "double" },
            };
            const cellInstructionEnter6A3 = SheetInstructions.getCell("G26");
            cellInstructionEnter6A3.value = "Description";
            SheetInstructions.getCell("G26").font = {
                size: 10,
                bold: true,
            };
            SheetInstructions.getCell("G26").border = {
                top: { style: "double" },
            };
            const cellInstructionEnter6B2 = SheetInstructions.getCell("F27");
            cellInstructionEnter6B2.value = "none";
            const cellInstructionEnter6B3 = SheetInstructions.getCell("G27");
            cellInstructionEnter6B3.value = "No unusual occurrences were observed.";

            const cellInstructionEnter6C2 = SheetInstructions.getCell("F28");
            cellInstructionEnter6C2.value = "dust";
            const cellInstructionEnter6C3 = SheetInstructions.getCell("G28");
            cellInstructionEnter6C3.value = "Airborne dust was observed at the site.";

            const cellInstructionEnter6D2 = SheetInstructions.getCell("F29");
            cellInstructionEnter6D2.value = "agricultural activity";
            const cellInstructionEnter6D3 = SheetInstructions.getCell("G29");
            cellInstructionEnter6D3.value = "Cultivating, fertilizing, spraying, or harvesting near the site.";

            const cellInstructionEnter6E2 = SheetInstructions.getCell("F30");
            cellInstructionEnter6E2.value = "construction";
            const cellInstructionEnter6E3 = SheetInstructions.getCell("G30");
            cellInstructionEnter6E3.value = "Construction or demolition near the site.";

            const cellInstructionEnter6F2 = SheetInstructions.getCell("F31");
            cellInstructionEnter6F2.value = "smoke/ash";
            const cellInstructionEnter6F3 = SheetInstructions.getCell("G31");
            cellInstructionEnter6F3.value = "Smoke or airborne ash was observed at the site.";

            const cellInstructionEnter6G2 = SheetInstructions.getCell("F32");
            cellInstructionEnter6G2.value = "volcanic activity";
            const cellInstructionEnter6G3 = SheetInstructions.getCell("G32");
            cellInstructionEnter6G3.value = "Volcanic ash or gases were observed at the site.";

            const cellInstructionEnter6H2 = SheetInstructions.getCell("F33");
            cellInstructionEnter6H2.value = "extreme weather";
            const cellInstructionEnter6H3 = SheetInstructions.getCell("G33");
            cellInstructionEnter6H3.value = "Precipitation resulted from a tropical storm (cyclone, hurricane, typhoon) or severe thunderstorm.";

            const cellInstructionEnter6I2 = SheetInstructions.getCell("F34");
            cellInstructionEnter6I2.value = "dew/frost";
            const cellInstructionEnter6I3 = SheetInstructions.getCell("G34");
            cellInstructionEnter6I3.value = "Sample resulted from collected dew or frost.";

            const cellInstructionEnter6J2 = SheetInstructions.getCell("F35");
            cellInstructionEnter6J2.value = "wind-blown snow";
            const cellInstructionEnter6J3 = SheetInstructions.getCell("G35");
            cellInstructionEnter6J3.value = "Some or all of the sample resulted from wind-blown snow.";

            const cellInstructionEnter7A1 = SheetInstructions.getCell("B36");
            SheetInstructions.mergeCells(`B36:E36`);
            cellInstructionEnter7A1.value =
                "Precipitation amount recorded by the standard rain or snow gauge. Amounts are in liquid depth of precipitation in millimetres. Missing values are entered as -99999. This measurement must be accompanied by a flag.";

            const cellInstructionEnter7A2 = SheetInstructions.getCell("F36");
            cellInstructionEnter7A2.value = "Flag";
            SheetInstructions.getCell("F36").font = {
                size: 10,
                bold: true,
            };
            SheetInstructions.getCell("F36").border = {
                top: { style: "double" },
            };
            const cellInstructionEnter7A3 = SheetInstructions.getCell("G36");
            SheetInstructions.getCell("G36").font = {
                size: 10,
                bold: true,
            };
            cellInstructionEnter7A3.value = "Definition (see footnote)";
            SheetInstructions.getCell("G36").border = {
                top: { style: "double" },
            };
            const cellInstructionEnter7B2 = SheetInstructions.getCell("F37");
            cellInstructionEnter7B2.value = "M";
            const cellInstructionEnter7B3 = SheetInstructions.getCell("G37");
            cellInstructionEnter7B3.value = "Missing - no value is available";

            const cellInstructionEnter7C2 = SheetInstructions.getCell("F38");
            cellInstructionEnter7C2.value = "X";
            const cellInstructionEnter7C3 = SheetInstructions.getCell("G38");
            cellInstructionEnter7C3.value = "Missing - value is invalid due to gauge malfunction, precipitation sampler malfunction, sample contamination, mishandling, etc.";

            const cellInstructionEnter7D2 = SheetInstructions.getCell("F39");
            cellInstructionEnter7D2.value = "V";
            const cellInstructionEnter7D3 = SheetInstructions.getCell("G39");
            cellInstructionEnter7D3.value = "Valid measurement";

            const cellInstructionEnter7E2 = SheetInstructions.getCell("F40");
            cellInstructionEnter7E2.value = "D";
            const cellInstructionEnter7E3 = SheetInstructions.getCell("G40");
            cellInstructionEnter7E3.value = "Valid measurement that is at or below the method detection limit and is set equal to the detection limit.";

            const cellInstructionEnter7F2 = SheetInstructions.getCell("F41");
            cellInstructionEnter7F2.value = "L";
            const cellInstructionEnter7F3 = SheetInstructions.getCell("G41");
            cellInstructionEnter7F3.value = "Valid measurement that is below the method detection limit.";

            const cellInstructionEnter7G1 = SheetInstructions.getCell("F42");
            SheetInstructions.mergeCells(`F42:G42`);
            cellInstructionEnter7G1.value = "M, X, and V are used for Standard Gauge and Sample Quantity measurements. All five flags are used for precipitation chemistry measurements.";
            SheetInstructions.getCell("F42").font = {
                size: 10,
                bold: true,
            };
            SheetInstructions.getCell("F42").border = {
                top: { style: "double" },
            };
            const cellInstructionEnter8 = SheetInstructions.getCell("B43");
            SheetInstructions.mergeCells(`B43:G44`);
            cellInstructionEnter8.value = "Mass in grams of precipitation collected by the precipitation chemistry sampler. Missing values are entered as -99999. This measurement must be accompanied by a flag (refer to table in instruction 7).";

            const cellInstructionEnter9 = SheetInstructions.getCell("B45");
            SheetInstructions.mergeCells(`B45:G46`);
            cellInstructionEnter9.value = "pH measurement in pH units. Missing values are entered as -99999. This measurement must be accompanied by a flag (refer to table in instruction 7)";

            const cellInstructionEnter10 = SheetInstructions.getCell("B47");
            SheetInstructions.mergeCells(`B47:G48`);
            cellInstructionEnter10.value = "Conductivity measurement in microSiemens per centimetre (µS/cm). Missing values are entered as -99999. This measurement must be accompanied by a flag (refer to table in instruction 7)";

            const cellInstructionEnter11 = SheetInstructions.getCell("B49");
            SheetInstructions.mergeCells(`B49:G50`);
            cellInstructionEnter11.value = "Acidity measurement in microequivalents per litre (µeq/L). Missing values are entered as -99999. This measurement must be accompanied by a flag (refer to table in instruction 7)";

            const cellInstructionEnter12 = SheetInstructions.getCell("B51");
            SheetInstructions.mergeCells(`B51:G52`);
            cellInstructionEnter12.value =
                "Sulfate concentration in milligrams per litre (mg/L). Report sulfate as SO42-  sulfate as S. Missing values are entered as -99999. This measurement must be accompanied by a flag (refer to table in instruction 7)";

            const cellInstructionEnter13 = SheetInstructions.getCell("B53");
            SheetInstructions.mergeCells(`B53:G54`);
            cellInstructionEnter13.value =
                "Ammonium concentration in milligrams per litre (mg/L). Report ammonium as NH4+ not ammonium as N. Missing values are entered as -99999. This measurement must be accompanied by a flag (refer to table in instruction 7)";

            const cellInstructionEnter14 = SheetInstructions.getCell("B55");
            SheetInstructions.mergeCells(`B55:G56`);
            cellInstructionEnter14.value =
                "Nitrate concentration in milligrams per litre (mg/L). Report nitrate as NO3- not nitrate as N. Missing values are entered as -99999. This measurement must be accompanied by a flag (refer to table in instruction 7)";

            const cellInstructionEnter15 = SheetInstructions.getCell("B57");
            SheetInstructions.mergeCells(`B57:G58`);
            cellInstructionEnter15.value =
                "Report chloride, calcium, potassium, magnesium, sodium, and fluoride concentrations in milligrams per litre (mg/L). Missing values are entered as -99999. These measurements must be accompanied by a flag (refer to table in instruction 7)";

            var filename = "WMO-GAWPrecipChemDataOnlyV3-" + year + "_GAW" + station_code + "_" + year + ".xlsx";
            workbook.xlsx
                .writeBuffer()
                .then((buffer) => saveAs(new Blob([buffer]), filename))
                .catch((err) => console.log("Error writing excel export", err));
        },
        cretaExcelKAHNoWMO(data, year) {
            var data_row = data.kah_data;
            var sort_data = data_row.sort((a, b) => parseInt(a.minggu) - parseInt(b.minggu));
            var kah_report = data.kah_report;
            var rows_kah = sort_data;
            const workbook = new ExcelJS.Workbook();

            //SHEET KAH
            const sheet1 = "Kimia Air Hujan";
            let SheetKAH = workbook.addWorksheet(sheet1);
            SheetKAH.views = [
                {
                    showGridLines: false,
                },
            ];

            const colKAH11C = SheetKAH.getColumn(3);
            colKAH11C.width = 2;

            const colKAH12K = SheetKAH.getColumn(11);
            colKAH12K.width = 2;

            const colKAH18A = SheetKAH.getColumn(1);
            colKAH18A.width = 15;

            const cellKAH2 = SheetKAH.getCell("A2");
            const titleKAH2 = SheetKAH.getRow(2);
            titleKAH2.height = 15;
            cellKAH2.value = "BADAN METEOROLOGI KLIMATOLOGI DAN GEOFISIKA";
            cellKAH2.font = {
                size: 10,
                bold: true,
            };
            cellKAH2.alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
            };
            SheetKAH.mergeCells("A2:R2");

            const cellKAH3 = SheetKAH.getCell("A3");
            const titleKAH3 = SheetKAH.getRow(3);
            titleKAH3.height = 15;
            cellKAH3.value = "Jl. Angkasa I No.2 Kemayoran, Jakarta 10720 Telp.: (021) 4246321, Fax.: (021) 4246703";
            cellKAH3.font = {
                size: 10,
            };
            cellKAH3.alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
            };
            SheetKAH.mergeCells("A3:R3");

            const cellKAH4 = SheetKAH.getCell("A4");
            const titleKAH4 = SheetKAH.getRow(4);
            titleKAH4.height = 15;
            cellKAH4.value = "P.O.Box 3540 Jkt, Website : http://www.bmkg.go.id";
            cellKAH4.font = {
                size: 10,
            };
            cellKAH4.alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
            };
            SheetKAH.mergeCells("A4:R4");

            SheetKAH.mergeCells("A5:R5");
            SheetKAH.getCell("A5:T5").border = {
                bottom: { style: "medium" },
            };

            const cellKAH7 = SheetKAH.getCell("A7");
            const titleKAH7 = SheetKAH.getRow(7);
            titleKAH7.height = 15;
            cellKAH7.value = "Pusat Informasi Perubahan Iklim";
            cellKAH7.font = {
                size: 10,
                bold: true,
            };
            cellKAH7.alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
            };
            SheetKAH.mergeCells("A7:R7");

            const cellKAH8 = SheetKAH.getCell("A8");
            const titleKAH8 = SheetKAH.getRow(8);
            titleKAH8.height = 15;
            cellKAH8.value = "Bidang Analisis Perubahan Iklim";
            cellKAH8.font = {
                size: 10,
            };
            cellKAH8.alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
            };
            SheetKAH.mergeCells("A8:R8");

            const cellKAH9 = SheetKAH.getCell("A9");
            const titleKAH9 = SheetKAH.getRow(9);
            titleKAH9.height = 15;
            cellKAH9.value = " ";
            cellKAH9.font = {
                size: 10,
                bold: true,
            };
            cellKAH9.alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
            };
            SheetKAH.mergeCells("A9:R9");
            cellKAH9.font = {
                color: { argb: "FF0000" },
            };

            const cellKAH11A = SheetKAH.getCell("A11");
            const titleKAH11A = SheetKAH.getRow(11);
            titleKAH11A.height = 15;
            cellKAH11A.value = "ID Station";
            cellKAH11A.font = {
                size: 10,
                bold: false,
            };
            cellKAH11A.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetKAH.mergeCells("A11:B11");

            const cellKAH11C = SheetKAH.getCell("C11");
            cellKAH11C.value = ":";
            cellKAH11C.font = {
                size: 10,
                bold: false,
            };
            cellKAH11C.alignment = {
                horizontal: "right",
                vertical: "middle",
                wrapText: true,
            };

            const cellKAH11D = SheetKAH.getCell("D11");
            cellKAH11D.value = kah_report.station_id;
            cellKAH11D.font = {
                size: 10,
                bold: true,
            };
            cellKAH11D.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetKAH.mergeCells("D11:H11");

            const cellKAH12A = SheetKAH.getCell("A12");
            const titleKAH12A = SheetKAH.getRow(12);
            titleKAH12A.height = 15;
            cellKAH12A.value = "Nama Stasiun";
            cellKAH12A.font = {
                size: 10,
                bold: false,
            };
            cellKAH12A.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetKAH.mergeCells("A12:B12");

            const cellKAH12C = SheetKAH.getCell("C12");
            cellKAH12C.value = ":";
            cellKAH12C.font = {
                size: 10,
                bold: false,
            };
            cellKAH12C.alignment = {
                horizontal: "right",
                vertical: "middle",
                wrapText: true,
            };

            const cellKAH12D = SheetKAH.getCell("D12");
            cellKAH12D.value = kah_report.station_name;
            cellKAH12D.font = {
                size: 10,
                bold: true,
            };
            cellKAH12D.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetKAH.mergeCells("D12:H12");

            const cellKAH12I = SheetKAH.getCell("I12");
            cellKAH12I.value = "Lintang";
            cellKAH12I.font = {
                size: 10,
                bold: false,
            };
            cellKAH12I.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetKAH.mergeCells("I12:J12");

            const cellKAH12K = SheetKAH.getCell("K12");
            cellKAH12K.value = ":";
            cellKAH12K.font = {
                size: 10,
                bold: false,
            };
            cellKAH12K.alignment = {
                horizontal: "right",
                vertical: "middle",
                wrapText: true,
            };

            const cellKAH12L = SheetKAH.getCell("L12");
            cellKAH12L.value = kah_report.lintang;
            cellKAH12L.font = {
                size: 10,
                bold: true,
            };
            cellKAH12L.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetKAH.mergeCells("L12:N12");

            const cellKAH13A = SheetKAH.getCell("A13");
            const titleKAH13A = SheetKAH.getRow(13);
            titleKAH13A.height = 15;
            cellKAH13A.value = "Provinsi";
            cellKAH13A.font = {
                size: 10,
                bold: false,
            };
            cellKAH13A.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetKAH.mergeCells("A13:B13");

            const cellKAH13C = SheetKAH.getCell("C13");
            cellKAH13C.value = ":";
            cellKAH13C.font = {
                size: 10,
                bold: false,
            };
            cellKAH13C.alignment = {
                horizontal: "right",
                vertical: "middle",
                wrapText: true,
            };

            const cellKAH13D = SheetKAH.getCell("D13");
            cellKAH13D.value = kah_report.provinsi;
            cellKAH13D.font = {
                size: 10,
                bold: true,
            };
            cellKAH13D.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetKAH.mergeCells("D13:H13");

            const cellKAH13I = SheetKAH.getCell("I13");
            cellKAH13I.value = "Bujur";
            cellKAH13I.font = {
                size: 10,
                bold: false,
            };
            cellKAH13I.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetKAH.mergeCells("I13:J13");

            const cellKAH13K = SheetKAH.getCell("K13");
            cellKAH13K.value = ":";
            cellKAH13K.font = {
                size: 10,
                bold: false,
            };
            cellKAH13K.alignment = {
                horizontal: "right",
                vertical: "middle",
                wrapText: true,
            };

            const cellKAH13L = SheetKAH.getCell("L13");
            cellKAH13L.value = kah_report.bujur;
            cellKAH13L.font = {
                size: 10,
                bold: true,
            };
            cellKAH13L.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetKAH.mergeCells("L13:N13");

            const cellKAH14A = SheetKAH.getCell("A14");
            const titleKAH14A = SheetKAH.getRow(14);
            titleKAH14A.height = 15;
            cellKAH14A.value = "Balai Besar Wilayah";
            cellKAH14A.font = {
                size: 10,
                bold: false,
            };
            cellKAH14A.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetKAH.mergeCells("A14:B14");

            const cellKAH14C = SheetKAH.getCell("C14");
            cellKAH14C.value = ":";
            cellKAH14C.font = {
                size: 10,
                bold: false,
            };
            cellKAH14C.alignment = {
                horizontal: "right",
                vertical: "middle",
                wrapText: true,
            };

            const cellKAH14D = SheetKAH.getCell("D14");
            cellKAH14D.value = kah_report.balai;
            cellKAH14D.font = {
                size: 10,
                bold: true,
            };
            cellKAH14D.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetKAH.mergeCells("D14:H14");

            const cellKAH14I = SheetKAH.getCell("I14");
            cellKAH14I.value = "Ketinggian";
            cellKAH14I.font = {
                size: 10,
                bold: false,
            };
            cellKAH14I.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetKAH.mergeCells("I14:J14");

            const cellKAH14K = SheetKAH.getCell("K14");
            cellKAH14K.value = ":";
            cellKAH14K.font = {
                size: 10,
                bold: false,
            };
            cellKAH14K.alignment = {
                horizontal: "right",
                vertical: "middle",
                wrapText: true,
            };

            const cellKAH14L = SheetKAH.getCell("L14");
            cellKAH14L.value = kah_report.ketinggian;
            cellKAH14L.font = {
                size: 10,
                bold: true,
            };
            cellKAH14L.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetKAH.mergeCells("L14:N14");

            const cellKAH16A = SheetKAH.getCell("A16");
            const titleKAH16A = SheetKAH.getRow(16);
            titleKAH16A.height = 15;
            cellKAH16A.value = "Parameter";
            cellKAH16A.font = {
                size: 10,
                bold: false,
            };
            cellKAH16A.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetKAH.mergeCells("A16:B16");

            const cellKAH16C = SheetKAH.getCell("C16");
            cellKAH16C.value = ":";
            cellKAH16C.font = {
                size: 10,
                bold: false,
            };
            cellKAH16C.alignment = {
                horizontal: "right",
                vertical: "middle",
                wrapText: true,
            };

            const cellKAH16D = SheetKAH.getCell("D16");
            cellKAH16D.value = kah_report.parameter;
            cellKAH16D.font = {
                size: 10,
                bold: true,
            };
            cellKAH16D.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetKAH.mergeCells("D16:H16");

            const cellKAH16I = SheetKAH.getCell("I16");
            cellKAH16I.value = "Satuan";
            cellKAH16I.font = {
                size: 10,
                bold: false,
            };
            cellKAH16I.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetKAH.mergeCells("I16:J16");

            const cellKAH16K = SheetKAH.getCell("K16");
            cellKAH16K.value = ":";
            cellKAH16K.font = {
                size: 10,
                bold: false,
            };
            cellKAH16K.alignment = {
                horizontal: "right",
                vertical: "middle",
                wrapText: true,
            };

            const cellKAH16L = SheetKAH.getCell("L16");
            cellKAH16L.value = "*)";
            cellKAH16L.font = {
                size: 10,
                bold: true,
            };
            cellKAH16L.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetKAH.mergeCells("L16:N16");

            const cellKAH17A = SheetKAH.getCell("A17");
            const titleKAH17A = SheetKAH.getRow(17);
            titleKAH17A.height = 15;
            cellKAH17A.value = "Jenis Data";
            cellKAH17A.font = {
                size: 10,
                bold: false,
            };
            cellKAH17A.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetKAH.mergeCells("A17:B17");

            const cellKAH17C = SheetKAH.getCell("C17");
            cellKAH17C.value = ":";
            cellKAH17C.font = {
                size: 10,
                bold: false,
            };
            cellKAH17C.alignment = {
                horizontal: "right",
                vertical: "middle",
                wrapText: true,
            };

            const cellKAH17D = SheetKAH.getCell("D17");
            cellKAH17D.value = kah_report.jenis_data;
            cellKAH17D.font = {
                size: 10,
                bold: true,
            };
            cellKAH17D.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetKAH.mergeCells("D17:H17");

            const cellKAH18A = SheetKAH.getCell("A18");
            const titleKAH18A = SheetKAH.getRow(18);
            titleKAH18A.height = 15;
            cellKAH18A.value = "interval data (bulan-tahun)";
            cellKAH18A.font = {
                size: 10,
                bold: false,
            };
            cellKAH18A.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetKAH.mergeCells("A18:B18");

            const cellKAH18C = SheetKAH.getCell("C18");
            cellKAH18C.value = ":";
            cellKAH18C.font = {
                size: 10,
                bold: false,
            };
            cellKAH18C.alignment = {
                horizontal: "right",
                vertical: "middle",
                wrapText: true,
            };

            const cellKAH18D = SheetKAH.getCell("D18");
            cellKAH18D.value = kah_report.interval;
            cellKAH18D.font = {
                size: 10,
                bold: true,
            };
            cellKAH18D.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetKAH.mergeCells("D18:H18");

            const cellKAH18I = SheetKAH.getCell("I18");
            cellKAH18I.value = "Tgl Cetak";
            cellKAH18I.font = {
                size: 10,
                bold: false,
            };
            cellKAH18I.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetKAH.mergeCells("I18:J18");

            const cellKAH18K = SheetKAH.getCell("K18");
            cellKAH18K.value = ":";
            cellKAH18K.font = {
                size: 10,
                bold: false,
            };
            cellKAH18K.alignment = {
                horizontal: "right",
                vertical: "middle",
                wrapText: true,
            };

            const cellKAH18L = SheetKAH.getCell("L18");
            cellKAH18L.value = moment().format("DD MMMM  YYYY");
            cellKAH18L.font = {
                size: 10,
                bold: true,
            };
            cellKAH18L.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetKAH.mergeCells("L18:N18");

            const cellKAH20A = SheetKAH.getCell("A20");
            const titleKAH20A = SheetKAH.getRow(20);
            titleKAH20A.height = 15;
            cellKAH20A.value = "*) Satuan";
            cellKAH20A.font = {
                size: 10,
                bold: false,
            };
            cellKAH20A.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };

            const cellKAH20B = SheetKAH.getCell("B20");
            cellKAH20B.value = ":  Derajat Keasaman (pH)  #  Daya Hantar/DH = (mho)  #  Ca, Mg, Na, K, NH4, Cl, SO4, NO3, Kesadahan Total (KT) = (mg/l";
            cellKAH20B.font = {
                size: 10,
                bold: false,
            };
            cellKAH20B.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetKAH.mergeCells("B20:T20");

            const cellKAH21B = SheetKAH.getCell("B21");
            cellKAH21B.value = ":  Alkalinity (AL), Acidity (AC) = (ueg/l),  Curah Hujan (CH) = mm";
            cellKAH21B.font = {
                size: 10,
                bold: false,
            };
            cellKAH21B.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetKAH.mergeCells("B21:T21");

            // TABLE KAH

            const dataSheetKAH1 = SheetKAH.getCell("A23");
            SheetKAH.getCell("A23").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            dataSheetKAH1.font = {
                size: 10,
                bold: true,
            };
            dataSheetKAH1.value = "Tahun";

            const dataSheetKAH2 = SheetKAH.getCell("B23");
            SheetKAH.getCell("B23").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            dataSheetKAH2.font = {
                size: 10,
                bold: true,
            };
            dataSheetKAH2.value = "Bulan";

            const dataSheetKAH3 = SheetKAH.getCell("C23");
            SheetKAH.getCell("C23").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            dataSheetKAH3.font = {
                size: 10,
                bold: true,
            };
            dataSheetKAH3.value = "Minggu";

            const dataSheetKAH4 = SheetKAH.getCell("D23");
            SheetKAH.getCell("D23").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            dataSheetKAH4.font = {
                size: 10,
                bold: true,
            };
            dataSheetKAH4.value = "pH";

            const dataSheetKAH5 = SheetKAH.getCell("E23");
            SheetKAH.getCell("E23").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            dataSheetKAH5.font = {
                size: 10,
                bold: true,
            };
            dataSheetKAH5.value = "DH";

            const dataSheetKAH6 = SheetKAH.getCell("F23");
            SheetKAH.getCell("F23").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            dataSheetKAH6.font = {
                size: 10,
                bold: true,
            };
            dataSheetKAH6.value = "Ca";

            const dataSheetKAH7 = SheetKAH.getCell("G23");
            SheetKAH.getCell("G23").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            dataSheetKAH7.font = {
                size: 10,
                bold: true,
            };
            dataSheetKAH7.value = "Mg";

            const dataSheetKAH8 = SheetKAH.getCell("H23");
            SheetKAH.getCell("H23").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            dataSheetKAH8.font = {
                size: 10,
                bold: true,
            };
            dataSheetKAH8.value = "Na";

            const dataSheetKAH9 = SheetKAH.getCell("I23");
            SheetKAH.getCell("I23").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            dataSheetKAH9.font = {
                size: 10,
                bold: true,
            };
            dataSheetKAH9.value = "K";

            const dataSheetKAH10 = SheetKAH.getCell("J23");
            SheetKAH.getCell("J23").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            dataSheetKAH10.font = {
                size: 10,
                bold: true,
            };
            dataSheetKAH10.value = "NH4";

            const dataSheetKAH11 = SheetKAH.getCell("K23");
            SheetKAH.getCell("K23").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            dataSheetKAH11.font = {
                size: 10,
                bold: true,
            };
            dataSheetKAH11.value = "Cl";

            const dataSheetKAH12 = SheetKAH.getCell("L23");
            SheetKAH.getCell("L23").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            dataSheetKAH12.font = {
                size: 10,
                bold: true,
            };
            dataSheetKAH12.value = "SO4";

            const dataSheetKAH13 = SheetKAH.getCell("M23");
            SheetKAH.getCell("M23").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            dataSheetKAH13.font = {
                size: 10,
                bold: true,
            };
            dataSheetKAH13.value = "NO3";

            const dataSheetKAH14 = SheetKAH.getCell("N23");
            SheetKAH.getCell("N23").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            dataSheetKAH14.font = {
                size: 10,
                bold: true,
            };
            dataSheetKAH14.value = "KT";

            const dataSheetKAH15 = SheetKAH.getCell("O23");
            SheetKAH.getCell("O23").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            dataSheetKAH15.font = {
                size: 10,
                bold: true,
            };
            dataSheetKAH15.value = "AC";

            const dataSheetKAH16 = SheetKAH.getCell("P23");
            SheetKAH.getCell("P23").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            dataSheetKAH16.font = {
                size: 10,
                bold: true,
            };
            dataSheetKAH16.value = "AL";

            const dataSheetKAH17 = SheetKAH.getCell("Q23");
            SheetKAH.getCell("Q23").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            dataSheetKAH17.font = {
                size: 10,
                bold: true,
            };
            dataSheetKAH17.value = "CH";

            const dataSheetKAH18 = SheetKAH.getCell("R23");
            SheetKAH.getCell("R23").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            dataSheetKAH18.font = {
                size: 10,
                bold: true,
            };
            dataSheetKAH18.value = "Keterangan";
            SheetKAH.mergeCells("A24: R24");

            const cellKAH24 = SheetKAH.getCell("A24");
            cellKAH24.height = 5;

            SheetKAH.columns = [
                { key: "tahun", width: 10 },
                { key: "bulan", width: 10 },
                { key: "minggu", width: 7 },
                { key: "ph", width: 7 },
                { key: "dh", width: 7 },
                { key: "ca", width: 7 },
                { key: "mg", width: 7 },
                { key: "na", width: 7 },
                { key: "k", width: 7 },
                { key: "nh4", width: 7 },
                { key: "cl", width: 7 },
                { key: "so4", width: 7 },
                { key: "no3", width: 7 },
                { key: "kt", width: 7 },
                { key: "ac", width: 7 },
                { key: "al", width: 7 },
                { key: "ch", width: 7 },
                { key: "keterangan", width: 15 },
            ];
            const dataSheetKAH24S = SheetKAH.getCell("S24");
            SheetKAH.getCell("S24").alignment = {
                horizontal: "left",
                vertical: "top",
            };
            dataSheetKAH24S.font = {
                size: 10,
                bold: false,
            };
            // dataSheetKAH24S.value ="kondisi luarbiasa, catatan lab dan catatan lainnya";
            dataSheetKAH24S.value = "";
            SheetKAH.mergeCells("S24: X24");

            SheetKAH.addRows(rows_kah);
            const rowCountKAH = SheetKAH.rowCount;
            // SheetKAH.mergeCells(`C24:D${rowCountKAH}+1`);

            for (let i = 22; i <= 22; i++) {
                const inst = SheetKAH.getRow(1 + i);

                for (let index = 1; index <= 18; index++) {
                    inst.getCell(index).border = {
                        top: { style: "thin" },
                        left: { style: "thin" },
                        bottom: { style: "thin" },
                        right: { style: "thin" },
                    };
                }
            }
            for (let i = 25; i <= rowCountKAH; i++) {
                const inst = SheetKAH.getRow(0 + i);

                for (let index = 1; index <= 18; index++) {
                    inst.getCell(index).border = {
                        top: { style: "thin" },
                        left: { style: "thin" },
                        bottom: { style: "thin" },
                        right: { style: "thin" },
                    };
                }
            }
            for (let i = 24; i <= rowCountKAH; i++) {
                const inst = SheetKAH.getRow(0 + i);

                for (let index = 1; index <= 17; index++) {
                    inst.getCell(index).alignment = {
                        horizontal: "center",
                        vertical: "middle",
                    };
                }
            }
            // FOOTER
            SheetKAH.mergeCells(`P${rowCountKAH + 2}:R${rowCountKAH + 2}`);
            // SheetKAH.getCell(`P${rowCountKAH + 2}`).value =
            //   "Jakarta, " + moment().format("DD MMMM  YYYY");

            SheetKAH.getCell(`P${rowCountKAH + 2}`).value = " ";

            SheetKAH.getCell(`P${rowCountKAH + 2}`).alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetKAH.getCell(`P${rowCountKAH + 2}`).font = {
                color: { argb: "FF0000" },
            };
            SheetKAH.mergeCells(`P${rowCountKAH + 3}:R${rowCountKAH + 3}`);
            SheetKAH.getCell(`P${rowCountKAH + 3}`).value = " ";

            // SheetKAH.getCell(`P${rowCountKAH + 3}`).value =
            // "Pejabat yang mengeluarkan";

            SheetKAH.getCell(`P${rowCountKAH + 3}`).alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetKAH.getCell(`P${rowCountKAH + 3}`).font = {
                color: { argb: "FF0000" },
            };

            SheetKAH.mergeCells(`P${rowCountKAH + 9}:R${rowCountKAH + 9}`);
            // SheetKAH.getCell(`P${rowCountKAH + 9}`).value = "NIP. : ";
            SheetKAH.getCell(`P${rowCountKAH + 9}`).value = " ";
            SheetKAH.getCell(`P${rowCountKAH + 9}`).alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetKAH.getCell(`P${rowCountKAH + 9}`).font = {
                color: { argb: "FF0000" },
            };
            var namefile = "Report Kimia Air Hujan " + kah_report.station_name + " Tahun " + year + ".xlsx";
            workbook.xlsx
                .writeBuffer()
                .then((buffer) => saveAs(new Blob([buffer]), namefile))
                .catch((err) => console.log("Error writing excel export", err));
            //END SHEET KAH
        },
        cretaExcelSPM(data, year) {
            var data_row = data.spm_data;
            // var data_row = data.kah_data
            var sort_data = data_row.sort((a, b) => parseInt(a.minggu) - parseInt(b.minggu));
            var spm_report = data.spm_report;
            // var spm_report = data.kah_report;
            var rows_spm = sort_data;
            const workbook = new ExcelJS.Workbook();
            const sheet2 = "SPM";

            let SheetSPM = workbook.addWorksheet(sheet2);
            SheetSPM.views = [
                {
                    showGridLines: false,
                },
            ];

            const colSPM11C = SheetSPM.getColumn(3);
            colSPM11C.width = 2;

            const colSPM12K = SheetSPM.getColumn(11);
            colSPM12K.width = 2;

            const colSPM18A = SheetSPM.getColumn(1);
            colSPM18A.width = 15;

            const cellSPM2 = SheetSPM.getCell("A2");
            const titleSPM2 = SheetSPM.getRow(2);
            titleSPM2.height = 15;
            cellSPM2.value = "BADAN METEOROLOGI KLIMATOLOGI DAN GEOFISIKA";
            cellSPM2.font = {
                size: 10,
                bold: true,
            };
            cellSPM2.alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
            };
            SheetSPM.mergeCells("A2:H2");

            const cellSPM3 = SheetSPM.getCell("A3");
            const titleSPM3 = SheetSPM.getRow(3);
            titleSPM3.height = 15;
            cellSPM3.value = "Jl. Angkasa I No.2 Kemayoran, Jakarta 10720 Telp.: (021) 4246321, Fax.: (021) 4246703";
            cellSPM3.font = {
                size: 10,
            };
            cellSPM3.alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
            };
            SheetSPM.mergeCells("A3:H3");

            const cellSPM4 = SheetSPM.getCell("A4");
            const titleSPM4 = SheetSPM.getRow(4);
            titleSPM4.height = 15;
            cellSPM4.value = "P.O.Box 3540 Jkt, Website : http://www.bmkg.go.id";
            cellSPM4.font = {
                size: 10,
            };
            cellSPM4.alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
            };
            SheetSPM.mergeCells("A4:H4");

            SheetSPM.mergeCells("A5:H5");
            SheetSPM.getCell("A5:H5").border = {
                bottom: { style: "medium" },
            };

            const cellSPM7 = SheetSPM.getCell("A7");
            const titleSPM7 = SheetSPM.getRow(7);
            titleSPM7.height = 15;
            cellSPM7.value = "Pusat Informasi Perubahan Iklim";
            cellSPM7.font = {
                size: 10,
                bold: true,
            };
            cellSPM7.alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
            };
            SheetSPM.mergeCells("A7:H7");

            const cellSPM8 = SheetSPM.getCell("A8");
            const titleSPM8 = SheetSPM.getRow(8);
            titleSPM8.height = 15;
            cellSPM8.value = "Bidang Analisis Perubahan Iklim";
            cellSPM8.font = {
                size: 10,
            };
            cellSPM8.alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
            };
            SheetSPM.mergeCells("A8:H8");

            const cellSPM9 = SheetSPM.getCell("A9");
            const titleSPM9 = SheetSPM.getRow(9);
            titleSPM9.height = 15;
            // cellSPM9.value = "Telp.: (021) 4246321 ext.2408";
            cellSPM9.value = " ";
            cellSPM9.font = {
                size: 10,
                bold: true,
            };
            cellSPM9.alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
            };
            SheetSPM.mergeCells("A9:H9");
            cellSPM9.font = {
                color: { argb: "FF0000" },
            };

            const cellSPM11A = SheetSPM.getCell("A11");
            const titleSPM11A = SheetSPM.getRow(11);
            titleSPM11A.height = 15;
            cellSPM11A.value = "ID Station";
            cellSPM11A.font = {
                size: 10,
                bold: false,
            };
            cellSPM11A.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetSPM.mergeCells("A11:B11");

            const cellSPM11C = SheetSPM.getCell("C11");
            cellSPM11C.value = `: ${spm_report.station_id}`;
            cellSPM11C.font = {
                size: 10,
                bold: true,
            };
            cellSPM11C.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetSPM.mergeCells("C11:F11");

            const cellSPM12A = SheetSPM.getCell("A12");
            const titleSPM12A = SheetSPM.getRow(12);
            titleSPM12A.height = 15;
            cellSPM12A.value = "Nama Stasiun";
            cellSPM12A.font = {
                size: 10,
                bold: false,
            };
            cellSPM12A.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetSPM.mergeCells("A12:B12");

            const cellSPM12C = SheetSPM.getCell("C12");
            cellSPM12C.value = `: ${spm_report.station_name}`;
            cellSPM12C.font = {
                size: 10,
                bold: true,
            };
            cellSPM12C.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetSPM.mergeCells("C12:E12");

            const cellSPM12F = SheetSPM.getCell("F12");
            cellSPM12F.value = "Lintang";
            cellSPM12F.font = {
                size: 10,
                bold: false,
            };
            cellSPM12F.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            // SheetSPM.mergeCells("F12:G12");

            const cellSPM12G = SheetSPM.getCell("G12");
            cellSPM12G.value = `: ${spm_report.lintang}`;
            cellSPM12G.font = {
                size: 10,
                bold: true,
            };
            cellSPM12G.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetSPM.mergeCells("G12:H12");

            const cellSPM13A = SheetSPM.getCell("A13");
            const titleSPM13A = SheetSPM.getRow(13);
            titleSPM13A.height = 15;
            cellSPM13A.value = "Provinsi";
            cellSPM13A.font = {
                size: 10,
                bold: false,
            };
            cellSPM13A.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetSPM.mergeCells("A13:B13");

            const cellSPM13C = SheetSPM.getCell("C13");
            cellSPM13C.value = `: ${spm_report.provinsi}`;
            cellSPM13C.font = {
                size: 10,
                bold: true,
            };
            cellSPM13C.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetSPM.mergeCells("C13:E13");

            const cellSPM13F = SheetSPM.getCell("F13");
            cellSPM13F.value = "Bujur";
            cellSPM13F.font = {
                size: 10,
                bold: false,
            };
            cellSPM13F.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            // SheetSPM.mergeCells("F13:G13");

            const cellSPM13G = SheetSPM.getCell("G13");
            cellSPM13G.value = `: ${spm_report.bujur}`;
            cellSPM13G.font = {
                size: 10,
                bold: true,
            };
            cellSPM13G.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetSPM.mergeCells("G13:H13");

            const cellSPM14A = SheetSPM.getCell("A14");
            const titleSPM14A = SheetSPM.getRow(14);
            titleSPM14A.height = 15;
            cellSPM14A.value = "Balai Besar Wilayah";
            cellSPM14A.font = {
                size: 10,
                bold: false,
            };
            cellSPM14A.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetSPM.mergeCells("A14:B14");

            const cellSPM14C = SheetSPM.getCell("C14");
            cellSPM14C.value = `: ${spm_report.balai}`;
            cellSPM14C.font = {
                size: 10,
                bold: true,
            };
            cellSPM14C.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetSPM.mergeCells("C14:E14");

            const cellSPM14F = SheetSPM.getCell("F14");
            cellSPM14F.value = "Ketinggian";
            cellSPM14F.font = {
                size: 10,
                bold: false,
            };
            cellSPM14F.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            // SheetSPM.mergeCells("F14:G14");

            const cellSPM14G = SheetSPM.getCell("G14");
            cellSPM14G.value = `: ${spm_report.ketinggian}`;
            cellSPM14G.font = {
                size: 10,
                bold: true,
            };
            cellSPM14G.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetSPM.mergeCells("G14:H14");

            const cellSPM16A = SheetSPM.getCell("A16");
            const titleSPM16A = SheetSPM.getRow(16);
            titleSPM16A.height = 15;
            cellSPM16A.value = "Parameter";
            cellSPM16A.font = {
                size: 10,
                bold: false,
            };
            cellSPM16A.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetSPM.mergeCells("A16:B16");

            const cellSPM16C = SheetSPM.getCell("C16");
            cellSPM16C.value = `: ${spm_report.parameter}`;
            cellSPM16C.font = {
                size: 10,
                bold: true,
            };
            cellSPM16C.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetSPM.mergeCells("C16:E16");

            const cellSPM16F = SheetSPM.getCell("F16");
            cellSPM16F.value = "Satuan";
            cellSPM16F.font = {
                size: 10,
                bold: false,
            };
            cellSPM16F.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            // SheetSPM.mergeCells("F16:G16");

            const cellSPM16G = SheetSPM.getCell("G16");
            cellSPM16G.value = "*)";
            cellSPM16G.font = {
                size: 10,
                bold: true,
            };
            cellSPM16G.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetSPM.mergeCells("G16:H16");

            const cellSPM17A = SheetSPM.getCell("A17");
            const titleSPM17A = SheetSPM.getRow(17);
            titleSPM17A.height = 15;
            cellSPM17A.value = "Jenis Data";
            cellSPM17A.font = {
                size: 10,
                bold: false,
            };
            cellSPM17A.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetSPM.mergeCells("A17:B17");

            const cellSPM17C = SheetSPM.getCell("C17");
            cellSPM17C.value = `: ${spm_report.jenis_data}`;
            cellSPM17C.font = {
                size: 10,
                bold: true,
            };
            cellSPM17C.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetSPM.mergeCells("C17:E17");

            const cellSPM18A = SheetSPM.getCell("A18");
            const titleSPM18A = SheetSPM.getRow(18);
            titleSPM18A.height = 15;
            cellSPM18A.value = "Periode";
            cellSPM18A.font = {
                size: 10,
                bold: false,
            };
            cellSPM18A.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetSPM.mergeCells("A18:B18");

            const cellSPM18C = SheetSPM.getCell("C18");
            cellSPM18C.value = `: ${spm_report.interval}`;
            cellSPM18C.font = {
                size: 10,
                bold: true,
            };
            cellSPM18C.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetSPM.mergeCells("C18:E18");

            const cellSPM18F = SheetSPM.getCell("F18");
            cellSPM18F.value = "Tgl Cetak";
            cellSPM18F.font = {
                size: 10,
                bold: false,
            };
            cellSPM18F.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            // SheetSPM.mergeCells("F18:G18");

            const cellSPM18G = SheetSPM.getCell("G18");
            cellSPM18G.value = moment().format("DD MMMM  YYYY");
            cellSPM18G.font = {
                size: 10,
                bold: true,
            };
            cellSPM18G.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetSPM.mergeCells("G18:H18");

            // TABLE SPM

            // const dataSheetSPM1 = SheetSPM.getCell("A20");
            // SheetSPM.getCell("A20").alignment = {
            //   horizontal: "center",
            //   vertical: "middle",
            // };
            // dataSheetSPM1.font = {
            //   size: 10,
            //   bold: true,
            // };
            // dataSheetSPM1.value = "Tahun";

            // const dataSheetSPM2 = SheetSPM.getCell("B20");
            // SheetSPM.getCell("B20").alignment = {
            //   horizontal: "center",
            //   vertical: "middle",
            // };
            // dataSheetSPM2.font = {
            //   size: 10,
            //   bold: true,
            // };
            // dataSheetSPM2.value = "Bulan";

            const dataSheetSPM3 = SheetSPM.getCell("C20");
            SheetSPM.getCell("C20").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            dataSheetSPM3.font = {
                size: 10,
                bold: true,
            };
            dataSheetSPM3.value = "Tahun";

            const dataSheetSPM4 = SheetSPM.getCell("D20");
            SheetSPM.getCell("D20").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            dataSheetSPM4.font = {
                size: 10,
                bold: true,
            };
            dataSheetSPM4.value = "Bulan";

            const dataSheetSPM5 = SheetSPM.getCell("E20");
            SheetSPM.getCell("E20").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            dataSheetSPM5.font = {
                size: 10,
                bold: true,
            };
            dataSheetSPM5.value = "Minggu";

            const dataSheetSPM6 = SheetSPM.getCell("F20");
            SheetSPM.getCell("F20").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            dataSheetSPM6.font = {
                size: 10,
                bold: true,
            };
            dataSheetSPM6.value = "Konsentrasi SPM";

            const dataSheetSPM7 = SheetSPM.getCell("G20");
            SheetSPM.getCell("G20").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            dataSheetSPM7.font = {
                size: 10,
                bold: true,
            };
            dataSheetSPM7.value = "Keterangan";

            // const dataSheetSPM8 = SheetSPM.getCell("H20");
            // SheetSPM.getCell("H20").alignment = {
            //   horizontal: "center",
            //   vertical: "middle",
            // };
            // dataSheetSPM8.font = {
            //   size: 10,
            //   bold: true,
            // };
            // dataSheetSPM8.value = "Konsentrasi SPM";

            // const dataSheetSPM18 = SheetSPM.getCell("I20");
            // SheetSPM.getCell("I20").alignment = {
            //   horizontal: "center",
            //   vertical: "middle",
            // };
            // dataSheetSPM18.font = {
            //   size: 10,
            //   bold: true,
            // };
            // dataSheetSPM18.value = "Keterangan";

            SheetSPM.columns = [
                // { key: "no", width: 8 },
                // { key: "station_id", width: 14 },
                // { key: "station_name", width: 20 },
                // { key: "tgl_sampel", width: 15 },
                { key: "" },
                { key: "" },
                { key: "tahun", width: 15 },
                { key: "bulan", width: 15 },
                { key: "minggu", width: 10 },
                { key: "konsentrasi_spm", width: 15 },
                { key: "keterangan", width: 20 },

                { key: "", width: 15 },
            ];

            SheetSPM.addRows(rows_spm);
            const rowCountSPM = SheetSPM.rowCount;
            // SheetSPM.mergeCells(`C24:D${rowCountSPM}+1`);

            // for (let i = 19; i <= 19; i++) {
            //   const inst = SheetSPM.getRow(1 + i);

            //   for (let index = 1; index <= 9; index++) {
            //     inst.getCell(index).border = {
            //       top: { style: "thin" },
            //       left: { style: "thin" },
            //       bottom: { style: "thin" },
            //       right: { style: "thin" },
            //     };
            //   }
            // }
            for (let i = 20; i <= rowCountSPM; i++) {
                const inst = SheetSPM.getRow(0 + i);

                for (let index = 3; index <= 7; index++) {
                    inst.getCell(index).border = {
                        top: { style: "thin" },
                        left: { style: "thin" },
                        bottom: { style: "thin" },
                        right: { style: "thin" },
                    };
                }
            }
            for (let i = 20; i <= rowCountSPM; i++) {
                const inst = SheetSPM.getRow(0 + i);

                for (let index = 1; index <= 8; index++) {
                    inst.getCell(index).alignment = {
                        horizontal: "center",
                        vertical: "middle",
                    };
                }
            }
            // FOOTER
            SheetSPM.mergeCells(`G${rowCountSPM + 2}:H${rowCountSPM + 2}`);
            SheetSPM.getCell(`G${rowCountSPM + 2}`).value = " ";
            // "Jakarta, " + moment().format("DD MMMM  YYYY");
            SheetSPM.getCell(`G${rowCountSPM + 2}`).alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetSPM.getCell(`G${rowCountSPM + 2}`).font = {
                color: { argb: "FF0000" },
            };
            SheetSPM.mergeCells(`G${rowCountSPM + 3}:H${rowCountSPM + 3}`);
            // SheetSPM.getCell(`G${rowCountSPM + 3}`).value =
            //   "Pejabat yang mengeluarkan";
            SheetSPM.getCell(`G${rowCountSPM + 3}`).value = " ";
            SheetSPM.getCell(`G${rowCountSPM + 3}`).alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetSPM.getCell(`G${rowCountSPM + 3}`).font = {
                color: { argb: "FF0000" },
            };

            SheetSPM.mergeCells(`G${rowCountSPM + 9}:H${rowCountSPM + 9}`);
            SheetSPM.getCell(`G${rowCountSPM + 9}`).value = "  ";
            // SheetSPM.getCell(`G${rowCountSPM + 9}`).value = "NIP. : ";
            SheetSPM.getCell(`G${rowCountSPM + 9}`).alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetSPM.getCell(`G${rowCountSPM + 9}`).font = {
                color: { argb: "FF0000" },
            };
            var namefile = "Report Suspended Particulate Matter(SPM) " + spm_report.station_name + " Tahun " + year + ".xlsx";
            workbook.xlsx
                .writeBuffer()
                .then((buffer) => saveAs(new Blob([buffer]), namefile))
                .catch((err) => console.log("Error writing excel export", err));
        },
        cretaExcelAerosol(data, year) {
            var data_row = data.aerosol_data;
            var sort_data = data_row.sort((a, b) => parseInt(a.minggu) - parseInt(b.minggu));
            var aerosol_report = data.aerosol_report;
            var rows_aerosol_data = sort_data;
            const workbook = new ExcelJS.Workbook();
            const sheet4 = "Aerosol";
            let SheetAerosol = workbook.addWorksheet(sheet4);
            SheetAerosol.views = [
                {
                    showGridLines: false,
                },
            ];

            const colAerosol11C = SheetAerosol.getColumn(3);
            colAerosol11C.width = 2;

            const colAerosol12K = SheetAerosol.getColumn(11);
            colAerosol12K.width = 2;

            const colAerosol18A = SheetAerosol.getColumn(1);
            colAerosol18A.width = 15;

            const cellAerosol2 = SheetAerosol.getCell("A2");
            const titleAerosol2 = SheetAerosol.getRow(2);
            titleAerosol2.height = 15;
            cellAerosol2.value = "BADAN METEOROLOGI KLIMATOLOGI DAN GEOFISIKA";
            cellAerosol2.font = {
                size: 10,
                bold: true,
            };
            cellAerosol2.alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
            };
            SheetAerosol.mergeCells("A2:P2");

            const cellAerosol3 = SheetAerosol.getCell("A3");
            const titleAerosol3 = SheetAerosol.getRow(3);
            titleAerosol3.height = 15;
            cellAerosol3.value = "Jl. Angkasa I No.2 Kemayoran, Jakarta 10720 Telp.: (021) 4246321, Fax.: (021) 4246703";
            cellAerosol3.font = {
                size: 10,
            };
            cellAerosol3.alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
            };
            SheetAerosol.mergeCells("A3:P3");

            const cellAerosol4 = SheetAerosol.getCell("A4");
            const titleAerosol4 = SheetAerosol.getRow(4);
            titleAerosol4.height = 15;
            cellAerosol4.value = "P.O.Box 3540 Jkt, Website : http://www.bmkg.go.id";
            cellAerosol4.font = {
                size: 10,
            };
            cellAerosol4.alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
            };
            SheetAerosol.mergeCells("A4:P4");

            SheetAerosol.mergeCells("A5:P5");
            SheetAerosol.getCell("A5:T5").border = {
                bottom: { style: "medium" },
            };

            const cellAerosol7 = SheetAerosol.getCell("A7");
            const titleAerosol7 = SheetAerosol.getRow(7);
            titleAerosol7.height = 15;
            cellAerosol7.value = "Pusat Informasi Perubahan Iklim";
            cellAerosol7.font = {
                size: 10,
                bold: true,
            };
            cellAerosol7.alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
            };
            SheetAerosol.mergeCells("A7:P7");

            const cellAerosol8 = SheetAerosol.getCell("A8");
            const titleAerosol8 = SheetAerosol.getRow(8);
            titleAerosol8.height = 15;
            cellAerosol8.value = "Bidang Analisis Perubahan Iklim";
            cellAerosol8.font = {
                size: 10,
            };
            cellAerosol8.alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
            };
            SheetAerosol.mergeCells("A8:P8");

            const cellAerosol9 = SheetAerosol.getCell("A9");
            const titleAerosol9 = SheetAerosol.getRow(9);
            titleAerosol9.height = 15;
            cellAerosol9.value = " ";
            cellAerosol9.font = {
                size: 10,
                bold: true,
            };
            cellAerosol9.alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
            };
            SheetAerosol.mergeCells("A9:P9");
            cellAerosol9.font = {
                color: { argb: "FF0000" },
            };

            const cellAerosol11A = SheetAerosol.getCell("A11");
            const titleAerosol11A = SheetAerosol.getRow(11);
            titleAerosol11A.height = 15;
            cellAerosol11A.value = "ID Station";
            cellAerosol11A.font = {
                size: 10,
                bold: false,
            };
            cellAerosol11A.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetAerosol.mergeCells("A11:B11");

            const cellAerosol11C = SheetAerosol.getCell("C11");
            cellAerosol11C.value = ":";
            cellAerosol11C.font = {
                size: 10,
                bold: false,
            };
            cellAerosol11C.alignment = {
                horizontal: "right",
                vertical: "middle",
                wrapText: true,
            };

            const cellAerosol11D = SheetAerosol.getCell("D11");
            cellAerosol11D.value = aerosol_report.station_id;
            cellAerosol11D.font = {
                size: 10,
                bold: true,
            };
            cellAerosol11D.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetAerosol.mergeCells("D11:H11");

            const cellAerosol12A = SheetAerosol.getCell("A12");
            const titleAerosol12A = SheetAerosol.getRow(12);
            titleAerosol12A.height = 15;
            cellAerosol12A.value = "Nama Stasiun";
            cellAerosol12A.font = {
                size: 10,
                bold: false,
            };
            cellAerosol12A.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetAerosol.mergeCells("A12:B12");

            const cellAerosol12C = SheetAerosol.getCell("C12");
            cellAerosol12C.value = ":";
            cellAerosol12C.font = {
                size: 10,
                bold: false,
            };
            cellAerosol12C.alignment = {
                horizontal: "right",
                vertical: "middle",
                wrapText: true,
            };

            const cellAerosol12D = SheetAerosol.getCell("D12");
            cellAerosol12D.value = aerosol_report.station_name;
            cellAerosol12D.font = {
                size: 10,
                bold: true,
            };
            cellAerosol12D.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetAerosol.mergeCells("D12:H12");

            const cellAerosol12I = SheetAerosol.getCell("I12");
            cellAerosol12I.value = "Lintang";
            cellAerosol12I.font = {
                size: 10,
                bold: false,
            };
            cellAerosol12I.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetAerosol.mergeCells("I12:J12");

            const cellAerosol12K = SheetAerosol.getCell("K12");
            cellAerosol12K.value = ":";
            cellAerosol12K.font = {
                size: 10,
                bold: false,
            };
            cellAerosol12K.alignment = {
                horizontal: "right",
                vertical: "middle",
                wrapText: true,
            };

            const cellAerosol12L = SheetAerosol.getCell("L12");
            cellAerosol12L.value = aerosol_report.lintang;
            cellAerosol12L.font = {
                size: 10,
                bold: true,
            };
            cellAerosol12L.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetAerosol.mergeCells("L12:P12");

            const cellAerosol13A = SheetAerosol.getCell("A13");
            const titleAerosol13A = SheetAerosol.getRow(13);
            titleAerosol13A.height = 15;
            cellAerosol13A.value = "Provinsi";
            cellAerosol13A.font = {
                size: 10,
                bold: false,
            };
            cellAerosol13A.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetAerosol.mergeCells("A13:B13");

            const cellAerosol13C = SheetAerosol.getCell("C13");
            cellAerosol13C.value = ":";
            cellAerosol13C.font = {
                size: 10,
                bold: false,
            };
            cellAerosol13C.alignment = {
                horizontal: "right",
                vertical: "middle",
                wrapText: true,
            };

            const cellAerosol13D = SheetAerosol.getCell("D13");
            cellAerosol13D.value = aerosol_report.provinsi;
            cellAerosol13D.font = {
                size: 10,
                bold: true,
            };
            cellAerosol13D.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetAerosol.mergeCells("D13:H13");

            const cellAerosol13I = SheetAerosol.getCell("I13");
            cellAerosol13I.value = "Bujur";
            cellAerosol13I.font = {
                size: 10,
                bold: false,
            };
            cellAerosol13I.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetAerosol.mergeCells("I13:J13");

            const cellAerosol13K = SheetAerosol.getCell("K13");
            cellAerosol13K.value = ":";
            cellAerosol13K.font = {
                size: 10,
                bold: false,
            };
            cellAerosol13K.alignment = {
                horizontal: "right",
                vertical: "middle",
                wrapText: true,
            };

            const cellAerosol13L = SheetAerosol.getCell("L13");
            cellAerosol13L.value = aerosol_report.bujur;
            cellAerosol13L.font = {
                size: 10,
                bold: true,
            };
            cellAerosol13L.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetAerosol.mergeCells("L13:P13");

            const cellAerosol14A = SheetAerosol.getCell("A14");
            const titleAerosol14A = SheetAerosol.getRow(14);
            titleAerosol14A.height = 15;
            cellAerosol14A.value = "Balai Besar Wilayah";
            cellAerosol14A.font = {
                size: 10,
                bold: false,
            };
            cellAerosol14A.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetAerosol.mergeCells("A14:B14");

            const cellAerosol14C = SheetAerosol.getCell("C14");
            cellAerosol14C.value = ":";
            cellAerosol14C.font = {
                size: 10,
                bold: false,
            };
            cellAerosol14C.alignment = {
                horizontal: "right",
                vertical: "middle",
                wrapText: true,
            };

            const cellAerosol14D = SheetAerosol.getCell("D14");
            cellAerosol14D.value = aerosol_report.balai;
            cellAerosol14D.font = {
                size: 10,
                bold: true,
            };
            cellAerosol14D.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetAerosol.mergeCells("D14:H14");

            const cellAerosol14I = SheetAerosol.getCell("I14");
            cellAerosol14I.value = "Ketinggian";
            cellAerosol14I.font = {
                size: 10,
                bold: false,
            };
            cellAerosol14I.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetAerosol.mergeCells("I14:J14");

            const cellAerosol14K = SheetAerosol.getCell("K14");
            cellAerosol14K.value = ":";
            cellAerosol14K.font = {
                size: 10,
                bold: false,
            };
            cellAerosol14K.alignment = {
                horizontal: "right",
                vertical: "middle",
                wrapText: true,
            };

            const cellAerosol14L = SheetAerosol.getCell("L14");
            cellAerosol14L.value = aerosol_report.ketinggian;
            cellAerosol14L.font = {
                size: 10,
                bold: true,
            };
            cellAerosol14L.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetAerosol.mergeCells("L14:P14");

            const cellAerosol16A = SheetAerosol.getCell("A16");
            const titleAerosol16A = SheetAerosol.getRow(16);
            titleAerosol16A.height = 15;
            cellAerosol16A.value = "Parameter";
            cellAerosol16A.font = {
                size: 10,
                bold: false,
            };
            cellAerosol16A.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetAerosol.mergeCells("A16:B16");

            const cellAerosol16C = SheetAerosol.getCell("C16");
            cellAerosol16C.value = ":";
            cellAerosol16C.font = {
                size: 10,
                bold: false,
            };
            cellAerosol16C.alignment = {
                horizontal: "right",
                vertical: "middle",
                wrapText: true,
            };

            const cellAerosol16D = SheetAerosol.getCell("D16");
            cellAerosol16D.value = aerosol_report.parameter;
            cellAerosol16D.font = {
                size: 10,
                bold: true,
            };
            cellAerosol16D.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetAerosol.mergeCells("D16:H16");

            const cellAerosol16I = SheetAerosol.getCell("I16");
            cellAerosol16I.value = "Satuan";
            cellAerosol16I.font = {
                size: 10,
                bold: false,
            };
            cellAerosol16I.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetAerosol.mergeCells("I16:J16");

            const cellAerosol16K = SheetAerosol.getCell("K16");
            cellAerosol16K.value = ":";
            cellAerosol16K.font = {
                size: 10,
                bold: false,
            };
            cellAerosol16K.alignment = {
                horizontal: "right",
                vertical: "middle",
                wrapText: true,
            };

            const cellAerosol16L = SheetAerosol.getCell("L16");
            cellAerosol16L.value = "*)";
            cellAerosol16L.font = {
                size: 10,
                bold: true,
            };
            cellAerosol16L.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetAerosol.mergeCells("L16:P16");

            const cellAerosol17A = SheetAerosol.getCell("A17");
            const titleAerosol17A = SheetAerosol.getRow(17);
            titleAerosol17A.height = 15;
            cellAerosol17A.value = "Jenis Data";
            cellAerosol17A.font = {
                size: 10,
                bold: false,
            };
            cellAerosol17A.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetAerosol.mergeCells("A17:B17");

            const cellAerosol17C = SheetAerosol.getCell("C17");
            cellAerosol17C.value = ":";
            cellAerosol17C.font = {
                size: 10,
                bold: false,
            };
            cellAerosol17C.alignment = {
                horizontal: "right",
                vertical: "middle",
                wrapText: true,
            };

            const cellAerosol17D = SheetAerosol.getCell("D17");
            cellAerosol17D.value = aerosol_report.jenis_data;
            cellAerosol17D.font = {
                size: 10,
                bold: true,
            };
            cellAerosol17D.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetAerosol.mergeCells("D17:H17");

            const cellAerosol18A = SheetAerosol.getCell("A18");
            const titleAerosol18A = SheetAerosol.getRow(18);
            titleAerosol18A.height = 15;
            cellAerosol18A.value = "interval data (bulan-tahun)";
            cellAerosol18A.font = {
                size: 10,
                bold: false,
            };
            cellAerosol18A.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetAerosol.mergeCells("A18:B18");

            const cellAerosol18C = SheetAerosol.getCell("C18");
            cellAerosol18C.value = ":";
            cellAerosol18C.font = {
                size: 10,
                bold: false,
            };
            cellAerosol18C.alignment = {
                horizontal: "right",
                vertical: "middle",
                wrapText: true,
            };

            const cellAerosol18D = SheetAerosol.getCell("D18");
            cellAerosol18D.value = aerosol_report.interval;
            cellAerosol18D.font = {
                size: 10,
                bold: true,
            };
            cellAerosol18D.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetAerosol.mergeCells("D18:H18");

            const cellAerosol18I = SheetAerosol.getCell("I18");
            cellAerosol18I.value = "Tgl Cetak";
            cellAerosol18I.font = {
                size: 10,
                bold: false,
            };
            cellAerosol18I.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetAerosol.mergeCells("I18:J18");

            const cellAerosol18K = SheetAerosol.getCell("K18");
            cellAerosol18K.value = ":";
            cellAerosol18K.font = {
                size: 10,
                bold: false,
            };
            cellAerosol18K.alignment = {
                horizontal: "right",
                vertical: "middle",
                wrapText: true,
            };

            const cellAerosol18L = SheetAerosol.getCell("L18");
            cellAerosol18L.value = moment().format("DD MMMM  YYYY");
            cellAerosol18L.font = {
                size: 10,
                bold: true,
            };
            cellAerosol18L.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetAerosol.mergeCells("L18:P18");

            const cellAerosol20A = SheetAerosol.getCell("A20");
            const titleAerosol20A = SheetAerosol.getRow(20);
            titleAerosol20A.height = 15;
            cellAerosol20A.value = "*) Satuan";
            cellAerosol20A.font = {
                size: 10,
                bold: false,
            };
            cellAerosol20A.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };

            const cellAerosol20B = SheetAerosol.getCell("B20");
            cellAerosol20B.value = ":  Konsentrasi Aerosol (ug/m3), Derajat Keasaman (pH)  #  Daya Hantar/DH = (mho)  #  Ca, Mg, Na, K, NH4, Cl, SO4, NO3, Kesadahan Total (KT) = (mg/l)";
            cellAerosol20B.font = {
                size: 10,
                bold: false,
            };
            cellAerosol20B.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetAerosol.mergeCells("B20:T20");

            // TABLE Aerosol

            const dataSheetAerosol1 = SheetAerosol.getCell("A23");
            SheetAerosol.getCell("A23").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            dataSheetAerosol1.font = {
                size: 10,
                bold: true,
            };
            dataSheetAerosol1.value = "Tahun";

            const dataSheetAerosol2 = SheetAerosol.getCell("B23");
            SheetAerosol.getCell("B23").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            dataSheetAerosol2.font = {
                size: 10,
                bold: true,
            };
            dataSheetAerosol2.value = "Bulan";

            const dataSheetAerosol3 = SheetAerosol.getCell("C23");
            SheetAerosol.getCell("C23").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            dataSheetAerosol3.font = {
                size: 10,
                bold: true,
            };
            dataSheetAerosol3.value = "Minggu";

            const dataSheetAerosol4 = SheetAerosol.getCell("D23");
            SheetAerosol.getCell("D23").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            dataSheetAerosol4.font = {
                size: 10,
                bold: true,
            };
            dataSheetAerosol4.value = "Konsentrasi Aerosol";

            const dataSheetAerosol5 = SheetAerosol.getCell("E23");
            SheetAerosol.getCell("E23").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            dataSheetAerosol5.font = {
                size: 10,
                bold: true,
            };
            dataSheetAerosol5.value = "pH";

            const dataSheetAerosol6 = SheetAerosol.getCell("F23");
            SheetAerosol.getCell("F23").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            dataSheetAerosol6.font = {
                size: 10,
                bold: true,
            };
            dataSheetAerosol6.value = "DH";

            const dataSheetAerosol7 = SheetAerosol.getCell("G23");
            SheetAerosol.getCell("G23").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            dataSheetAerosol7.font = {
                size: 10,
                bold: true,
            };
            dataSheetAerosol7.value = "Ca";

            const dataSheetAerosol8 = SheetAerosol.getCell("H23");
            SheetAerosol.getCell("H23").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            dataSheetAerosol8.font = {
                size: 10,
                bold: true,
            };
            dataSheetAerosol8.value = "Mg";

            const dataSheetAerosol9 = SheetAerosol.getCell("I23");
            SheetAerosol.getCell("I23").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            dataSheetAerosol9.font = {
                size: 10,
                bold: true,
            };
            dataSheetAerosol9.value = "Na";

            const dataSheetAerosol10 = SheetAerosol.getCell("J23");
            SheetAerosol.getCell("J23").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            dataSheetAerosol10.font = {
                size: 10,
                bold: true,
            };
            dataSheetAerosol10.value = "K";

            const dataSheetAerosol11 = SheetAerosol.getCell("K23");
            SheetAerosol.getCell("K23").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            dataSheetAerosol11.font = {
                size: 10,
                bold: true,
            };
            dataSheetAerosol11.value = "NH4";

            const dataSheetAerosol12 = SheetAerosol.getCell("L23");
            SheetAerosol.getCell("L23").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            dataSheetAerosol12.font = {
                size: 10,
                bold: true,
            };
            dataSheetAerosol12.value = "Cl";

            const dataSheetAerosol13 = SheetAerosol.getCell("M23");
            SheetAerosol.getCell("M23").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            dataSheetAerosol13.font = {
                size: 10,
                bold: true,
            };
            dataSheetAerosol13.value = "SO4";

            const dataSheetAerosol14 = SheetAerosol.getCell("N23");
            SheetAerosol.getCell("N23").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            dataSheetAerosol14.font = {
                size: 10,
                bold: true,
            };
            dataSheetAerosol14.value = "NO3";

            const dataSheetAerosol15 = SheetAerosol.getCell("O23");
            SheetAerosol.getCell("O23").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            dataSheetAerosol15.font = {
                size: 10,
                bold: true,
            };
            dataSheetAerosol15.value = "KT";

            const dataSheetAerosol16 = SheetAerosol.getCell("P23");
            SheetAerosol.getCell("P23").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            dataSheetAerosol16.font = {
                size: 10,
                bold: true,
            };
            dataSheetAerosol16.value = "Keterangan";
            SheetAerosol.mergeCells("A24: P24");
            const cellAerosol24 = SheetAerosol.getCell("A24");
            cellAerosol24.height = 2;

            SheetAerosol.columns = [
                { key: "tahun", width: 10 },
                { key: "bulan", width: 10 },
                { key: "minggu", width: 7 },
                { key: "aerosol", width: 18 },
                { key: "ph", width: 7 },
                { key: "dh", width: 7 },
                { key: "ca", width: 7 },
                { key: "mg", width: 7 },
                { key: "na", width: 7 },
                { key: "k", width: 7 },
                { key: "nh4", width: 7 },
                { key: "cl", width: 7 },
                { key: "so4", width: 7 },
                { key: "no3", width: 7 },
                { key: "kt", width: 7 },
                { key: "keterangan", width: 15 },
                { key: "" },
            ];
            const dataSheetAerosol24Q = SheetAerosol.getCell("Q24");
            SheetAerosol.getCell("Q24").alignment = {
                horizontal: "left",
                vertical: "top",
            };
            dataSheetAerosol24Q.font = {
                size: 10,
                bold: false,
            };
            // dataSheetAerosol24Q.value = "luarbiasa, sampling, catatan lainnya";
            dataSheetAerosol24Q.value = " ";
            SheetAerosol.mergeCells("Q24: S24");

            SheetAerosol.addRows(rows_aerosol_data);
            const rowCountAerosol = SheetAerosol.rowCount;
            // SheetAerosol.mergeCells(`C24:D${rowCountAerosol}+1`);

            for (let i = 22; i <= 22; i++) {
                const inst = SheetAerosol.getRow(1 + i);

                for (let index = 1; index <= 16; index++) {
                    inst.getCell(index).border = {
                        top: { style: "thin" },
                        left: { style: "thin" },
                        bottom: { style: "thin" },
                        right: { style: "thin" },
                    };
                }
            }
            for (let i = 25; i <= rowCountAerosol; i++) {
                const inst = SheetAerosol.getRow(0 + i);

                for (let index = 1; index <= 16; index++) {
                    inst.getCell(index).border = {
                        top: { style: "thin" },
                        left: { style: "thin" },
                        bottom: { style: "thin" },
                        right: { style: "thin" },
                    };
                }
            }
            for (let i = 24; i <= rowCountAerosol; i++) {
                const inst = SheetAerosol.getRow(0 + i);

                for (let index = 1; index <= 15; index++) {
                    inst.getCell(index).alignment = {
                        horizontal: "center",
                        vertical: "middle",
                    };
                }
            }
            // FOOTER
            SheetAerosol.mergeCells(`M${rowCountAerosol + 2}:P${rowCountAerosol + 2}`);
            SheetAerosol.getCell(`M${rowCountAerosol + 2}`).value = " ";
            // "Jakarta, " + moment().format("DD MMMM  YYYY");
            SheetAerosol.getCell(`M${rowCountAerosol + 2}`).alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetAerosol.getCell(`M${rowCountAerosol + 2}`).font = {
                color: { argb: "FF0000" },
            };
            SheetAerosol.mergeCells(`M${rowCountAerosol + 3}:P${rowCountAerosol + 3}`);
            // SheetAerosol.getCell(`M${rowCountAerosol + 3}`).value =
            //   "Pejabat yang mengeluarkan";
            SheetAerosol.getCell(`M${rowCountAerosol + 3}`).value = " ";
            SheetAerosol.getCell(`M${rowCountAerosol + 3}`).alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetAerosol.getCell(`M${rowCountAerosol + 3}`).font = {
                color: { argb: "FF0000" },
            };

            SheetAerosol.mergeCells(`M${rowCountAerosol + 9}:P${rowCountAerosol + 9}`);
            SheetAerosol.getCell(`M${rowCountAerosol + 9}`).value = " ";
            // SheetAerosol.getCell(`M${rowCountAerosol + 9}`).value = "NIP. : ";
            SheetAerosol.getCell(`M${rowCountAerosol + 9}`).alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetAerosol.getCell(`M${rowCountAerosol + 9}`).font = {
                color: { argb: "FF0000" },
            };
            var namefile = "Report Aerosol " + aerosol_report.station_name + " Tahun " + year + ".xlsx";
            workbook.xlsx
                .writeBuffer()
                .then((buffer) => saveAs(new Blob([buffer]), namefile))
                .catch((err) => console.log("Error writing excel export", err));
        },
        cretaExcelSO2NO2(data, year) {
            var data_row = data.so2_data;
            var sort_data = data_row.sort((a, b) => parseInt(a.minggu) - parseInt(b.minggu));
            var so2_report = data.so2_report;
            var rows_so2_data = sort_data;
            const workbook = new ExcelJS.Workbook();
            const sheet3 = "NO2 dan SO2";
            let SheetNo2So = workbook.addWorksheet(sheet3);
            SheetNo2So.views = [
                {
                    showGridLines: false,
                },
            ];

            const colNo2So11C = SheetNo2So.getColumn(3);
            colNo2So11C.width = 2;

            const colNo2So12K = SheetNo2So.getColumn(11);
            colNo2So12K.width = 2;

            const colNo2So18A = SheetNo2So.getColumn(1);
            colNo2So18A.width = 15;

            const cellNo2So1 = SheetNo2So.getCell("A1");
            const titleNo2So1 = SheetNo2So.getRow(1);
            titleNo2So1.height = 15;
            cellNo2So1.value = "BADAN METEOROLOGI KLIMATOLOGI DAN GEOFISIKA";
            cellNo2So1.font = {
                size: 10,
                bold: true,
            };
            cellNo2So1.alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
            };
            SheetNo2So.mergeCells("A1:J1");

            const cellNo2So2 = SheetNo2So.getCell("A2");
            const titleNo2So2 = SheetNo2So.getRow(2);
            titleNo2So2.height = 15;
            cellNo2So2.value = "Jl. Angkasa I No.2 Kemayoran, Jakarta 10720 Telp.: (021) 4246321, Fax.: (021) 4246703";
            cellNo2So2.font = {
                size: 10,
            };
            cellNo2So2.alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
            };
            SheetNo2So.mergeCells("A2:J2");

            const cellNo2So3 = SheetNo2So.getCell("A3");
            const titleNo2So3 = SheetNo2So.getRow(3);
            titleNo2So3.height = 15;
            cellNo2So3.value = "P.O.Box 3540 Jkt, Website : http://www.bmkg.go.id";
            cellNo2So3.font = {
                size: 10,
            };
            cellNo2So3.alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
            };
            SheetNo2So.mergeCells("A3:J3");

            SheetNo2So.mergeCells("A4:J4");
            SheetNo2So.getCell("A4:J4").border = {
                bottom: { style: "medium" },
            };

            const cellNo2So6 = SheetNo2So.getCell("A6");
            const titleNo2So6 = SheetNo2So.getRow(6);
            titleNo2So6.height = 15;
            cellNo2So6.value = "Pusat Informasi Perubahan Iklim";
            cellNo2So6.font = {
                size: 10,
                bold: true,
            };
            cellNo2So6.alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
            };
            SheetNo2So.mergeCells("A6:J6");

            const cellNo2So7 = SheetNo2So.getCell("A7");
            const titleNo2So7 = SheetNo2So.getRow(7);
            titleNo2So7.height = 15;
            cellNo2So7.value = "Bidang Analisis Perubahan Iklim";
            cellNo2So7.font = {
                size: 10,
            };
            cellNo2So7.alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
            };
            SheetNo2So.mergeCells("A7:J7");

            const cellNo2So8 = SheetNo2So.getCell("A8");
            const titleNo2So8 = SheetNo2So.getRow(8);
            titleNo2So8.height = 15;
            cellNo2So8.value = " ";
            cellNo2So8.font = {
                size: 10,
                bold: true,
            };
            cellNo2So8.alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
            };
            SheetNo2So.mergeCells("A8:J8");
            cellNo2So8.font = {
                color: { argb: "FF0000" },
            };

            const cellNo2So10A = SheetNo2So.getCell("A10");
            const titleNo2So10A = SheetNo2So.getRow(10);
            titleNo2So10A.height = 15;
            cellNo2So10A.value = "ID Station";
            cellNo2So10A.font = {
                size: 10,
                bold: false,
            };
            cellNo2So10A.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetNo2So.mergeCells("A10:B10");

            const cellNo2So10C = SheetNo2So.getCell("C10");
            cellNo2So10C.value = `: ${so2_report.station_id}`;
            cellNo2So10C.font = {
                size: 10,
                bold: true,
            };
            cellNo2So10C.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetNo2So.mergeCells("C10:F10");

            const cellNo2So11A = SheetNo2So.getCell("A11");
            const titleNo2So11A = SheetNo2So.getRow(11);
            titleNo2So11A.height = 15;
            cellNo2So11A.value = "Nama Stasiun";
            cellNo2So11A.font = {
                size: 10,
                bold: false,
            };
            cellNo2So11A.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetNo2So.mergeCells("A11:B11");

            const cellNo2So11C = SheetNo2So.getCell("C11");
            cellNo2So11C.value = `: ${so2_report.station_name}`;
            cellNo2So11C.font = {
                size: 10,
                bold: true,
            };
            cellNo2So11C.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetNo2So.mergeCells("C11:F11");

            const cellNo2So11G = SheetNo2So.getCell("G11");
            cellNo2So11G.value = "Lintang";
            cellNo2So11G.font = {
                size: 10,
                bold: false,
            };
            cellNo2So11G.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            // SheetNo2So.mergeCells("G12:J12");

            const cellNo2So11H = SheetNo2So.getCell("H11");
            cellNo2So11H.value = `: ${so2_report.lintang}`;
            cellNo2So11H.font = {
                size: 10,
                bold: true,
            };
            cellNo2So11H.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetNo2So.mergeCells("H11:J11");

            const cellNo2So12A = SheetNo2So.getCell("A12");
            const titleNo2So12A = SheetNo2So.getRow(12);
            titleNo2So12A.height = 15;
            cellNo2So12A.value = "Provinsi";
            cellNo2So12A.font = {
                size: 10,
                bold: false,
            };
            cellNo2So12A.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetNo2So.mergeCells("A12:B12");

            const cellNo2So12C = SheetNo2So.getCell("C12");
            cellNo2So12C.value = `: ${so2_report.provinsi}`;
            cellNo2So12C.font = {
                size: 10,
                bold: true,
            };
            cellNo2So12C.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetNo2So.mergeCells("C12:F12");

            const cellNo2So12G = SheetNo2So.getCell("G12");
            cellNo2So12G.value = "Bujur";
            cellNo2So12G.font = {
                size: 10,
                bold: false,
            };
            cellNo2So12G.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            // SheetNo2So.mergeCells("I13:J13");

            const cellNo2So12H = SheetNo2So.getCell("H12");
            cellNo2So12H.value = `: ${so2_report.bujur}`;
            cellNo2So12H.font = {
                size: 10,
                bold: true,
            };
            cellNo2So12H.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetNo2So.mergeCells("H12:J12");

            const cellNo2So13A = SheetNo2So.getCell("A13");
            const titleNo2So13A = SheetNo2So.getRow(13);
            titleNo2So13A.height = 15;
            cellNo2So13A.value = "Balai Besar Wilayah";
            cellNo2So13A.font = {
                size: 10,
                bold: false,
            };
            cellNo2So13A.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetNo2So.mergeCells("A13:B13");

            const cellNo2So13C = SheetNo2So.getCell("C13");
            cellNo2So13C.value = `: ${so2_report.balai}`;
            cellNo2So13C.font = {
                size: 10,
                bold: true,
            };
            cellNo2So13C.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetNo2So.mergeCells("C13:F13");

            const cellNo2So14G = SheetNo2So.getCell("G14");
            const titleNo2So14G = SheetNo2So.getRow(14);
            titleNo2So14G.height = 15;
            cellNo2So14G.value = "Parameter";
            cellNo2So14G.font = {
                size: 10,
                bold: false,
            };
            cellNo2So14G.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };

            const cellNo2So14H = SheetNo2So.getCell("H14");
            cellNo2So14H.value = `: ${so2_report.ketinggian}`;
            cellNo2So14H.font = {
                size: 10,
                bold: true,
            };
            cellNo2So14H.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetNo2So.mergeCells("H14:J14");

            const cellNo2So15A = SheetNo2So.getCell("A15");
            const titleNo2So15A = SheetNo2So.getRow(15);
            titleNo2So15A.height = 15;
            cellNo2So15A.value = "Parameter";
            cellNo2So15A.font = {
                size: 10,
                bold: false,
            };
            cellNo2So15A.alignment = {
                horizontal: "left",
                wrapText: true,
            };
            SheetNo2So.mergeCells("A15:B15");

            const cellNo2So15C = SheetNo2So.getCell("C15");
            cellNo2So15C.value = `: ${so2_report.parameter}`;
            cellNo2So15C.font = {
                size: 10,
                bold: true,
            };
            cellNo2So15C.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetNo2So.mergeCells("C15:F15");

            const cellNo2So16A = SheetNo2So.getCell("A16");
            const titleNo2So16A = SheetNo2So.getRow(16);
            titleNo2So16A.height = 15;
            cellNo2So16A.value = "Jenis Data";
            cellNo2So16A.font = {
                size: 10,
                bold: false,
            };
            cellNo2So16A.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetNo2So.mergeCells("A16:B16");

            const cellNo2So16C = SheetNo2So.getCell("C16");
            cellNo2So16C.value = `: ${so2_report.jenis_data}`;
            cellNo2So16C.font = {
                size: 10,
                bold: true,
            };
            cellNo2So16C.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetNo2So.mergeCells("C16:F16");

            const cellNo2So17A = SheetNo2So.getCell("A17");
            const titleNo2So17A = SheetNo2So.getRow(17);
            titleNo2So17A.height = 15;
            cellNo2So17A.value = "Periode";
            cellNo2So17A.font = {
                size: 10,
                bold: false,
            };
            cellNo2So17A.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetNo2So.mergeCells("A17:B17");

            const cellNo2So17C = SheetNo2So.getCell("C17");
            ///interval
            cellNo2So17C.value = `: ${so2_report.interval}`;
            cellNo2So17C.font = {
                size: 10,
                bold: true,
            };
            cellNo2So17C.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetNo2So.mergeCells("C17:F17");

            const cellNo2So16G = SheetNo2So.getCell("G16");
            cellNo2So16G.value = "Tgl Cetak";
            cellNo2So16G.font = {
                size: 10,
                bold: false,
            };
            cellNo2So16G.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            // SheetNo2So.mergeCells("G16:");

            const cellNo2So16H = SheetNo2So.getCell("H16");
            cellNo2So16H.value = `: ${moment().format("DD MMMM  YYYY")}`;
            cellNo2So16H.font = {
                size: 10,
                bold: true,
            };
            cellNo2So16H.alignment = {
                horizontal: "left",
                vertical: "middle",
                wrapText: true,
            };
            SheetNo2So.mergeCells("H16:J16");

            // TABLE No2So

            const dataSheetNo2So1 = SheetNo2So.getCell("C21");
            SheetNo2So.getCell("C21").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            dataSheetNo2So1.font = {
                size: 10,
                bold: true,
            };
            dataSheetNo2So1.value = "Tahun";

            const dataSheetNo2So2 = SheetNo2So.getCell("D21");
            SheetNo2So.getCell("D21").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            dataSheetNo2So2.font = {
                size: 10,
                bold: true,
            };
            dataSheetNo2So2.value = "Bulan";

            const dataSheetNo2So3 = SheetNo2So.getCell("E21");
            SheetNo2So.getCell("E21").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            dataSheetNo2So3.font = {
                size: 10,
                bold: true,
            };
            dataSheetNo2So3.value = "Minggu";

            const dataSheetNo2So4 = SheetNo2So.getCell("F21");
            SheetNo2So.getCell("F21").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            dataSheetNo2So4.font = {
                size: 10,
                bold: true,
            };
            dataSheetNo2So4.value = "Konsentrasi NO2";

            const dataSheetNo2So5 = SheetNo2So.getCell("G21");
            SheetNo2So.getCell("G21").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            dataSheetNo2So5.font = {
                size: 10,
                bold: true,
            };
            dataSheetNo2So5.value = "Konsentrasi SO2";

            const dataSheetNo2So18 = SheetNo2So.getCell("H21");
            SheetNo2So.getCell("H21").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            dataSheetNo2So18.font = {
                size: 10,
                bold: true,
            };
            dataSheetNo2So18.value = "Keterangan";

            SheetNo2So.columns = [{ key: "" }, { key: "" }, { key: "tahun", width: 10 }, { key: "bulan", width: 10 }, { key: "minggu", width: 8 }, { key: "no2", width: 20 }, { key: "so2", width: 20 }, { key: "keterangan", width: 15 }];

            SheetNo2So.addRows(rows_so2_data);
            const rowCountNo2So = SheetNo2So.rowCount;
            // SheetNo2So.mergeCells(`C24:D${rowCountNo2So}+1`);
            const empty = SheetNo2So.getCell(`A22: B${rowCountNo2So}`);
            empty.value = "";
            for (let i = 21; i <= rowCountNo2So; i++) {
                const inst = SheetNo2So.getRow(0 + i);

                for (let index = 3; index <= 8; index++) {
                    inst.getCell(index).border = {
                        top: { style: "thin" },
                        left: { style: "thin" },
                        bottom: { style: "thin" },
                        right: { style: "thin" },
                    };
                }
            }
            for (let i = 22; i <= rowCountNo2So; i++) {
                const inst = SheetNo2So.getRow(0 + i);

                for (let index = 1; index <= 7; index++) {
                    inst.getCell(index).alignment = {
                        horizontal: "center",
                        vertical: "middle",
                    };
                }
            }
            // FOOTER
            SheetNo2So.getCell(`A${rowCountNo2So + 2}`).value = "Catatan : ";

            SheetNo2So.mergeCells(`B${rowCountNo2So + 3}:J${rowCountNo2So + 4}`);
            SheetNo2So.getCell(`B${rowCountNo2So + 3}`).value = " ";
            // "yang terkena flag diganti 9999 keterangan no data";
            SheetNo2So.getCell(`B${rowCountNo2So + 3}`).alignment = {
                horizontal: "left",
                vertical: "top",
                wrapText: true,
            };
            var namefile = "Report SO2NO2 " + so2_report.station_name + " Tahun " + year + ".xlsx";
            workbook.xlsx
                .writeBuffer()
                .then((buffer) => saveAs(new Blob([buffer]), namefile))
                .catch((err) => console.log("Error writing excel export", err));
        },

        createExcelEANET(data, year) {
            // console.log('createExcelEANET', data, year)
            var data_row = data.input_awal;
            data_row.forEach((item) => {
                item.ph_monthly = item.ph_monthly && item.ph_monthly != "null" ? item.ph_monthly.toFixed(2) : null;
                item.conductivity_monthly = item.conductivity_monthly && item.conductivity_monthly != "null" ? item.conductivity_monthly.toFixed(1) : null;
                item.ca2_monthly = item.ca2_monthly && item.ca2_monthly != "null" ? item.ca2_monthly.toFixed(3) : null;
                item.mg2_monthly = item.mg2_monthly && item.mg2_monthly != "null" ? item.mg2_monthly.toFixed(3) : null;
                item.na_monthly = item.na_monthly && item.na_monthly != "null" ? item.na_monthly.toFixed(3) : null;
                item.k_monthly = item.k_monthly && item.k_monthly != "null" ? item.k_monthly.toFixed(3) : null;
                item.nh4_monthly = item.nh4_monthly && item.nh4_monthly != "null" ? item.nh4_monthly.toFixed(3) : null;
                item.cl_monthly = item.cl_monthly && item.cl_monthly != "null" ? item.cl_monthly.toFixed(3) : null;
                item.so42_monthly = item.so42_monthly && item.so42_monthly != "null" ? item.so42_monthly.toFixed(3) : null;
                item.no3_monthly = item.no3_monthly && item.no3_monthly != "null" ? item.no3_monthly.toFixed(3) : null;
                item.curah_hujan_sampling_sum_monthly = item.curah_hujan_sampling_sum_monthly && item.curah_hujan_sampling_sum_monthly != "null" ? item.curah_hujan_sampling_sum_monthly.toFixed(1) : null;
            });
            console.log("data_row", data_row);
            var sort_data = data_row.sort((a, b) => parseInt(a.bulan) - parseInt(b.bulan));
            var eanet_report = data.eanet_report;
            var rows_input_awal = sort_data;
            const workbook = new ExcelJS.Workbook();
            const sheet3 = "EANET";
            let SheetNo2So = workbook.addWorksheet(sheet3);
            SheetNo2So.views = [
                {
                    showGridLines: false,
                },
            ];

            const colNo2So11C = SheetNo2So.getColumn(3);
            colNo2So11C.width = 2;

            const colNo2So12K = SheetNo2So.getColumn(11);
            colNo2So12K.width = 2;

            const colNo2So18A = SheetNo2So.getColumn(1);
            colNo2So18A.width = 15;

            const cellNo2So1 = SheetNo2So.getCell("A1");
            const titleNo2So1 = SheetNo2So.getRow(1);
            titleNo2So1.height = 15;
            cellNo2So1.value = "BADAN METEOROLOGI KLIMATOLOGI DAN GEOFISIKA";
            cellNo2So1.font = {
                size: 10,
                bold: true,
            };
            cellNo2So1.alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
            };
            SheetNo2So.mergeCells("A1:J1");

            const cellNo2So2 = SheetNo2So.getCell("A2");
            const titleNo2So2 = SheetNo2So.getRow(2);
            titleNo2So2.height = 15;
            cellNo2So2.value = "Jl. Angkasa I No.2 Kemayoran, Jakarta 10720 Telp.: (021) 4246321, Fax.: (021) 4246703";
            cellNo2So2.font = {
                size: 10,
            };
            cellNo2So2.alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
            };
            SheetNo2So.mergeCells("A2:J2");

            const cellNo2So3 = SheetNo2So.getCell("A3");
            const titleNo2So3 = SheetNo2So.getRow(3);
            titleNo2So3.height = 15;
            cellNo2So3.value = "P.O.Box 3540 Jkt, Website : http://www.bmkg.go.id";
            cellNo2So3.font = {
                size: 10,
            };
            cellNo2So3.alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
            };
            SheetNo2So.mergeCells("A3:J3");

            SheetNo2So.mergeCells("A4:J4");
            SheetNo2So.getCell("A4:J4").border = {
                bottom: { style: "medium" },
            };

            const cellInputAwal = SheetNo2So.getCell("A6");
            const titleInputAwal = SheetNo2So.getRow(1);
            titleInputAwal.height = 15;
            cellInputAwal.value = "DATA TURUNAN/Rata-rata bulanan (INPUT AWAL)";
            cellInputAwal.font = {
                size: 10,
                bold: true,
            };
            cellInputAwal.alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
            };
            SheetNo2So.mergeCells("A6:C6");

            /** TABLE EANET */
            /** INPUT AWAL */
            const dataSheetEanet1 = SheetNo2So.getCell("A7");
            SheetNo2So.getCell("A7").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            dataSheetEanet1.font = {
                size: 10,
                bold: true,
            };
            dataSheetEanet1.value = "Bulan";

            const dataSheetEanet2 = SheetNo2So.getCell("B7");
            SheetNo2So.getCell("B7").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            dataSheetEanet2.font = {
                size: 10,
                bold: true,
            };
            dataSheetEanet2.value = "Tahun";

            const dataSheetEanet3 = SheetNo2So.getCell("C7");
            SheetNo2So.getCell("C7").alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
                width: 2,
            };
            dataSheetEanet3.font = {
                size: 10,
                bold: true,
            };
            dataSheetEanet3.value = "pH\n--";

            const dataSheetEanet4 = SheetNo2So.getCell("D7");
            SheetNo2So.getCell("D7").alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
                width: 2,
            };
            dataSheetEanet4.font = {
                size: 10,
                bold: true,
            };
            dataSheetEanet4.value = "EC\nµS/cm";

            const dataSheetEanet5 = SheetNo2So.getCell("E7");
            SheetNo2So.getCell("E7").alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
                width: 2,
            };
            dataSheetEanet5.font = {
                size: 10,
                bold: true,
            };
            dataSheetEanet5.value = "Ca2+\nmg/L";

            const dataSheetEanet6 = SheetNo2So.getCell("F7");
            SheetNo2So.getCell("F7").alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
                width: 2,
            };
            dataSheetEanet6.font = {
                size: 10,
                bold: true,
            };
            dataSheetEanet6.value = "Mg2+\nmg/L";

            const dataSheetEanet7 = SheetNo2So.getCell("G7");
            SheetNo2So.getCell("G7").alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
                width: 2,
            };
            dataSheetEanet7.font = {
                size: 10,
                bold: true,
            };
            dataSheetEanet7.value = "Na+\nmg/L";

            const dataSheetEanet8 = SheetNo2So.getCell("H7");
            SheetNo2So.getCell("H7").alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
                width: 2,
            };
            dataSheetEanet8.font = {
                size: 10,
                bold: true,
            };
            dataSheetEanet8.value = "K+\nmg/L";

            const dataSheetEanet9 = SheetNo2So.getCell("I7");
            SheetNo2So.getCell("I7").alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
                width: 2,
            };
            dataSheetEanet9.font = {
                size: 10,
                bold: true,
            };
            dataSheetEanet9.value = "NH4+\nmg/L";

            const dataSheetEanet10 = SheetNo2So.getCell("J7");
            SheetNo2So.getCell("J7").alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
                width: 2,
            };
            dataSheetEanet10.font = {
                size: 10,
                bold: true,
            };
            dataSheetEanet10.value = "Cl-\nmg/L";

            const dataSheetEanet11 = SheetNo2So.getCell("K7");
            SheetNo2So.getCell("K7").alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
                width: 2,
            };
            dataSheetEanet11.font = {
                size: 10,
                bold: true,
            };
            dataSheetEanet11.value = "SO42+\nmg/L";

            const dataSheetEanet12 = SheetNo2So.getCell("L7");
            SheetNo2So.getCell("L7").alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
                width: 2,
            };
            dataSheetEanet12.font = {
                size: 10,
                bold: true,
            };
            dataSheetEanet12.value = "NO3- \n mg/L";

            const dataSheetEanet13 = SheetNo2So.getCell("M7");
            SheetNo2So.getCell("M7").alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
                width: 2,
            };
            dataSheetEanet13.font = {
                size: 10,
                bold: true,
            };
            dataSheetEanet13.value = "CH \n mm";

            SheetNo2So.columns = [
                { key: "bulan", width: 10 },
                { key: "tahun", width: 10 },
                { key: "ph_monthly", width: 20 },
                { key: "conductivity_monthly", width: 20 },
                { key: "ca2_monthly", width: 20 },
                { key: "mg2_monthly", width: 20 },
                { key: "na_monthly", width: 20 },
                { key: "k_monthly", width: 20 },
                { key: "nh4_monthly", width: 20 },
                { key: "cl_monthly", width: 20 },
                { key: "so42_monthly", width: 20 },
                { key: "no3_monthly", width: 20 },
                { key: "curah_hujan_sampling_sum_monthly", width: 20 },
            ];

            SheetNo2So.addRows(rows_input_awal);
            const rowCountNo2So = SheetNo2So.rowCount;
            // SheetNo2So.mergeCells(`C24:D${rowCountNo2So}+1`);
            // const empty = SheetNo2So.getCell(`A2: B${rowCountNo2So}`);
            // empty.value = "";
            for (let i = 7; i <= rowCountNo2So; i++) {
                const inst = SheetNo2So.getRow(0 + i);

                for (let index = 1; index <= 13; index++) {
                    inst.getCell(index).border = {
                        top: { style: "thin" },
                        left: { style: "thin" },
                        bottom: { style: "thin" },
                        right: { style: "thin" },
                    };
                }
            }
            for (let i = 8; i <= rowCountNo2So; i++) {
                const inst = SheetNo2So.getRow(0 + i);

                for (let index = 1; index <= 13; index++) {
                    inst.getCell(index).alignment = {
                        horizontal: "center",
                        vertical: "middle",
                    };
                }
            }

            /**EANET 1*/

            var data_row_e1 = data.eanet_1;
            data_row_e1.forEach((item) => {
                item.so42_eanet_1 = item.so42_eanet_1 && item.so42_eanet_1 != "null" ? item.so42_eanet_1.toFixed(3) : null;
                item.nss_so42_eanet_1 = item.nss_so42_eanet_1 && item.nss_so42_eanet_1 != "null" ? item.nss_so42_eanet_1.toFixed(3) : null;
                item.no3_eanet_1 = item.no3_eanet_1 && item.no3_eanet_1 != "null" ? item.no3_eanet_1.toFixed(3) : null;
                item.cl_eanet_1 = item.cl_eanet_1 && item.cl_eanet_1 != "null" ? item.cl_eanet_1.toFixed(3) : null;
                item.nh4_eanet_1 = item.nh4_eanet_1 && item.nh4_eanet_1 != "null" ? item.nh4_eanet_1.toFixed(3) : null;
                item.na_eanet_1 = item.na_eanet_1 && item.na_eanet_1 != "null" ? item.na_eanet_1.toFixed(3) : null;
                item.k_eanet_1 = item.k_eanet_1 && item.k_eanet_1 != "null" ? item.k_eanet_1.toFixed(3) : null;
                item.ca2_eanet_1 = item.ca2_eanet_1 && item.ca2_eanet_1 != "null" ? item.ca2_eanet_1.toFixed(3) : null;
                item.nss_ca2_eanet_1 = item.nss_ca2_eanet_1 && item.nss_ca2_eanet_1 != "null" ? item.nss_ca2_eanet_1.toFixed(3) : null;
                item.mg2_eanet_1 = item.mg2_eanet_1 && item.mg2_eanet_1 != "null" ? item.mg2_eanet_1.toFixed(3) : null;
                item.h_eanet_1 = item.h_eanet_1 && item.h_eanet_1 != "null" ? item.h_eanet_1.toFixed(3) : null;
                item.ph_eanet_1 = item.ph_eanet_1 && item.ph_eanet_1 != "null" ? item.ph_eanet_1.toFixed(2) : null;
                item.ec_eanet_1 = item.ec_eanet_1 && item.ec_eanet_1 != "null" ? item.ec_eanet_1.toFixed(1) : null;
                item.ch_eanet_1 = item.ch_eanet_1 && item.ch_eanet_1 != "null" ? item.ch_eanet_1.toFixed(1) : null;
            });
            var sort_data_e1 = data_row_e1.sort((a, b) => parseInt(a.bulan) - parseInt(b.bulan));
            // var so2_report = data.so2_report;
            var rows_eanet_1 = sort_data_e1;

            const cellEanet1 = SheetNo2So.getCell("A22");
            const titleEanet1 = SheetNo2So.getRow(1);
            titleEanet1.height = 15;
            cellEanet1.value = "DATA TURUNAN/Rata-rata bulanan (EANET-1)";
            cellEanet1.font = {
                size: 10,
                bold: true,
            };
            cellEanet1.alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
            };
            SheetNo2So.mergeCells("A22:C22");

            /** EANET-1 */
            const dataSheetE11 = SheetNo2So.getCell("A23");
            SheetNo2So.getCell("A23").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            dataSheetE11.font = {
                size: 10,
                bold: true,
            };
            dataSheetE11.value = "Bulan";

            const dataSheetE12 = SheetNo2So.getCell("B23");
            SheetNo2So.getCell("B23").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            dataSheetE12.font = {
                size: 10,
                bold: true,
            };
            dataSheetE12.value = "Tahun";

            const dataSheetE13 = SheetNo2So.getCell("C23");
            SheetNo2So.getCell("C23").alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
                width: 2,
            };
            dataSheetE13.font = {
                size: 10,
                bold: true,
            };
            dataSheetE13.value = "SO42-\nμmol/L";

            const dataSheetE14 = SheetNo2So.getCell("D23");
            SheetNo2So.getCell("D23").alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
                width: 2,
            };
            dataSheetE14.font = {
                size: 10,
                bold: true,
            };
            dataSheetE14.value = "nss-SO42- \nμmol/L";

            const dataSheetE15 = SheetNo2So.getCell("E23");
            SheetNo2So.getCell("E23").alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
                width: 2,
            };
            dataSheetE15.font = {
                size: 10,
                bold: true,
            };
            dataSheetE15.value = "NO3-\nμmol/L";

            const dataSheetE16 = SheetNo2So.getCell("F23");
            SheetNo2So.getCell("F23").alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
                width: 2,
            };
            dataSheetE16.font = {
                size: 10,
                bold: true,
            };
            dataSheetE16.value = "Cl-\nμmol/L";

            const dataSheetE17 = SheetNo2So.getCell("G23");
            SheetNo2So.getCell("G23").alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
                width: 2,
            };
            dataSheetE17.font = {
                size: 10,
                bold: true,
            };
            dataSheetE17.value = "NH4+\nμmol/L";

            const dataSheetE18 = SheetNo2So.getCell("H23");
            SheetNo2So.getCell("H23").alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
                width: 2,
            };
            dataSheetE18.font = {
                size: 10,
                bold: true,
            };
            dataSheetE18.value = "Na+\nμmol/L";

            const dataSheetE19 = SheetNo2So.getCell("I23");
            SheetNo2So.getCell("I23").alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
                width: 2,
            };
            dataSheetE19.font = {
                size: 10,
                bold: true,
            };
            dataSheetE19.value = "K+\nμmol/L";

            const dataSheetE110 = SheetNo2So.getCell("J23");
            SheetNo2So.getCell("J23").alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
                width: 2,
            };
            dataSheetE110.font = {
                size: 10,
                bold: true,
            };
            dataSheetE110.value = "Ca2+\nμmol/L";

            const dataSheetE111 = SheetNo2So.getCell("K23");
            SheetNo2So.getCell("K23").alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
                width: 2,
            };
            dataSheetE111.font = {
                size: 10,
                bold: true,
            };
            dataSheetE111.value = "nss-Ca2+\nμmol/L";

            const dataSheetE112 = SheetNo2So.getCell("L23");
            SheetNo2So.getCell("L23").alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
                width: 2,
            };
            dataSheetE112.font = {
                size: 10,
                bold: true,
            };
            dataSheetE112.value = "Mg2+\nμmol/L";

            const dataSheetE113 = SheetNo2So.getCell("M23");
            SheetNo2So.getCell("M23").alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
                width: 2,
            };
            dataSheetE113.font = {
                size: 10,
                bold: true,
            };
            dataSheetE113.value = "H+\nμmol/L";

            const dataSheetE114 = SheetNo2So.getCell("N23");
            SheetNo2So.getCell("N23").alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
                width: 2,
            };
            dataSheetE114.font = {
                size: 10,
                bold: true,
            };
            dataSheetE114.value = "pH\n--";

            const dataSheetE115 = SheetNo2So.getCell("O23");
            SheetNo2So.getCell("O23").alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
                width: 2,
            };
            dataSheetE115.font = {
                size: 10,
                bold: true,
            };
            dataSheetE115.value = "EC\nmS/m";

            const dataSheetE116 = SheetNo2So.getCell("P23");
            SheetNo2So.getCell("P23").alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
                width: 2,
            };
            dataSheetE116.font = {
                size: 10,
                bold: true,
            };
            dataSheetE116.value = "CH \nmm";

            SheetNo2So.columns = [
                { key: "bulan", width: 10 },
                { key: "tahun", width: 10 },
                { key: "so42_eanet_1", width: 20 },
                { key: "nss_so42_eanet_1", width: 20 },
                { key: "no3_eanet_1", width: 20 },
                { key: "cl_eanet_1", width: 20 },
                { key: "nh4_eanet_1", width: 20 },
                { key: "na_eanet_1", width: 20 },
                { key: "k_eanet_1", width: 20 },
                { key: "ca2_eanet_1", width: 20 },
                { key: "nss_ca2_eanet_1", width: 20 },
                { key: "mg2_eanet_1", width: 20 },
                { key: "h_eanet_1", width: 20 },
                { key: "ph_eanet_1", width: 20 },
                { key: "ec_eanet_1", width: 20 },
                { key: "ch_eanet_1", width: 20 },
            ];

            SheetNo2So.addRows(rows_eanet_1);
            const rowCountEanet1 = SheetNo2So.rowCount;
            // SheetNo2So.mergeCells(`C24:D${rowCountNo2So}+1`);
            // const empty = SheetNo2So.getCell(`A2: B${rowCountNo2So}`);
            // empty.value = "";
            for (let i = 23; i <= rowCountEanet1; i++) {
                const inst = SheetNo2So.getRow(0 + i);

                for (let index = 1; index <= 16; index++) {
                    inst.getCell(index).border = {
                        top: { style: "thin" },
                        left: { style: "thin" },
                        bottom: { style: "thin" },
                        right: { style: "thin" },
                    };
                }
            }
            for (let i = 24; i <= rowCountEanet1; i++) {
                const inst = SheetNo2So.getRow(0 + i);

                for (let index = 1; index <= 16; index++) {
                    inst.getCell(index).alignment = {
                        horizontal: "center",
                        vertical: "middle",
                    };
                }
            }

            /**EANET 2*/

            var data_row_e2 = data.eanet_2;
            data_row_e2.forEach((item) => {
                item.so42_eanet_2 = item.so42_eanet_2 && item.so42_eanet_2 != "null" ? item.so42_eanet_2.toFixed(3) : null;
                item.nss_so42_eanet_2 = item.nss_so42_eanet_2 && item.nss_so42_eanet_2 != "null" ? item.nss_so42_eanet_2.toFixed(3) : null;
                item.no3_eanet_2 = item.no3_eanet_2 && item.no3_eanet_2 != "null" ? item.no3_eanet_2.toFixed(3) : null;
                item.cl_eanet_2 = item.cl_eanet_2 && item.cl_eanet_2 != "null" ? item.cl_eanet_2.toFixed(3) : null;
                item.nh4_eanet_2 = item.nh4_eanet_2 && item.nh4_eanet_2 != "null" ? item.nh4_eanet_2.toFixed(3) : null;
                item.na_eanet_2 = item.na_eanet_2 && item.na_eanet_2 != "null" ? item.na_eanet_2.toFixed(3) : null;
                item.k_eanet_2 = item.k_eanet_2 && item.k_eanet_2 != "null" ? item.k_eanet_2.toFixed(3) : null;
                item.ca2_eanet_2 = item.ca2_eanet_2 && item.ca2_eanet_2 != "null" ? item.ca2_eanet_2.toFixed(3) : null;
                item.nss_ca2_eanet_2 = item.nss_ca2_eanet_2 && item.nss_ca2_eanet_2 != "null" ? item.nss_ca2_eanet_2.toFixed(3) : null;
                item.mg2_eanet_2 = item.mg2_eanet_2 && item.mg2_eanet_2 != "null" ? item.mg2_eanet_2.toFixed(3) : null;
                item.h_eanet_2 = item.h_eanet_2 && item.h_eanet_2 != "null" ? item.h_eanet_2.toFixed(3) : null;
            });
            var sort_data_e2 = data_row_e2.sort((a, b) => parseInt(a.bulan) - parseInt(b.bulan));
            // var so2_report = data.so2_report;
            var rows_eanet_2 = sort_data_e2;

            const cellEanet2 = SheetNo2So.getCell("A38");
            const titleEanet2 = SheetNo2So.getRow(1);
            titleEanet2.height = 15;
            cellEanet2.value = "DATA TURUNAN (WET DEPOSITION / Deposisi Asam)  (EANET-2)";
            cellEanet2.font = {
                size: 10,
                bold: true,
            };
            cellEanet2.alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
            };
            SheetNo2So.mergeCells("A38:D38");

            /** EANET 2*/

            /** INPUT AWAL */
            const dataSheetEanet21 = SheetNo2So.getCell("A39");
            SheetNo2So.getCell("A39").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            dataSheetEanet21.font = {
                size: 10,
                bold: true,
            };
            dataSheetEanet21.value = "Bulan";

            const dataSheetEanet22 = SheetNo2So.getCell("B39");
            SheetNo2So.getCell("B39").alignment = {
                horizontal: "center",
                vertical: "middle",
            };
            dataSheetEanet22.font = {
                size: 10,
                bold: true,
            };
            dataSheetEanet22.value = "Tahun";

            const dataSheetEanet23 = SheetNo2So.getCell("C39");
            SheetNo2So.getCell("C39").alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
                width: 2,
            };
            dataSheetEanet23.font = {
                size: 10,
                bold: true,
            };
            dataSheetEanet23.value = "SO42-\nmmol/m2";
            // dataSheetEanet23a.value = "mmol/m2";

            const dataSheetEanet24 = SheetNo2So.getCell("D39");
            SheetNo2So.getCell("D39").alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
                width: 2,
            };
            dataSheetEanet24.font = {
                size: 10,
                bold: true,
            };
            dataSheetEanet24.value = "nss-SO42-\nmmol/m2";

            const dataSheetEanet25 = SheetNo2So.getCell("E39");
            SheetNo2So.getCell("E39").alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
                width: 2,
            };
            dataSheetEanet25.font = {
                size: 10,
                bold: true,
            };
            dataSheetEanet25.value = "NO3-\nmmol/m2";

            const dataSheetEanet26 = SheetNo2So.getCell("F39");
            SheetNo2So.getCell("F39").alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
                width: 2,
            };
            dataSheetEanet26.font = {
                size: 10,
                bold: true,
            };
            dataSheetEanet26.value = "CL-\nmmol/m2";

            const dataSheetEanet27 = SheetNo2So.getCell("G39");
            SheetNo2So.getCell("G39").alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
                width: 2,
            };
            dataSheetEanet27.font = {
                size: 10,
                bold: true,
            };
            dataSheetEanet27.value = "NH4+\nmmol/m2";

            const dataSheetEanet28 = SheetNo2So.getCell("H39");
            SheetNo2So.getCell("H39").alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
                width: 2,
            };
            dataSheetEanet28.font = {
                size: 10,
                bold: true,
            };
            dataSheetEanet28.value = "Na+\nmmol/m2";

            const dataSheetEanet29 = SheetNo2So.getCell("I39");
            SheetNo2So.getCell("I39").alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
                width: 2,
            };
            dataSheetEanet29.font = {
                size: 10,
                bold: true,
            };
            dataSheetEanet29.value = "K+\nmmol/m2";

            const dataSheetEanet210 = SheetNo2So.getCell("J39");
            SheetNo2So.getCell("J39").alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
                width: 2,
            };
            dataSheetEanet210.font = {
                size: 10,
                bold: true,
            };
            dataSheetEanet210.value = "Ca2+\nmmol/m2";

            const dataSheetEanet211 = SheetNo2So.getCell("K39");
            SheetNo2So.getCell("K39").alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
                width: 2,
            };
            dataSheetEanet211.font = {
                size: 10,
                bold: true,
            };
            dataSheetEanet211.value = "nss-Ca2+\nmmol/m2";

            const dataSheetEanet212 = SheetNo2So.getCell("L39");
            SheetNo2So.getCell("L39").alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
                width: 2,
            };
            dataSheetEanet212.font = {
                size: 10,
                bold: true,
            };
            dataSheetEanet212.value = "Mg2+\nmmol/m2";

            const dataSheetEanet213 = SheetNo2So.getCell("M39");
            SheetNo2So.getCell("M39").alignment = {
                horizontal: "center",
                vertical: "middle",
                wrapText: true,
                width: 2,
            };
            dataSheetEanet213.font = {
                size: 10,
                bold: true,
            };
            dataSheetEanet213.value = "H+\nmmol/m2";

            SheetNo2So.columns = [
                { key: "bulan", width: 10 },
                { key: "tahun", width: 10 },
                { key: "so42_eanet_2", width: 20 },
                { key: "nss_so42_eanet_2", width: 20 },
                { key: "no3_eanet_2", width: 20 },
                { key: "cl_eanet_2", width: 20 },
                { key: "nh4_eanet_2", width: 20 },
                { key: "na_eanet_2", width: 20 },
                { key: "k_eanet_2", width: 20 },
                { key: "ca2_eanet_2", width: 20 },
                { key: "nss_ca2_eanet_2", width: 20 },
                { key: "mg2_eanet_2", width: 20 },
                { key: "h_eanet_2", width: 20 },
            ];

            SheetNo2So.addRows(rows_eanet_2);
            const rowCountEanet2 = SheetNo2So.rowCount;
            // SheetNo2So.mergeCells(`C24:D${rowCountNo2So}+1`);
            // const empty = SheetNo2So.getCell(`A2: B${rowCountNo2So}`);
            // empty.value = "";
            for (let i = 39; i <= rowCountEanet2; i++) {
                const inst = SheetNo2So.getRow(0 + i);

                for (let index = 1; index <= 13; index++) {
                    inst.getCell(index).border = {
                        top: { style: "thin" },
                        left: { style: "thin" },
                        bottom: { style: "thin" },
                        right: { style: "thin" },
                    };
                }
            }
            for (let i = 40; i <= rowCountEanet2; i++) {
                const inst = SheetNo2So.getRow(0 + i);

                for (let index = 1; index <= 13; index++) {
                    inst.getCell(index).alignment = {
                        horizontal: "center",
                        vertical: "middle",
                    };
                }
            }

            // FOOTER
            // SheetNo2So.getCell(`A${rowCountNo2So + 2}`).value = "Catatan : ";

            // SheetNo2So.mergeCells(`B${rowCountNo2So + 3}:J${rowCountNo2So + 4}`);
            // SheetNo2So.getCell(`B${rowCountNo2So + 3}`).value =
            //   "yang terkena flag diganti 9999 keterangan no data";
            // SheetNo2So.getCell(`B${rowCountNo2So + 3}`).alignment = {
            //   horizontal: "left",
            //   vertical: "top",
            //   wrapText: true,
            // };
            var namefile = "Report EANET  " + eanet_report.station_name + " Tahun " + year + ".xlsx";
            workbook.xlsx
                .writeBuffer()
                .then((buffer) => saveAs(new Blob([buffer]), namefile))
                .catch((err) => console.log("Error writing excel export", err));
        },

        fetchWmo(search) {
            this.fetching = true;
            let temp = search.toLowerCase();
            var foundRaw = [];
            var WmoStore = this.STORE_WMO;
            if (search == "" || search == null) {
                this.STORE_WMO_SELECT = this.STORE_WMO;
            }
            for (var i in WmoStore) {
                var stationName = WmoStore[i].station_name.toString().toLowerCase().search(temp);
                var wmoID = WmoStore[i].station_wmo_id.toString().toLowerCase().search(temp);
                if (stationName != -1 || wmoID != -1) {
                    foundRaw.push(WmoStore[i]);
                }
            }
            this.STORE_WMO_SELECT = foundRaw;
            this.fetching = false;
        },
        fetchStation(search) {
            this.SEARCH_STATION = search;
            this.fetching = true;
            this.STORE_STATION_SELECT = [];
            let temp = search.toLowerCase();
            var foundRaw = [];
            var StationStore = this.STORE_STATION;
            if (search == "" || search == null) {
                var no = 1;
                var dataStation = [];
                for (var i in StationStore) {
                    dataStation.push(StationStore[i]);
                    if (no == 300) {
                        break;
                    }
                    no++;
                }
                this.STORE_STATION_SELECT = dataStation;
            }

            var noSearch = 1;
            for (var i in StationStore) {
                if (StationStore[i].station_name != null) {
                    var stationName = StationStore[i].station_name.toString().toLowerCase().search(temp);
                    var stationID = StationStore[i]["station_id"].toString().toLowerCase().search(temp);
                    if (stationName != -1 || stationID != -1) {
                        foundRaw.push(StationStore[i]);
                        noSearch++;
                    }
                    if (noSearch == 300) {
                        break;
                    }
                }
            }
            this.STORE_STATION_SELECT = foundRaw;
            this.fetching = false;
        },
        onDropdownStation(event) {
            console.log(event);
        },
        contextSelectedTree() {},
        onCallReportME45() {
            this.REPORT_ORIENTATION = "landscape";
            var report = {};

            report.titles = this.WMO_SELECTED.toString().substring(1).split("");
            this.spinningLoading = true;
            new Promise(async (resolve) => {
                report.data = await DataDummyME_45.callDataME_45(this.WMO_SELECTED, this.DATE_RANGE_SELECTED[0], this.DATE_RANGE_SELECTED[1]);

                this.STORE_REPORT = report;
                setTimeout(() => {
                    this.$refs.html2Pdf.generatePdf();
                    this.spinningLoading = false;
                }, 1000);
                resolve(report);
            });
        },
        onCallReportME48() {
            this.REPORT_ORIENTATION = "landscape";
            var report = {};
            report.title = "LAPORAN ME 48";
            report.wmo_id = this.WMO_SELECTED;
            this.spinningLoading = true;
            new Promise(async (resolve) => {
                report.data = await DataDummyME_48.callDataME_48(this.WMO_SELECTED, this.DATE_RANGE_SELECTED[0], this.DATE_RANGE_SELECTED[1]);
                this.STORE_REPORT = report;
                setTimeout(() => {
                    this.$refs.html2Pdf.generatePdf();
                    this.spinningLoading = false;
                }, 1000);
                resolve(report);
            });
        },
        onCallReportMETAR() {
            this.REPORT_ORIENTATION = "portrait";
            var report = {};
            report.title = "LAPORAN METAR";
            report.wmo_id = this.WMO_SELECTED;
            this.spinningLoading = true;
            new Promise(async (resolve) => {
                report.data = await DataDummyMETAR.callDataMETAR(this.WMO_SELECTED, this.DATE_RANGE_SELECTED[0], this.DATE_RANGE_SELECTED[1]);
                this.STORE_REPORT = report;
                setTimeout(() => {
                    this.$refs.html2Pdf.generatePdf();
                    this.spinningLoading = false;
                }, 1000);
                resolve(report);
            });
        },
        onCallReportPILOTBALON() {
            this.REPORT_ORIENTATION = "portrait";
            var report = {};
            report.title = "LAPORAN PILOR BALON";
            this.spinningLoading = true;
            new Promise(async (resolve) => {
                this.STORE_REPORT = report;
                setTimeout(() => {
                    this.$refs.html2Pdf.generatePdf();
                    this.spinningLoading = false;
                }, 1000);
                resolve(report);
            });
        },
        async onCallReportFKIKLIM71() {
            this.REPORT_ORIENTATION = "portrait";
            var PERIODE = new Date(this.DATE_PERIODE_SELECTED);
            // return;
            var report = {};
            report.title = "DATA KLIMATOLOGI";
            report.bulan = DataHelper.getMonthText(PERIODE.getMonth());
            report.tahun = PERIODE.getFullYear();
            report.station = await DataStation.callDataStation("&station_id=" + this.STATION_SELECTED);
            report.gr_lintang = "";
            report.gr_busur = "";
            report.dpl = "";

            this.spinningLoading = true;
            new Promise(async (resolve) => {
                report.data = await DataDummyFKIKLIM71.callDataFKIKLIM71(this.STATION_SELECTED, this.DATE_PERIODE_SELECTED); // Calls the method before page loads
                this.STORE_REPORT = report;
                setTimeout(() => {
                    this.$refs.html2Pdf.generatePdf();
                    this.spinningLoading = false;
                }, 1000);
                resolve(report);
            });
        },
        async onCallReportIKLIMMIKRO() {
            this.REPORT_ORIENTATION = "landscape";
            var PERIODE = new Date(this.DATE_PERIODE_SELECTED);

            var report = {};
            report.title = "FORM PERAMATAN IKLIM MIKRO";
            report.bulan = DataHelper.getMonthText(PERIODE.getMonth());
            report.tahun = PERIODE.getFullYear();
            report.station = await DataStation.callDataStation("&station_id=" + this.STATION_SELECTED);
            report.gr_lintang = "";
            report.gr_busur = "";
            report.dpl = "";

            this.spinningLoading = true;
            new Promise(async (resolve) => {
                report.data = await DataDummyIKLIMMIKRO.callDataIKLIMMIKRO(this.STATION_SELECTED, this.DATE_PERIODE_SELECTED);
                this.STORE_REPORT = report;
                setTimeout(() => {
                    this.$refs.html2Pdf.generatePdf();
                    this.spinningLoading = false;
                }, 1000);
                resolve(report);
            });
        },
        async onCallReportAGMIA() {
            this.REPORT_ORIENTATION = "landscape";
            var PERIODE = new Date(this.DATE_PERIODE_SELECTED);

            var report = {};
            report.bulan = DataHelper.getMonthText(PERIODE.getMonth());
            report.tahun = PERIODE.getFullYear();
            report.station = await DataStation.callDataStation("&station_id=" + this.STATION_SELECTED);
            report.gr_lintang = "";
            report.gr_busur = "";
            report.dpl = "";
            report.title = "LAPORAN AGM IA";

            this.spinningLoading = true;
            new Promise(async (resolve) => {
                report.data = await DataDummyAGM1A.callDataAGM1A(this.STATION_SELECTED, this.DATE_PERIODE_SELECTED, this.TIME_SELECTED);
                this.STORE_REPORT = report;
                setTimeout(() => {
                    this.$refs.html2Pdf.generatePdf();
                    this.spinningLoading = false;
                }, 1000);
                resolve(report);
            });
        },
        async onCallReportAGMIB() {
            this.REPORT_ORIENTATION = "landscape";
            var PERIODE = new Date(this.DATE_PERIODE_SELECTED);

            var report = {};
            report.bulan = DataHelper.getMonthText(PERIODE.getMonth());
            report.tahun = PERIODE.getFullYear();
            report.station = await DataStation.callDataStation("&station_id=" + this.STATION_SELECTED);
            report.gr_lintang = "";
            report.gr_busur = "";
            report.dpl = "";
            report.title = "LAPORAN AGM IB";

            this.spinningLoading = true;
            new Promise(async (resolve) => {
                report.data = await DataDummyAGM1B.callDataAGM1B(this.STATION_SELECTED, this.DATE_PERIODE_SELECTED, this.TIME_SELECTED);
                this.STORE_REPORT = report;
                setTimeout(() => {
                    this.$refs.html2Pdf.generatePdf();
                    this.spinningLoading = false;
                }, 1000);
                resolve(report);
            });
        },
        async onCallReportPSYCHROMETERASSMAN() {
            this.REPORT_ORIENTATION = "portrait";
            var PERIODE = new Date(this.DATE_PERIODE_SELECTED);
            // return;
            var report = {};
            report.title = "FORM KELEMBAPAN UDARA ( Pyschrometer Assman )";
            report.bulan = DataHelper.getMonthText(PERIODE.getMonth());
            report.tahun = PERIODE.getFullYear();
            report.station = await DataStation.callDataStation("&station_id=" + this.STATION_SELECTED);
            report.gr_lintang = "";
            report.gr_busur = "";
            report.dpl = "";

            this.spinningLoading = true;
            new Promise(async (resolve) => {
                report.data = await DataDummyPSYCHROMETERASSMAN.callDataPSYCHROMETERASSMAN(this.STATION_SELECTED, this.DATE_PERIODE_SELECTED, this.TIME_SELECTED);
                this.STORE_REPORT = report;
                setTimeout(() => {
                    this.$refs.html2Pdf.generatePdf();
                    this.spinningLoading = false;
                }, 1000);
                resolve(report);
            });
        },
        async onCallReportSUHUTANAH() {
            this.REPORT_ORIENTATION = "landscape";
            var PERIODE = new Date(this.DATE_PERIODE_SELECTED);
            // return;
            var report = {};
            report.title = "FORM SUHU TANAH";
            report.bulan = DataHelper.getMonthText(PERIODE.getMonth());
            report.tahun = PERIODE.getFullYear();
            report.station = await DataStation.callDataStation("&station_id=" + this.STATION_SELECTED);
            report.gr_lintang = "";
            report.gr_busur = "";
            report.dpl = "";

            this.spinningLoading = true;
            new Promise(async (resolve) => {
                report.data = await DataDummySUHUTANAH.callDataSUHUTANAH(this.STATION_SELECTED, this.DATE_PERIODE_SELECTED);
                this.STORE_REPORT = report;
                setTimeout(() => {
                    this.$refs.html2Pdf.generatePdf();
                    this.spinningLoading = false;
                }, 1000);
                resolve(report);
            });
        },
        async onCallReportOPPENGUAPAN() {
            this.REPORT_ORIENTATION = "landscape";
            var PERIODE = new Date(this.DATE_PERIODE_SELECTED);
            var report = {};
            report.bulan = DataHelper.getMonthText(PERIODE.getMonth());
            report.tahun = PERIODE.getFullYear();
            report.station = await DataStation.callDataStation("&station_id=" + this.STATION_SELECTED);
            report.gr_lintang = "";
            report.gr_busur = "";
            report.dpl = "";
            report.title = "LAPORAN OP PENGUAPAN";

            this.spinningLoading = true;
            new Promise(async (resolve) => {
                report.data = await DataDummyOPPENGUAPAN.callDataOPPENGUAPAN(this.STATION_SELECTED, this.DATE_PERIODE_SELECTED, this.TIME_SELECTED);
                this.STORE_REPORT = report;
                setTimeout(() => {
                    this.$refs.html2Pdf.generatePdf();
                    this.spinningLoading = false;
                }, 1000);
                resolve(report);
            });
        },
        async onCallReportPICHEPENGUAPAN() {
            this.REPORT_ORIENTATION = "portrait";
            var PERIODE = new Date(this.DATE_PERIODE_SELECTED);
            // return;
            var report = {};
            report.title = "DATA PENGUAPAN PIECE EVAPORIMETER";
            report.bulan = DataHelper.getMonthText(PERIODE.getMonth());
            report.tahun = PERIODE.getFullYear();
            report.station = await DataStation.callDataStation("&station_id=" + this.STATION_SELECTED);
            report.gr_lintang = "";
            report.gr_busur = "";
            report.dpl = "";

            this.spinningLoading = true;
            new Promise(async (resolve) => {
                report.data = await DataDummyPICHEPENGUAPAN.callDataPICHEPENGUAPAN(this.STATION_SELECTED, this.DATE_PERIODE_SELECTED);
                this.STORE_REPORT = report;
                setTimeout(() => {
                    this.$refs.html2Pdf.generatePdf();
                    this.spinningLoading = false;
                }, 1000);
                resolve(report);
            });
        },
        async onCallReportLYSIMETER() {
            this.REPORT_ORIENTATION = "portrait";
            var PERIODE = new Date(this.DATE_PERIODE_SELECTED);
            var report = {};
            report.title = "LAPORAN OP PICHE LYSIMETER";
            report.bulan = DataHelper.getMonthText(PERIODE.getMonth());
            report.tahun = PERIODE.getFullYear();
            report.station = await DataStation.callDataStation("&station_id=" + this.STATION_SELECTED);
            report.gr_lintang = "";
            report.gr_busur = "";
            report.dpl = "";

            this.spinningLoading = true;
            new Promise(async (resolve) => {
                report.data = await DataDummyLYSIMETER.callDataLYSIMETER(this.STATION_SELECTED, this.DATE_PERIODE_SELECTED, this.TIME_SELECTED, this.JENIS_TANAH_SELECT);
                this.STORE_REPORT = report;
                setTimeout(() => {
                    this.$refs.html2Pdf.generatePdf();
                    this.spinningLoading = false;
                }, 1000);
                resolve(report);
            });
        },
        async onCallReportGUNBELLANI() {
            this.REPORT_ORIENTATION = "portrait";
            var PERIODE = new Date(this.DATE_PERIODE_SELECTED);
            // return;
            var report = {};
            report.title = "DATA INTENSITAS RADIASI MATAHARI (GUNBELLANI)";
            report.bulan = DataHelper.getMonthText(PERIODE.getMonth());
            report.tahun = PERIODE.getFullYear();
            report.station = await DataStation.callDataStation("&station_id=" + this.STATION_SELECTED);
            report.gr_lintang = "";
            report.gr_busur = "";
            report.dpl = "";

            this.spinningLoading = true;
            new Promise(async (resolve) => {
                report.data = await DataDummyGUNBELLANI.callDataGUNBELLANI(this.STATION_SELECTED, this.DATE_PERIODE_SELECTED);
                this.STORE_REPORT = report;
                setTimeout(() => {
                    this.$refs.html2Pdf.generatePdf();
                    this.spinningLoading = false;
                }, 1000);
                resolve(report);
            });
        },
        async onCallReportLAMAPENYINARAN() {
            this.REPORT_ORIENTATION = "portrait";
            var PERIODE = new Date(this.DATE_PERIODE_SELECTED);
            // return;
            var report = {};
            report.title = "DATA LAMA PENYINARAN MATAHARI";
            report.bulan = DataHelper.getMonthText(PERIODE.getMonth());
            report.tahun = PERIODE.getFullYear();
            report.station = await DataStation.callDataStation("&station_id=" + this.STATION_SELECTED);
            report.gr_lintang = "";
            report.gr_busur = "";
            report.dpl = "";

            this.spinningLoading = true;
            new Promise(async (resolve) => {
                report.data = await DataDummyLAMAPENYINARAN.callDataLAMAPENYINARAN(this.STATION_SELECTED, this.DATE_PERIODE_SELECTED);
                this.STORE_REPORT = report;
                setTimeout(() => {
                    this.$refs.html2Pdf.generatePdf();
                    this.spinningLoading = false;
                }, 1000);
                resolve(report);
            });
        },
        async onCallReportAKTINOGRAPH() {
            this.REPORT_ORIENTATION = "landscape";
            var PERIODE = new Date(this.DATE_PERIODE_SELECTED);
            // return;
            var report = {};
            report.title = "FORM INTENSITAS RADIASI MATAHARI (AKTINOGRAPH)";
            report.bulan = DataHelper.getMonthText(PERIODE.getMonth());
            report.tahun = PERIODE.getFullYear();
            report.station = await DataStation.callDataStation("&station_id=" + this.STATION_SELECTED);
            report.gr_lintang = "";
            report.gr_busur = "";
            report.dpl = "";

            this.spinningLoading = true;
            new Promise(async (resolve) => {
                report.data = await DataDummyAKTINOGRAPH.callDataAKTINOGRAPH(this.STATION_SELECTED, this.DATE_PERIODE_SELECTED);
                this.STORE_REPORT = report;
                setTimeout(() => {
                    this.$refs.html2Pdf.generatePdf();
                    this.spinningLoading = false;
                }, 1000);
                resolve(report);
            });
        },

        async onCallReportTHERMOHIGOGRAPH() {
            this.REPORT_ORIENTATION = "landscape";
            var PERIODE = new Date(this.DATE_PERIODE_SELECTED);
            // return;
            var report = {};
            report.title = "FORM PEMBACAAN DATA SUHU UDARA (THERMOHIGROGRAPH)";
            report.bulan = DataHelper.getMonthText(PERIODE.getMonth());
            report.tahun = PERIODE.getFullYear();
            report.station = await DataStation.callDataStation("&station_id=" + this.STATION_SELECTED);
            report.gr_lintang = "";
            report.gr_busur = "";
            report.dpl = "";

            this.spinningLoading = true;
            new Promise(async (resolve) => {
                report.data = await DataDummyTHERMOHIGOGRAPH.callDataTHERMOHIGOGRAPH(this.STATION_SELECTED, this.DATE_PERIODE_SELECTED);
                this.STORE_REPORT = report;
                setTimeout(() => {
                    this.$refs.html2Pdf.generatePdf();
                    this.spinningLoading = false;
                }, 1000);
                resolve(report);
            });
        },
        async onCallReportBAROGRAPH() {
            this.REPORT_ORIENTATION = "landscape";
            var PERIODE = new Date(this.DATE_PERIODE_SELECTED);
            var report = {};
            report.title = "FORM PEMBACAAN PIAS BAROGRAPH ( mb )";
            report.bulan = DataHelper.getMonthText(PERIODE.getMonth());
            report.tahun = PERIODE.getFullYear();
            report.station = await DataStation.callDataStation("&station_id=" + this.STATION_SELECTED);
            report.gr_lintang = "";
            report.gr_busur = "";
            report.dpl = "";

            this.spinningLoading = true;
            new Promise(async (resolve) => {
                report.data = await DataDummyBAROGRAPH.callDataBAROGRAPH(this.STATION_SELECTED, this.DATE_PERIODE_SELECTED);
                this.STORE_REPORT = report;
                setTimeout(() => {
                    this.$refs.html2Pdf.generatePdf();
                    this.spinningLoading = false;
                }, 1000);
                resolve(report);
            });
        },
        async onCallReportPERAWANAN() {
            this.REPORT_ORIENTATION = "portrait";
            var PERIODE = new Date(this.DATE_PERIODE_SELECTED);
            var report = {};
            report.title = "IKHTISAR FREKWENSI PERAWANAN HARIAN";
            report.bulan = DataHelper.getMonthText(PERIODE.getMonth());
            report.tahun = PERIODE.getFullYear();
            report.station = await DataStation.callDataStation("&station_id=" + this.STATION_SELECTED);
            report.gr_lintang = "";
            report.gr_busur = "";
            report.dpl = "";

            this.spinningLoading = true;
            new Promise(async (resolve) => {
                report.data = await DataDummyPERAWANAN.callDataPERAWANAN(this.STATION_SELECTED, this.DATE_PERIODE_SELECTED);
                this.STORE_REPORT = report;
                setTimeout(() => {
                    this.$refs.html2Pdf.generatePdf();
                    this.spinningLoading = false;
                }, 1000);
                resolve(report);
            });
        },
        async onCallReportFORMHUJAN() {
            this.REPORT_ORIENTATION = "landscape";
            var PERIODE = new Date(this.DATE_PERIODE_SELECTED);
            var report = {};
            report.title = "LAPORAN FORM HUJAN";
            report.bulan = DataHelper.getMonthText(PERIODE.getMonth());
            report.tahun = PERIODE.getFullYear();
            report.station = await DataStation.callDataStation("&station_id=" + this.STATION_SELECTED);

            this.spinningLoading = true;
            new Promise(async (resolve) => {
                report.data = await DataDummyFORMHUJAN.callDataFORMHUJAN(this.STATION_SELECTED, this.DATE_PERIODE_SELECTED);
                this.STORE_REPORT = report;
                setTimeout(() => {
                    this.$refs.html2Pdf.generatePdf();
                    this.spinningLoading = false;
                }, 1000);
                resolve(report);
            });
        },
        async onCallReportHUJANHELMAN() {
            this.REPORT_ORIENTATION = "landscape";
            var PERIODE = new Date(this.DATE_PERIODE_SELECTED);
            var report = {};
            report.bulan = DataHelper.getMonthText(PERIODE.getMonth());
            report.tahun = PERIODE.getFullYear();
            report.station = await DataStation.callDataStation("&station_id=" + this.STATION_SELECTED);
            report.title = "LAPORAN HUJAN HELMAN 1";
            this.spinningLoading = true;
            new Promise(async (resolve) => {
                report.data = await DataDummyHUJANHELMAN.callDataHUJANHELMAN(this.STATION_SELECTED, this.DATE_PERIODE_SELECTED);
                this.STORE_REPORT = report;
                setTimeout(() => {
                    this.$refs.html2Pdf.generatePdf();
                    this.spinningLoading = false;
                }, 1000);
                resolve(report);
            });
        },
        async onCallReportKELEMBAPANTANAH() {
            this.REPORT_ORIENTATION = "portrait";
            var PERIODE = new Date(this.DATE_PERIODE_SELECTED);
            var report = {};
            report.title = "DATA KELEMBABAN TANAH";
            report.bulan = DataHelper.getMonthText(PERIODE.getMonth());
            report.tahun = PERIODE.getFullYear();
            report.station = await DataStation.callDataStation("&station_id=" + this.STATION_SELECTED);
            report.gr_lintang = "";
            report.gr_busur = "";
            report.dpl = "";

            this.spinningLoading = true;

            new Promise(async (resolve) => {
                report.data = await DataDummyKELEMBAPANTANAH.callDataKELEMBAPANTANAH(this.STATION_SELECTED, this.DATE_PERIODE_SELECTED, this.TIME_SELECTED); // Calls the method before page loads
                this.STORE_REPORT = report;
                setTimeout(() => {
                    this.$refs.html2Pdf.generatePdf();
                    this.spinningLoading = false;
                }, 1000);
                resolve(report);
            });
        },
        async onCallReportFENOLOGI() {
            this.REPORT_ORIENTATION = "landscape";
            var PERIODE = new Date(this.DATE_PERIODE_SELECTED);
            var report = {};
            report.title = "PERAMATAN FENOLOGI MINGGUAN";
            report.bulan = DataHelper.getMonthText(PERIODE.getMonth());
            report.tahun = PERIODE.getFullYear();
            report.station = await DataStation.callDataStation("&station_id=" + this.STATION_SELECTED);

            this.spinningLoading = true;
            new Promise(async (resolve) => {
                report.data = await DataDummyFenologi.callDataFENOLOGI(this.STATION_SELECTED, this.DATE_PERIODE_SELECTED); // Calls the method before page loads
                this.STORE_REPORT = report;
                setTimeout(() => {
                    this.$refs.html2Pdf.generatePdf();
                    this.spinningLoading = false;
                }, 1000);
                resolve(report);
            });
        },

        async onCallReportACS() {
            this.STORE_REPORT = {};
            this.REPORT_ORIENTATION = "portrait";
            var report = {};
            var monthSelect = this.MONTH_SELECTED != "" && this.MONTH_SELECTED != null ? Number(this.MONTH_SELECTED.split("-")[0]) : this.MONTH_SELECTED;
            report.title = "ACS";
            report.station = await DataStation.callDataStation("&station_id=" + this.STATION_SELECTED);
            report.month = monthSelect;
            report.years = this.START_YEARS_SELECTED + " - " + this.END_YEARS_SELECTED;

            this.spinningLoading = true;
            new Promise(async (resolve) => {
                report.data = await DataDummyACS.callDataACS(this.STATION_SELECTED, monthSelect, this.START_YEARS_SELECTED, this.END_YEARS_SELECTED); // Calls the method before page loads
                this.STORE_REPORT = report;
                setTimeout(() => {
                    this.$refs.html2Pdf.generatePdf();
                    this.spinningLoading = false;
                }, 1000);
                resolve(report);
            });
        },
        onCallReportPETIR() {
            this.REPORT_ORIENTATION = "portrait";
            var report = {};
            report.title = "LAPORAN PETIR";
            this.spinningLoading = true;
            new Promise(async (resolve) => {
                this.STORE_REPORT = report;
                setTimeout(() => {
                    this.$refs.html2Pdf.generatePdf();
                    this.spinningLoading = false;
                }, 1000);
                resolve(report);
            });
        },
        onCallReportMAGNET() {
            this.REPORT_ORIENTATION = "portrait";
            var report = {};
            report.title = "LAPORAN MAGNET";
            this.spinningLoading = true;
            new Promise(async (resolve) => {
                this.STORE_REPORT = report;
                setTimeout(() => {
                    this.$refs.html2Pdf.generatePdf();
                    this.spinningLoading = false;
                }, 1000);
                resolve(report);
            });
        },
        async onCallReportWXREV() {
            this.REPORT_ORIENTATION = "landscape";
            var PERIODE = new Date(this.DATE_PERIODE_SELECTED);
            var report = {};
            report.bulan = DataHelper.getMonthText(PERIODE.getMonth());
            report.tahun = PERIODE.getFullYear();
            report.station = await DataStation.callDataStation("&station_id=" + this.STATION_SELECTED);
            report.title = "LAPORAN WXREV";
            this.spinningLoading = true;
            new Promise(async (resolve) => {
                report.data = await DataDummyWXREV.callDataWXREV(this.STATION_SELECTED, this.DATE_PERIODE_SELECTED);
                this.STORE_REPORT = report;
                setTimeout(() => {
                    this.$refs.html2Pdf.generatePdf();
                    this.spinningLoading = false;
                }, 1000);
                resolve(report);
            });
        },
        onCallReportCLIMAT() {
            this.REPORT_ORIENTATION = "portrait";
            var report = {};
            this.spinningLoading = true;
            new Promise(async (resolve) => {
                report.data = await DataDummyCLIMAT.callDataCLIMAT(this.WMO_SELECTED, this.DATE_PERIODE_SELECTED);
                this.STORE_REPORT = report;
                setTimeout(() => {
                    this.$refs.html2Pdf.generatePdf();
                    this.spinningLoading = false;
                }, 1000);
                resolve(report);
            });
        },
        onYearsStart() {
            this.START_YEARS_STORE = [];
            var DateNow = new Date();
            for (var i = Number(DateNow.getFullYear()); i > Number(DateNow.getFullYear() - 30); i--) {
                this.START_YEARS_STORE.push(i);
            }
        },
        onYearsEnd() {
            this.END_YEARS_STORE = [];
            var DateNow = new Date();
            for (var i = Number(DateNow.getFullYear()); i > Number(DateNow.getFullYear() - 30); i--) {
                this.END_YEARS_STORE.push(i);
            }
        },
        //Tambahan => Table
        // Id, report (ME), period, status, button
        onExportPDF() {
            // console.log("hahahahahah ahahahaha ahaha");
            this.STORE_REPORT = {};
            switch (this.REPORT_SELECTED) {
                case "ME_45":
                    this.onCallReportME45();
                    break;
                case "ME_48":
                    this.onCallReportME48();
                    break;
                case "METAR_REPORT":
                    this.onCallReportMETAR();
                    break;
                case "PILOT_BALON":
                    this.onCallReportPILOTBALON();
                    break;
                case "FKIKLIM71":
                    this.onCallReportFKIKLIM71();
                    break;
                case "IKLIM_MIKRO":
                    this.onCallReportIKLIMMIKRO();
                    break;
                case "AGM_IA":
                    this.onCallReportAGMIA();
                    break;
                case "AGM_IB":
                    this.onCallReportAGMIB();
                    break;
                case "PSYCHROMETER_ASSMAN":
                    this.onCallReportPSYCHROMETERASSMAN();
                    break;
                case "SUHU_TANAH":
                    this.onCallReportSUHUTANAH();
                    break;
                case "OP_PENGUAPAN":
                    this.onCallReportOPPENGUAPAN();
                    break;
                case "PICHE_PENGUAPAN":
                    this.onCallReportPICHEPENGUAPAN();
                    break;
                case "LYSIMETER":
                    this.onCallReportLYSIMETER();
                    break;
                case "GUNBELLANI":
                    this.onCallReportGUNBELLANI();
                    break;
                case "LAMA_PENYINARAN":
                    this.onCallReportLAMAPENYINARAN();
                    break;
                case "AKTINOGRAPH":
                    this.onCallReportAKTINOGRAPH();
                    break;
                case "THERMOHIGROGRAPH":
                    this.onCallReportTHERMOHIGOGRAPH();
                    break;
                case "BAROGRAPH":
                    this.onCallReportBAROGRAPH();
                    break;
                case "PERAWANAN":
                    this.onCallReportPERAWANAN();
                    break;
                case "FORM_HUJAN":
                    this.onCallReportFORMHUJAN();
                    break;
                case "HUJAN_HELMAN":
                    this.onCallReportHUJANHELMAN();
                    break;
                case "KELEMBAPAN_TANAH":
                    this.onCallReportKELEMBAPANTANAH();
                    break;
                case "FENOLOGI":
                    this.onCallReportFENOLOGI();
                    break;
                case "CLIMAT":
                    this.onCallReportCLIMAT();
                    break;
                case "PETIR":
                    this.onCallReportPETIR();
                    break;
                case "MAGNET":
                    this.onCallReportMAGNET();
                    break;
                case "WXREV":
                    this.onCallReportWXREV();
                    break;
                case "ACS":
                    this.onCallReportACS();
                    break;
                default:
                    break;
            }
        },
        beforeDownload($event) {},
        onProgress($event) {},
        hasStartedGeneration() {},
        hasGenerated($event) {},
        moment,
    },
    data() {
        return {
            ROWS_DATA_REPORT_KAH: {},
            TABLE_REPORT_KAH: [],
            showLoading: false,
            form_ku: false,
            isLoading: false,
            pageLength: 10,
            setIntervalCheckReport: null,
            resultreport: {},
            checkreport: [],
            blockCount: false,
            periodeHide: false,
            dateHide: false,
            reportType: null,
            checkStatus: null,
            REPORT_ORIENTATION: "landscape",
            DETAIL_WMO_SELECT: {},
            DETAIL_STATION_SELECT: {},
            ALLOW_DATE_PICKER: ["NONE"],
            ALLOW_DATE_RANGE_PICKER: ["ME_45", "ME_48", "METAR_REPORT"],
            ALLOW_DATE_PICKER_MONTH: ["ACS"],
            ALLOW_DATE_RANGE_PICKER_YEARS: ["ACS", "KIMIA_AIR_HUJAN", "KIMIA_AIR_HUJAN_NO_WMO", "SPM", "Aerosol", "SO2NO2", "EANET"],
            ALLOW_PICKER_YEARS: ["KIMIA_AIR_HUJAN", "KIMIA_AIR_HUJAN_NO_WMO", "SPM", "Aerosol", "SO2NO2", "EANET"],
            ALLOW_PERIODE_PICKER: [
                "HUJAN_HELMAN",
                "AGM_IA",
                "AGM_IB",
                "KELEMBAPAN_TANAH",
                "PERAWANAN",
                "FKIKLIM71",
                "IKLIM_MIKRO",
                "SUHU_TANAH",
                "OP_PENGUAPAN",
                "PSYCHROMETER_ASSMAN",
                "LYSIMETER",
                "PICHE_PENGUAPAN",
                "GUNBELLANI",
                "LAMA_PENYINARAN",
                "THERMOHIGROGRAPH",
                "AKTINOGRAPH",
                "FORM_HUJAN",
                "BAROGRAPH",
                "WXREV",
                "FENOLOGI",
                "CLIMAT",
            ],
            ALLOW_TYPE_PERIODE_PICKER: ["WXREV"],
            ALLOW_INTERVAL_YEAR: ["CLIMAT"],
            // ALLOW_WMO_PICKER: ["CLIMAT", "SUHU_TANAH", "ME_45", "ME_48", "METAR_REPORT", "FKIKLIM71", "IKLIM_MIKRO", "GUNBELLANI", "AGM_IA", "AGM_IB", "OP_PENGUAPAN",
            //                     "PSYCHROMETER_ASSMAN", "LYSIMETER", "PICHE_PENGUAPAN", "KELEMBAPAN_TANAH", "HUJAN_HELMAN", "LAMA_PENYINARAN", "THERMOHIGROGRAPH",
            //                     "AKTINOGRAPH", "FORM_HUJAN", "BAROGRAPH", "FENOLOGI"],
            ALLOW_WMO_PICKER: ["CLIMAT", "ME_45", "ME_48", "METAR_REPORT"],
            ALLOW_STATION_PICKER: [
                "HUJAN_HELMAN",
                "AGM_IA",
                "AGM_IB",
                "KELEMBAPAN_TANAH",
                "PERAWANAN",
                "FKIKLIM71",
                "IKLIM_MIKRO",
                "SUHU_TANAH",
                "OP_PENGUAPAN",
                "PSYCHROMETER_ASSMAN",
                "LYSIMETER",
                "PICHE_PENGUAPAN",
                "GUNBELLANI",
                "LAMA_PENYINARAN",
                "THERMOHIGROGRAPH",
                "AKTINOGRAPH",
                "FORM_HUJAN",
                "BAROGRAPH",
                "WXREV",
                "FENOLOGI",
                "ACS",
                "KIMIA_AIR_HUJAN",
                "KIMIA_AIR_HUJAN_NO_WMO",
                "SPM",
                "Aerosol",
                "SO2NO2",
                "EANET",
            ],
            ALLOW_POSHUJAN: ["FORM_HUJAN"],
            ALLOW_TIME: [],
            ALLOW_TIME_30: ["PSYCHROMETER_ASSMAN"],
            ALLOW_JENIS_TANAH: ["LYSIMETER"],
            REPORT_SELECTED: "",
            REPORT_SELECTED_TITLE: "",
            DATE_PERIODE_SELECTED: "",
            DATE_RANGE_SELECTED: [],
            DATE_SELECTED: "",
            MONTH_SELECTED: "",
            YEAR_SELECTED: "",
            START_YEARS_SELECTED: "",
            END_YEARS_SELECTED: "",
            WMO_SELECTED: "",
            STATION_SELECTED: "",
            TIME_SELECTED: "",
            JENIS_TANAH_SELECT: "",
            PERIODE_TYPE_SELECTED: "",
            INTERVAL_YEAR: "",
            STORE_WMO: [],
            STORE_STATION: [],
            STORE_REPORT: {},
            STORE_WMO_SELECT: [],
            STORE_STATION_SELECT: [],
            STORE_TIME: ["00:00", "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00", "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00"],
            STORE_TIME_30: ["07:30", "13:30", "17:30"],
            STORE_JENIS_TANAH: [
                { KEY: "tanah_gundul", LABEL: "TANAH GUNDUL" },
                { KEY: "tanah_komoditi", LABEL: "TANAH KOMODITI" },
                { KEY: "tanah_berumput", LABEL: "TANAH BERUMPUT" },
            ],
            START_YEARS_STORE: [],
            END_YEARS_STORE: [],
            STORAGE_MONTH: [
                { KEY: "01", LABEL: "JANUARY" },
                { KEY: "02", LABEL: "FEBRUARI" },
                { KEY: "03", LABEL: "MARET" },
                { KEY: "04", LABEL: "APRIL" },
                { KEY: "05", LABEL: "MEI" },
                { KEY: "06", LABEL: "JUNI" },
                { KEY: "07", LABEL: "JULI" },
                { KEY: "08", LABEL: "AGUSTUS" },
                { KEY: "09", LABEL: "SEPTEMBER" },
                { KEY: "10", LABEL: "OKTOBER" },
                { KEY: "11", LABEL: "NOVEMBER" },
                { KEY: "12", LABEL: "DESEMBER" },
            ],
            STORE_PERIODE_TYPE: [
                { KEY: "harian", LABEL: "HARIAN" },
                { KEY: "bulanan", LABEL: "BULANAN" },
            ],
            spinningLoadingPage: false,
            gtsSwitching: {
                gts_id: 0,
                gts_header: "",
                gts_ts_data: "",
                station_path: "",
                station_wmo: "",
                sandi: "",
            },
            openAll: true,
            fetching: false,
            spinningLoading: false,
            treeTypes: [
                {
                    type: "#",
                    max_children: 6,
                    max_depth: 4,
                },
                {
                    type: "Report",
                    icon: "far fa-folder",
                },
                {
                    type: "Basic",
                    icon: "far fa-compass",
                },
                {
                    type: "Top-up",
                    icon: "far fa-minus-square",
                },
            ],
            treeData: [
                {
                    id: 0,
                    text: "Report",
                    type: "Report",
                    count: 0,
                    children: [
                        {
                            id: 1,
                            code: "METEOROLOGI",
                            unix_code: "METEOROLOGI",
                            text: "Meteorologi",
                            type: "Basic",
                            count: 6,
                            children: [
                                {
                                    id: 2,
                                    unix_code: "ME_45",
                                    code: "MENU_ITEM",
                                    text: "ME 45",
                                    type: "Top-up",
                                    count: 0,
                                    children: [],
                                },
                                {
                                    id: 3,
                                    unix_code: "ME_48",
                                    code: "MENU_ITEM",
                                    text: "ME 48",
                                    type: "Top-up",
                                    count: 0,
                                    children: [],
                                },
                                {
                                    id: 4,
                                    unix_code: "METAR_REPORT",
                                    code: "MENU_ITEM",
                                    text: "Metar Report",
                                    type: "Top-up",
                                    count: 0,
                                    children: [],
                                },
                                // {
                                // id: 5,
                                // unix_code : "PILOT_BALON",
                                // code:"MENU_ITEM",
                                // text: "Pilot Balon",
                                // type: "Top-up",
                                // count: 0,
                                // children: [],
                                // },
                            ],
                        },
                        {
                            id: 100812.0,
                            code: "KLIMATOLOGI",
                            unix_code: "KLIMATOLOGI",
                            text: "Klimatologi",
                            type: "Basic",
                            count: 0,
                            children: [
                                {
                                    id: 29,
                                    unix_code: "Aerosol",
                                    code: "MENU_ITEM",
                                    text: "Aerosol",
                                    type: "Top-up",
                                    count: 0,
                                    children: [],
                                },
                                {
                                    id: 8,
                                    unix_code: "AGM_IA",
                                    code: "MENU_ITEM",
                                    text: "AGM IA",
                                    type: "Top-up",
                                    count: 0,
                                    children: [],
                                },
                                {
                                    id: 9,
                                    unix_code: "AGM_IB",
                                    code: "MENU_ITEM",
                                    text: "AGM IB",
                                    type: "Top-up",
                                    count: 0,
                                    children: [],
                                },
                                {
                                    id: 17,
                                    unix_code: "AKTINOGRAPH",
                                    code: "MENU_ITEM",
                                    text: "Aktinograph",
                                    type: "Top-up",
                                    count: 0,
                                    children: [],
                                },
                                {
                                    id: 19,
                                    unix_code: "BAROGRAPH",
                                    code: "MENU_ITEM",
                                    text: "Barograph",
                                    type: "Top-up",
                                    count: 0,
                                    children: [],
                                },
                                {
                                    id: 31,
                                    unix_code: "EANET",
                                    code: "MENU_ITEM",
                                    text: "Eanet",
                                    type: "Top-up",
                                    count: 0,
                                    children: [],
                                },
                                {
                                    id: 24,
                                    unix_code: "FENOLOGI",
                                    code: "MENU_ITEM",
                                    text: "Fenologi",
                                    type: "Top-up",
                                    count: 0,
                                    children: [],
                                },
                                {
                                    id: 6,
                                    unix_code: "FKIKLIM71",
                                    code: "MENU_ITEM",
                                    text: "Fklim71",
                                    type: "Top-up",
                                    count: 152,
                                    children: [],
                                },
                                {
                                    id: 21,
                                    unix_code: "FORM_HUJAN",
                                    code: "MENU_ITEM",
                                    text: "Form Hujan",
                                    type: "Top-up",
                                    count: 0,
                                    children: [],
                                },
                                {
                                    id: 15,
                                    unix_code: "GUNBELLANI",
                                    code: "MENU_ITEM",
                                    text: "Gunbellani",
                                    type: "Top-up",
                                    count: 0,
                                    children: [],
                                },
                                {
                                    id: 7,
                                    unix_code: "IKLIM_MIKRO",
                                    code: "MENU_ITEM",
                                    text: "Iklim Mikro",
                                    type: "Top-up",
                                    count: 0,
                                    children: [],
                                },
                                {
                                    id: 22,
                                    unix_code: "HUJAN_HELMAN",
                                    code: "MENU_ITEM",
                                    text: "Intensitas Hujan (Hellman) ",
                                    type: "Top-up",
                                    count: 0,
                                    children: [],
                                },
                                {
                                    id: 23,
                                    unix_code: "KELEMBAPAN_TANAH",
                                    code: "MENU_ITEM",
                                    text: "Kelembaban Tanah",
                                    type: "Top-up",
                                    count: 0,
                                    children: [],
                                },
                                {
                                    id: 26,
                                    unix_code: "KIMIA_AIR_HUJAN",
                                    code: "MENU_ITEM",
                                    text: "Kimia Air Hujan(WMO)",
                                    type: "Top-up",
                                    count: 0,
                                    children: [],
                                },
                                {
                                    id: 27,
                                    unix_code: "KIMIA_AIR_HUJAN_NO_WMO",
                                    code: "MENU_ITEM",
                                    text: "Kimia Air Hujan",
                                    type: "Top-up",
                                    count: 0,
                                    children: [],
                                },
                                {
                                    id: 16,
                                    unix_code: "LAMA_PENYINARAN",
                                    code: "MENU_ITEM",
                                    text: "Lama Penyinaran",
                                    type: "Top-up",
                                    count: 0,
                                    children: [],
                                },
                                {
                                    id: 14,
                                    unix_code: "LYSIMETER",
                                    code: "MENU_ITEM",
                                    text: "Lysimeter",
                                    type: "Top-up",
                                    count: 0,
                                    children: [],
                                },
                                {
                                    id: 12,
                                    unix_code: "OP_PENGUAPAN",
                                    code: "MENU_ITEM",
                                    text: "OP Penguapan",
                                    type: "Top-up",
                                    count: 0,
                                    children: [],
                                },
                                {
                                    id: 20,
                                    unix_code: "PERAWANAN",
                                    code: "MENU_ITEM",
                                    text: "Perawanan",
                                    type: "Top-up",
                                    count: 0,
                                    children: [],
                                },
                                {
                                    id: 13,
                                    unix_code: "PICHE_PENGUAPAN",
                                    code: "MENU_ITEM",
                                    text: "Piche Penguapan",
                                    type: "Top-up",
                                    count: 0,
                                    children: [],
                                },
                                {
                                    id: 10,
                                    unix_code: "PSYCHROMETER_ASSMAN",
                                    code: "MENU_ITEM",
                                    text: "Psychrometer Assman",
                                    type: "Top-up",
                                    count: 0,
                                    children: [],
                                },
                                {
                                    id: 28,
                                    unix_code: "SPM",
                                    code: "MENU_ITEM",
                                    text: "Suspended Particulate Matter(SPM)",
                                    type: "Top-up",
                                    count: 0,
                                    children: [],
                                },

                                {
                                    id: 30,
                                    unix_code: "SO2NO2",
                                    code: "MENU_ITEM",
                                    text: "SO2NO2",
                                    type: "Top-up",
                                    count: 0,
                                    children: [],
                                },
                                {
                                    id: 11,
                                    unix_code: "SUHU_TANAH",
                                    code: "MENU_ITEM",
                                    text: "Suhu Tanah",
                                    type: "Top-up",
                                    count: 0,
                                    children: [],
                                },
                                {
                                    id: 18,
                                    unix_code: "THERMOHIGROGRAPH",
                                    code: "MENU_ITEM",
                                    text: "Thermohigrograph",
                                    type: "Top-up",
                                    count: 0,
                                    children: [],
                                },
                            ],
                        },
                        {
                            id: 25,
                            code: "DATA_LEVEL",
                            unix_code: "DATA_LEVEL",
                            text: "Data - Level 2",
                            type: "Basic",
                            count: 152,
                            children: [
                                {
                                    id: 36,
                                    unix_code: "ACS",
                                    code: "MENU_ITEM",
                                    text: "ACS",
                                    type: "Top-up",
                                    count: 0,
                                    children: [],
                                },
                                {
                                    id: 37,
                                    unix_code: "CLIMAT",
                                    code: "MENU_ITEM",
                                    text: "Climat",
                                    type: "Top-up",
                                    count: 0,
                                    children: [],
                                },
                                {
                                    id: 38,
                                    unix_code: "WXREV",
                                    code: "MENU_ITEM",
                                    text: "WXREV",
                                    type: "Top-up",
                                    count: 0,
                                    children: [],
                                },
                            ],
                        },
                    ],
                },
            ],
            contextItems: [],
            selectedNode: null,

            // Id, report (ME), period, status, button
            checkreport_ku: [],
            columns: [
                // {
                //   label: "Id",
                //   field: "id",
                // },
                {
                    label: "No",
                    field: "no",
                },
                {
                    label: "Jenis Report",
                    field: "name",
                    // type: "number",
                },
                {
                    label: "Stasiun ",
                    field: "wmo",
                    // type: "number",
                },
                {
                    label: "Date Start",
                    field: "date_start",
                    // type: "date",
                    dateInputFormat: "dd-mmmm-yyyy",
                    dateOutputFormat: "dd mmmm yyyy",
                },
                {
                    label: "Date End",
                    field: "date_end",
                    // type: "date",
                    dateInputFormat: "dd-mmmm-yyyy",
                    dateOutputFormat: "dd mmmm yyyy",
                },
                {
                    label: "Periode",
                    field: "periode",
                    // filterable: true,
                },
                {
                    label: "Created",
                    field: "created_at",
                },
                {
                    label: "Status",
                    field: "status_download",
                    // type: "percentage",
                },
                {
                    label: "Download",
                    field: "download_status",
                    filterable: true,
                },
            ],
            rows: [
                {
                    id: 1,
                    report: "ME 45",
                    date: "2022-07-29",
                    status: "100%",
                    details: true,
                },
                {
                    id: 2,
                    report: "ME 48",
                    date: "28 Juli 2022",
                    status: "20%",
                    details: false,
                },
            ],
        };
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>

<style>
.vgt-table thead th.theadc {
    background-color: #f7fafc !important;
}

table tbody tr td {
    color: #000;
}

.monitor table.vgt-table td {
    padding: 10px !important;
    vertical-align: inherit !important;
}

.monitor .vgt-left-align {
    padding: 10px !important;
    vertical-align: inherit !important;
}

.monitor table.vgt-table {
    font-size: 12px !important;
}

.button-download {
    padding: 5px;
    border: 1px solid #ddd;
    color: #fff;
    background-color: green;
    border-radius: 4px;
    font-size: 14px;
    font-weight: bold;
    font-family: "微软雅黑", arail;
    cursor: pointer;
}

button:hover {
    background-color: #fff;
    color: green;
    border: 1px solid green;
}

#modalReport {
    top: 100px;
    /* left: -10px !important; */
    /* background-color: yellow !important; */
}
</style>
